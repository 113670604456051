.order_group_main_wrapper 
{
    width: 35em;
    min-width: 33em;
    margin: 10px;
    

    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    flex: 1;
    animation: size_up_wrap 0.5s ease-in-out;
}
@keyframes size_up_wrap {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}
.group_body_wrapper 
{
    height: 15em;
    max-height: 15em;
    overflow: scroll;
}
.order_group_main_header_wrapper 
{
    position: relative;
    background: var(--background-dark_minus);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 4px 8px;
    border-bottom: 1px solid var(--background-dark);
}
.order_group_main_header_wrapper h2 
{
    color: var(--text_light_white);
    font-size: 0.8em;
}
.package_info 
{
    color: var(--primary_text_color);
    margin: 2px 5px;
}

.selected_group_prod 
{
    border: 1px solid var(--color-theme) !important;
}
.order_validity
 {
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
    padding: 1px 10px;
    margin-left: 20px;
    color: var(--primary_text_color);
    font-weight: bold;
    border-radius: 10px;
 }
 .order_invalidity 
 {
    background: var(--red-color);
    border: 1px solid var(--red-color);
    padding: 1px 10px;
    margin-left: 20px;
    color: var(--primary_text_color);
    font-weight: bold;
    border-radius: 10px;
 }
 .rejected_amount_wrapper 
 {
    background: var(--red-color) !important;
    border-color: var(--red-color) !important;
    color: var(--primary_text_color) !important;
 }


 .button_disabled 
 {
    z-index: 0;
    filter: blur(5px);
 }
 .button_disabled:hover 
 {
    cursor:not-allowed;
 }
.expecting_action
{
    width: 100%;
    padding: 10px;
    height: 90%;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.expecting_action h2 {
    color: var(--background-light);
    text-transform: uppercase;
    font-size: 0.8em;
    text-align: center;
}