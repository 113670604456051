.editable_input_var_emp_view_master_wrapper 
{
    position: relative;
    border: 1px solid var(--background-dark);
    border-radius: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 4px 0px;
}
.editable_input_var_emp_view_master_wrapper:hover 
{
    border: 1px solid var(--text_light_white);
}
.editable_input_var_emp_view_master_wrapper:hover .unlock_edit_field
{
    visibility: visible;
    opacity: 1;   
}
.mini_manipulate_button:hover 
{
    background: var(--color-theme);
    color: var(--background-dark_plus);
}
.editable_input_var_emp_view_input_wrapper 
{
    width: 70%;
}
.editable_input_var_emp_view_input_wrapper input
{
    padding: 2px 5px;
    font-size: 0.65em;
    text-align: right;
    border: 1px solid none;
    background: none;
    width: 90%;
    margin-right: 2em;
    color: var(--primary_text_color);
}
.editable_input_var_emp_view_label_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.editable_input_var_emp_view_label_wrapper li 
{
    font-weight: bold;
    list-style: none;
    color: var(--text_light_white);
    padding: 2px 8px;
    font-size: var(--default-font-scale-min);
    text-transform: capitalize;
}
.unlock_edit_field 
{
    display: inline-flex;
    transition: 0.3s ease-in-out;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    margin-top: -1.2em;
    font-size: var(--default-font-scale-lrg);
    color: var(--color-theme);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    cursor: pointer;
    border-radius: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}