.pitty_cash_branch_level_master_wrapper 
{
   background: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
} 
.pitty_cash_bl_allocation_View_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
}
.pitty_cash_ut_header 
{
    padding: 5px;
    text-align: center;
    border-bottom: 2px solid var(--background-light);
}
.pitty_cash_ut_header h2 
{
    color: var(--text_light_white);
    font-size: 18px;
    text-transform: uppercase;
}
.pity_cash_chart_wrapper 
{
    width: 30%;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 10px;
    margin: 5px;
}
.pitty_cash_bl_inner_wrapper 
{
    padding: 20px;
}
.out_header 
{
    border: 1px solid var(--background-light);
    background: var(--background-light);
    padding: 5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.out_header h2 
{
    font-size: 14px;
    color: var(--text_light_white);
    text-transform: uppercase;
}
.out_transaction_wrapper 
{
    border-radius: 2px;
    border: 2px solid var(--background-dark);
    background: var(--background-dark);
    margin: 10px;
    width: 45%;
    min-width: 350px;
    height: 400px;
    max-height: 450px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.out_transaction_list_inner_wrapper 
{
    padding: 5px;
    height: 300px;
    max-height: 300px;
    overflow: scroll;
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.out_transaction_list_loading_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    padding: 5px;
    height: 300px;
    height: 300px;
    overflow: scroll;
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.out_transaction_list_empty_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 250px;

    text-transform: uppercase;
    color: var(--text_light_white);
    font-size: 1.0em;
    font-weight: bold;
    opacity: 0.4;
}
.deposit_action_button_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.deposit_action_button_wrapper button 
{
    width: 150px;
    padding: 3px;
    font-size: 12px;
    margin: 7px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.out_transaction_list_inner_wrapper li 
{
    list-style: none;
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 5px;

    margin: 7px;
    padding: 5px 10px;

    display: flex;
    justify-content: space-between;
    color: var(--primary_text_color);
    font-size: 10px;
    transform: scale(1.0);

    cursor: pointer;
    transition: 0.4s ease-in-out;
}
.out_transaction_list_inner_wrapper li:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.03);
}
.pit_amount 
{
    color: var(--color-yellow);
    font-weight: bold;
}
.pit_amount_in 
{
    color: var(--main-color);
    font-weight: bold;
}
.pit_amount_in_closed 
{
    color: var(--background-light);
    font-weight: bold;
    text-decoration: line-through;
    transition: 0.2s ease-in-out;
}
.pit_amount_in_closed:hover 
{
    color: var(--text_light_white);
    text-decoration: none;
}
.closed_deposit_tag 
{
    border: 1px solid var(--unit-of-measurment-color);
    background: var(--unit-of-measurment-color);
    padding: 0px 10px;
    border-radius: 2px;
    font-size: 8px;
    text-transform: uppercase;
}
.pit_action 
{
    border: 1px solid var(--red-color);
    padding: 1px 7px;
    font-size: 10px;
    background: var(--red-color);
    border-radius: 2px;
    color: var(--background-dark_plus);
    font-weight: bold;
}
.pitty_cash_transaction_main_wrapper 
{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.budget_alloc 
{
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: var(--background-dark_minus);
    font-weight: bold;
    border-radius: 2px;
}
.budget_release 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
    color: var(--background-dark_minus);
    font-weight: bold;
    border-radius: 2px;
}
.deposit_action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.pit_cat 
{
    color: var(--text_light_white);
}
.grand_total_petty_cash_li 
{
    border: 1px solid var(--main-color) !important;
}
.pit_cat_grand_total_num, .pit_cat_grand_total_tag
{
    color: var(--main-color);
    font-weight: bold;
}