.desk_view_list_wrapper 
{
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 90vh;
    max-height: 90vh;
    overflow: scroll;
}
.desk_view_receive_order_inner_nav_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px;
}
.desk_view_receive_order_nav
{
    border-bottom: 2px solid var(--color-theme);
    background: var(--background-dark);
    width: 70%;
    border-radius: 5px;
    margin-bottom: 10px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.filter_in_domain 
{
    padding: 5px;
}
.filter_in_domain input
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 5px 10px;
    width: 85%;
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.side_order_cart 
{
    position: absolute;
    right: 0;
    top: 3.5em;

    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    border-radius: 0.3em;
    
    width: 20em;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.pre_order_cart_body_list 
{
    height: 20em;
    max-height: 30em;
    overflow: scroll;
}
.header_side_cart 
{
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    padding: 2px 10px;
}
.header_side_cart h2 
{
    font-size: 0.8em;
    color: var(--text_light_white);
}
.header_side_cart button 
{
    background: none;
    border: none;
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
}
.header_side_cart button span 
{
    cursor: pointer;
}
.header_side_cart button span:hover
{
    color: var(--red-color);
}
.pre_order_cart_body_list 
{
    padding: 5px 10px;
}
.pre_order_cart_body_list li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 3px 6px;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.pre_order_cart_body_list li:hover 
{
    background-color: var(--background-dark);
}
.pre_order_cart_body_list li .name_space 
{
    width: 40% !important;
}
.action_button_wrapper 
{
    padding: 10px;
    background: var(--background-light);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}