.main_order_group_wrapper
 {
    min-width: 20em;
    width: 25em;
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 3px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }

 .main_order_group_header_wrapper 
 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    padding: 4px 8px;
    background: var(--background-dark);
 }
 .main_order_group_header_wrapper h3 
 {
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
 }
 .main_order_group_header_wrapper h3 sub 
 {
   font-size: var(--default-font-scale-mid);
   font-weight: 600;
   background: var(--color-theme);
   color: var(--background-dark_plus);
   padding: 1px 6px;
 }
 .right_size_header_action_wrapper 
 {
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
 }

 .order_info_wrapper 
 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    gap: 2em;
    padding: 5px;

    flex-wrap: wrap;
 }
 .order_owner_wrapper 
 {
    background: var(--background-dark_minus);
    margin: 5px;
 }
 .chef_pp_warpper 
 {
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
 }
 .chef_pp_warpper img 
 {
    width: 4em;
    height: 4em;

    border: 2px solid var(--background-light);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .owner_name_and_stuff 
 {
    padding: 3px;
 }
 .owner_name_and_stuff li 
 {
    text-align: center;
    list-style: none;
    color: var(--text_light_white);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    margin: 2px 4px;
 }
 .order_prod_list_wrapper
  {
    flex: 1;
    height: 8em;
    max-height: 8em;
    background: var(--background-dark_plus);
    border-radius: 10px;
    padding: 5px;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
 .order_prod_list_wrapper li 
 {
    list-style: none;
    color: var(--text_light_white);
    padding: 3px 6px;
    font-size: var(--default-font-scale-min);

    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    border-radius: 10px;
    margin: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }
 .request_body_wrapper 
 {
    padding: 5px 10px;
 }
 .chef_stock_request 
 {
   font-weight: bold;
 }