.page_termination_preview_main_wrapper 
{
    position: absolute;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    background: var(--background-dark_plus);

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.content_wrapper 
{
    text-align: center;
}
.content_wrapper h1 
{
    color: var(--red-color);
    font-size: 3em;
}
.content_wrapper h2 
{
    letter-spacing: 2.5px;
    color: var(--text_light_white);
    text-transform: uppercase;
    font-size: var(--default-font-scale-lrg);
    margin: 4px;
}
.force_terminated 
{
    font-weight: bold;
    border: 1px solid var(--red-color);
    padding: 1px 8px;
    border-radius: 2px;
    background: var(--red-color);
    color: var(--background-dark_plus);
}
.content_wrapper h3 
{
    font-weight: lighter;
    margin: 4px;
    font-size: var(--default-font-scale-mid);
    color: var(--background-light);
}
.page_reason 
{
    padding: 5px 10px;
}
.page_reason li
{
    margin: 4px;
    font-size: var(--default-font-scale-min);
    color: var(--background-light);
    text-align: left;
    font-weight: bolder;
}