.whats_gps_main_wrapper 
{
    height: 100vh;
    overflow: scroll;
}
.card_view_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.left_gpt_target_list 
{
    position: fixed;
    right: 0;
    top: 40%;
}
.side_gps_menu_track_list 
{
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    top: 10%;
    position: fixed;
    right: 0;
    width: 20em;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    animation: slow_in 0.5s ease-in-out;
}
@keyframes slow_in {
    0%{
        width: 1em;
        filter: blur(10px);
        opacity: 0;
    }
    100% {
        width: 20em;
        filter: blur(0px);
        opacity: 1; 
    }
}
.gps_track_list_side_header 
{
    background: var(--background-dark_plus);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.gps_track_list_side_body 
{
    padding: 5px;
    height: 12em;
    max-height: 12em;
    overflow: scroll;
}
.gps_track_list_side_body li 
{
    border-radius: 2em;
    font-size: var(--default-font-scale-min);
    color: var(--color-theme);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    background: var(--background-dark_plus);
    border: 1px solid var(--background-light);
    padding: 4px 8px;
    margin: 3px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s ease-in-out;
}
.gps_track_list_side_body li:hover 
{
    background: var(--background-dark_minus);
}
.empty_box_preview_tdl 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 70vh;
}
.empty_box_preview_tdl li 
{
    width: 100%;
    text-align: center;
    list-style: none;
    color: var(--text_light_white);
    font-size: 0.8em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}
.link_device_icon_placeholder 
{
    font-size: 2em !important;
}
.empty_vh_list_noti_wrapper 
{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 10em;
    flex-wrap: wrap;
}
.empty_vh_list_noti_wrapper ul
{
    margin: 5px;
    width: 100%;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    text-transform: uppercase;
    text-align: center;
}