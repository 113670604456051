.main_product_shelf_card_wrapper 
{
    background: var(--background-dark_plus);
    border: 2px solid var(--background-dark);
    width: 15em;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.main_product_shelf_card_wrapper_selected 
{
    border-color: var(--color-theme) !important;
}
.main_product_shelf_card_wrapper:hover 
{
    border-top: 2px solid var(--color-theme);
}
.prod_other_info_wrpper 
{
    padding: 5px 10px;
    height: 4em;
    max-height: 4em;
    overflow-y: scroll;
}
.prod_other_info_wrpper li 
{
    display: flex;
    justify-content: space-between;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    margin: 3px;
    padding: 2px 10px;
    border-radius: 10px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    transition: 0.2s ease-in-out;
}
/* .prod_other_info_wrpper li:hover 
{
    background: var(--background-light);
} */
.ing_amount_c 
{
    font-weight: bold;
}
.prod_name_info_wrapper   
{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 5px 10px;
    background: var(--background-dark_minus);
    padding: 3px 10px;
    border-bottom: 1px solid var(--background-dark_minus);
}
.prod_name_info_wrapper_selected 
{
    /* background: var(--color-theme) !important; */
    border-radius: 7px;
}
.prod_name_info_wrapper_selected h2 
{
    color: var(--color-theme) !important;
}
.prod_name_info_wrapper h2 
{
    color: var(--text_light_white);
    font-size: 0.9em;
}
.load_recep 
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--color-theme);
    font-size: var(--default-font-scale-lrg);
}