.input_list_wrapper
 {
    width: 45%;
    padding: 10px;
 }
 .form_wrapper 
 {
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
 }
.prodution_group_type
 {
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    margin: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 3px 6px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .prodution_group_type span 
 {
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
 }