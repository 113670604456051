.form_wrapper 
{
    padding: 20px;
}
.input_list_wrapper 
{
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.input_wrap 
{
    width: 200px;
    margin: 3px 10px;
}