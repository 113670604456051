.aquaire_request_key 
{
    position: absolute;
    right: 5em;
    width: 25em;
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 5px 10px;
    margin-top: 5em;
    border-radius: 3px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 3px;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.aquaire_request_key input 
{
    padding: 3px 4px;
    font-size: 1.05em;
    color: var(--text_light_white);
    font-weight: bold;
    width: 60%;
    border: 1px solid var(--background-dark);
    background: var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

}