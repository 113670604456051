.data_body 
{
    padding: 20px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
}
.data_preview_wrapper 
{
    margin: 10px;
}
.inner_preview_data_wrapper 
{
    display: flex;
    justify-content: center;

    height: 20em;
    max-height: 20em;
    overflow: scroll;
}
.paper_wrapper_dash 
{
    width: 90%;
    background: var(--primary_text_color);

    height: 20em;
    overflow: scroll;
}
.meta_data_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    padding: 5px 20px;
    text-align: center;
    color: var(--print_paper_background);
}
.meta_data_wrapper h2
{
    font-size: 0.8em;
    margin: 5px;
    text-transform: uppercase;
}
.transcation_owner_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.transcation_owner 
{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    background: var(--print_paper_background);
    border: 2px solid var(--dimm_white);
    margin: 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.transcation_init li
{
    width: 100%;
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--background-dark_plus);
    margin: 3px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 6em;
}
.transcation_init 
{
    width: 50%;
}
.transcation_init li span 
{
    text-align: left;
    flex: 1;
}
.transcation_init h2, .transcation_recive h2
{
    padding: 5px;
    text-align: center;
    font-size: 0.8em;
    color: var(--background-dark_plus);
    text-decoration: underline;
}
.final_note_po_stock_out 
{
    padding: 5px;
}
.final_note_po_stock_out li 
{
    list-style: none;
    font-size: 0.5em;
    font-weight: bold;
    text-align: right;
}
/* .paper_list_wrapper 
{
    width: 100%;
    padding: 5px 20px;
}
.paper_list_wrapper li 
{
    list-style: none;
    border-bottom: 1px solid var(--background-light);

    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
    font-size: var(--default-font-scale-min);
}
.paper_list_wrapper li span, .fake_tabke_header span
{
    width: 16.6%;
    text-align: center;
} */
.fake_tabke_header
{
    
    font-size: var(--default-font-scale-mid) !important;
    font-weight: bold !important;
}
.entry_deatil_val 
{
    font-weight: bold;
}

.form_action_button_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: right;
    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.form_action_button_wrapper button 
{
    padding: 5px 10px;
    margin: 5px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.form_action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.form_action_button_approve 
{
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
}
.form_action_button_req_dismiss 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}
.form_action_button_print 
{
    color: var(--primary_text_color);
    background: var(--dimm_white);
    border: 1px solid var(--dimm_white);
}
.form_action_button_dismiss 
{
    color: var(--primary_text_color);
    background: var(--red-color);
    border: 1px solid var(--red-color);
}
@media print {
    .paper_wrapper_dash 
    {
        width: 100%;
        height: 100%;
    }

  }
.action_wrapper_text_good 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bold;

    color: var(--background-dark_plus);
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    padding: 2px 5px;
}
.action_wrapper_text_bad 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    font-weight: bold;
    
    color: var(--background-dark_plus);
    border: 1px solid var(--red-color);
    background: var(--red-color);
    padding: 2px 5px;
}
.check_valid 
{
    color: var(--background-dark_plus);
    font-size: 1.3em;
}
.check_invalid 
{
    color: var(--background-dark_plus);
    font-size: 1.3em;
}

.special_footer_local_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;

    padding: 10px;
    border: 1px solid var(--background-light);
    background: var(--print_paper_background);
    margin: 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.footer_user_wrap h2 
{
    padding: 5px;
    font-size: 0.9em;
    text-decoration: underline;
    font-weight: bold;
}
.footer_user_wrap li 
{
    list-style: none;
}
.footer_user_wrap 
{
    width: 30%;
    padding: 5px 10px;
    height: 5em;
}
.name_manual, .sign_manual, .print_date
{
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 5px;
}
.entry_detail 
{
    padding: 10px;
}
.entry_detail li 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    list-style: none;
    font-size: var(--default-font-scale-mid);

    border-bottom: 1px solid var(--background-light);
    margin: 3px;
    padding: 2px 10px;
    background: var(--print_paper_background);
}
.entry_deatil_val 
{
    font-weight: bold;
}



/* BREAK */
.action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    padding: 15px;

    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.action_button_wrapper button 
{
    font-weight: bold;
    color: var(--background-dark_plus);
    font-size: 0.8em;
    width: 8em;
    padding: 3px;
    cursor: pointer;
    border-top-left-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.button_approve 
{
    background: var(--main-color);
    border: 1px solid var(--main-color);
}
.button_halt 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}
.data_list_paid 
{
    color: var(--red-color);
    font-weight: bold;
}
.button_print 
{
    background: var(--inventory-type-color);
    border: 1px solid var(--inventory-type-color);
}
.button_dismiss 
{
    background: var(--red-color);
    border: 1px solid var(--red-color);
}