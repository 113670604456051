.main_product_shelf_card_wrapper 
{
    background: var(--background-dark_minus);
    border: 2px solid var(--background-dark);
    width: 15em;
    height: 4em;
    margin: 5px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.main_product_shelf_card_wrapper_selected 
{
    border-color: var(--color-theme);
}
.main_product_shelf_card_wrapper:hover 
{
    border-top: 2px solid var(--color-theme);
}
.ing_amount_c 
{
    font-weight: bold;
}
.prod_name_info_wrapper   
{
    width: 100%;
    height: 100%;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 5px;
}
.prod_name_info_wrapper h2 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
    text-align: center;
}
.load_recep 
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--color-theme);
    font-size: var(--default-font-scale-lrg);
}
.batch_preview 
{
    background: var(--color-theme);
    color: var(--background-dark_plus);
    padding: 3px 5px;
    font-size: var(--default-font-scale-min);
    border-radius: 2px;
}
.batch_preview_TL 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    padding: 3px 5px;
    font-size: var(--default-font-scale-min);
    border-radius: 2px;
}
.item_type 
{
    color: var(--color-theme);
    font-size: 0.5em;
    font-weight: bold;
}
.product_is_returned {
    filter: brightness(50%);
    border: 2px solid var(--red-color);
}
.product_is_returned:hover 
{
    border: 2px solid var(--red-color);
}
.rot_approve_data_wrapper
 {
    top: 0;
    left: 0;
    position: absolute;
    background: var(--red-color);
    padding: 5px 10px;
 }
 .rot_approve_data_wrapper li 
 {
    list-style: none;
    color: var(--primary_text_color);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
 }