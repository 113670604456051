.chef_line_up_master_wrapper 
{
    margin: 10px;
}
.production_space_workshop_list_wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    
}