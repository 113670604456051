/* Assuming your classes are based on the label values */

.inventory_stock_transfer_state {
    padding: 3px 10px;
    border-radius: 2px;
    font-weight: bold;
    cursor: pointer;
    font-size: var(--default-font-scale-lrg);
    border: none;
  }
  
  .pending {
    background-color: #3f3b3b;
    color: #f0f0f0;
  }
  
  .sent {
    background-color: #ffd615;
    color: #000000;
  }
  
  .received {
    background-color: #0abf53;
    color: #000000;
  }
  
  .underway {
    background-color: #ff4e00;
    color: #000000;
  }
  
  .processing {
    background-color: #4644DC;
    color: #f0f0f0;
  }
  .rejected {
    background-color: #ff1f5a;
    color: #f0f0f0;
  }
  