.data_main 
{
    width: 95% !important;
    background: var(--background-light) !important;
}
@keyframes smooth_in {
    0% {
        scale: 0;
        width: 0;
        height: 0;
    }
    100% {
        scale: 1;
    }

}
.employee_view_header 
{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
    background: var(--background-dark_minus);
}
.employee_view_header h2 
{
    color: var(--primary_text_color);
    font-size: 1.1em;
}
.employee_view_header button 
{
    font-size: var(--default-font-scale-lrg);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary_text_color);
    transition: 0.3s ease-in-out;
}
.employee_view_header button:hover 
{
    color: var(--red-color);
}
.employee_detail_body 
{
    display: flex;
    justify-content: space-between;
}
.employee_detail_right 
{
    margin: 10px;
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    border-radius: 3px;
    padding: 10px;
    width: 20%;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.employee_personal_info 
{
    text-align: left;
    border: 1px solid var(--background-dark_minus);
    border-radius: 5px;
    background: var(--background-dark_minus);
    padding: 5px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.emp_p_info_edit 
{
    font-size: 11px;
    padding: 5px;
    cursor: pointer;
    color: var(--text_light_white);
    transition: 0.2s ease-in-out;
}
.emp_p_info_edit:hover 
{
    color: var(--color-theme);
}
.employee_pic 
{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.employee_respon 
{
    padding: 10px;
}
.employee_respon li 
{
    padding: 2px;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    text-transform: capitalize;
}
.employee_resp_icon 
{
    padding-right: 7px;
    font-size: 13px;
}
.emp_p_info_value input
{
    text-align: right;
    padding: 3px 10px;
    background: none;
    border: none;
    font-size: var(--default-font-scale-lrg);
    font-weight: bold;
    color: var(--text_light_white);
}
.employee_personal_info_action 
{
    text-align: right;
}
.employee_personal_info_action button 
{
    background: none;
    border: none;
}
.employee_personal_info .emp_p_info_value 
{
    padding-right: 20px;
}
.employee_detail_left 
{
    margin: 10px;
    border: 1px solid var(---background-dark_plus);
    background: var(--background-dark_plus);
    border-radius: 3px;
    padding: 20px;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.emp_detail_tab_bar 
{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    border-bottom: 3px solid var(--color-theme);
}
.emp_detail_tab_bar  li 
{
    list-style: none;
    margin-left: 5px;
}
.emp_p_info_hard 
{
    padding: 5px;
}
.emp_detail_tab_bar button
{
    padding: 5px 10px;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    font-size: var(--default-font-scale-min);
    border: 1px solid var(--background-dark);
    background-color: var(--background-dark_plus);
    color: var(--primary_text_color);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.emp_detail_tab_bar button:hover 
{
    border: 1px solid var(--background-dark);
    background-color: var(--background-dark);
}
.emp_detail_tab_bar .active_tab_bar_but 
{
    border: 1px solid var(--color-theme);
    background-color: var(--color-theme);
    color: var(--background-dark_plus);
   font-weight: bold;
}
.emp_detail_tab_bar .active_tab_bar_but:hover 
{
    border: 1px solid var(--color-theme);
    background-color: var(--color-theme);
    color: var(--background-dark_plus);
   font-weight: bold;
}

.emp_salary_wrapper 
{
    padding: 10px;
}
.emp_salary_wrapper li 
{
    list-style: none;
    font-size: 20px;
}
.employee_detail_right .emp_salary_wrapper .emp_salary_just_value 
{
    padding: 3px;
    font-weight: bold;
    color: var(--color-yellow);
    transition: 0.5s ease-in-out;
}
.employee_detail_right .emp_salary_wrapper .emp_salary_just_value_hide 
{
    padding: 3px;
    font-weight: bold;
    color: var(--color-yellow);
    filter: blur(6px);
    transition: 0.01s ease-in-out;
    user-select: none;
    text-decoration: underline;
}
.emp_salary_visible 
{
    font-size: 13px;
    padding-left: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.emp_salary_visible:hover 
{
    color: var(--color-theme); 
}
.emp_name_big 
{
    font-size: var(--default-font-scale-mid) !important;
    font-weight: 600;
}