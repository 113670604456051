.emp_personal_info_tab_bar 
{
    border-bottom: 1px solid var(--background-light); 
    background: var(--background-dark);
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 5px;
}
.emp_personal_info_tab_bar li 
{
    position: relative;
    list-style: none;
    margin: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.emp_personal_info_tab_bar li button 
{
    padding: 5px;
    font-size: var(--default-font-scale-min);
    border: none;
    background: none;
    font-weight: bold;
    color: var(--text_light_white);
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.emp_status_inner_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stat_body
 {
    padding: 10px;
 }
.active_tab_bar_but button  
{
    color: var(--color-theme) !important;
}



.active_tab_bar_but::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--color-theme);
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;

    transform-origin: bottom left;
    transform: scaleX(1);
  }
  

  .emp_personal_info_tab_bar li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--color-theme);
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }
  
.emp_personal_info_tab_bar li:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
}

