.order_group_list_singular 
{
    background: var(--background-dark_minus);
    border-top: 2px solid var(--background-light);
    border-radius: 5px;
    padding: 5px;
    margin: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.sub_group_list_data li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    min-width: 25em;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);

    border-radius: 3px;

    font-weight: bold;

    padding: 5px 10px;
    margin: 3px 10px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.sub_group_list_data li:hover 
{
    border-bottom: 2px solid var(--color-theme);
    background: var(--background-dark_minus);
}
.sub_group_list_data li span 
{
    flex: 1;
}

.selected_group_prod 
{
    border: 1px solid var(--color-theme) !important;
}

.order_info_critical 
{
    z-index: 1;
    position: absolute;
    right: 0;
    top: 1.5em;
    width: 23em;

    padding: 5px;

    border-radius: 5px;

    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    opacity: 1;
    visibility: visible;
    scale: 1;
    transition: 0.2s ease-in-out;
}
.order_info_critical_hide 
{
    position: absolute;
    right: 0;
    top: 1.5em;
    width: 23em;

    padding: 10px;

    border-radius: 10px;

    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;


    opacity: 0;
    visibility: hidden;
    scale: 0;
    transition: 0.2s ease-in-out;
}
.order_info_critical li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 10px;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: left;
}
.order_info_critical li:hover 
{
    border-color: var(--color-theme);
}
.order_validity
 {
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
    padding: 1px 10px;
    margin-left: 20px;
    color: var(--primary_text_color);
    font-weight: bold;
    border-radius: 10px;
 }
 .order_invalidity 
 {
    background: var(--red-color);
    border: 1px solid var(--red-color);
    padding: 1px 10px;
    margin-left: 20px;
    color: var(--primary_text_color);
    font-weight: bold;
    border-radius: 10px;
 }
 .rejected_amount_wrapper 
 {
    background: var(--red-color) !important;
    border-color: var(--red-color) !important;
    color: var(--primary_text_color) !important;
 }
 .total_amount_wrapper 
 {
    background: var(--text_light_white) !important;
    border-color: var(--text_light_white) !important;
    color: var(--background-dark_plus) !important;
 }

 .button_disabled 
 {
    z-index: 0;
    filter: blur(5px);
 }
 .button_disabled:hover 
 {
    cursor:not-allowed;
 }

.quantity_data_wrapper span
{
    margin: 0px 3px;
    padding: 1px 6px;
    background: var(--color-theme);
    border: 2px solid var(--color-theme);
    color: var(--background-dark_plus) !important;
    font-weight: bolder;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.action_button_wrapper_group 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.action_button_wrapper_group button 
{
    border: 2px solid var(--color-theme);

    color: var(--color-theme);
    background: var(--background-dark_plus);
    border-radius: 10px;
    margin: 2px 4px;

    font-size: var(--default-font-scale-lrg);
    height: 2em;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.reject_order_item 
{
    /* background: var(--red-color) !important; */
    border-color: var(--red-color) !important;
    color: var(--red-color) !important;
}
.reject_order_item:hover 
{
    background: var(--red-color) !important;
    border-color: var(--red-color) !important;
    color: var(--primary_text_color) !important; 
}
.action_button_wrapper_group button:hover 
{
    background: var(--color-theme);
    color: var(--background-dark_plus);
    border-radius: 2px;

}
.group_header 
{
    padding: 3px 10px;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.group_header button 
{
    background: none;
    color: var(--text_light_white);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    font-size: 0.8em;
    border: none;
}
.group_header button:hover
{
    color: var(--color-theme);
}
.group_header h2 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    text-transform: capitalize;
    border: 2px solid var(--text_light_white);
    padding: 2px 5px;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.special_order_wrap
{
    background: var(--color-theme) !important;
    border: 2px solid var(--color-theme) !important;
    color: var(--background-dark_plus) !important;
}