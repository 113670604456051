.data_body 
{
    padding: 20px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
}
.inner_preview_data_wrapper 
{
    display: flex;
    justify-content: center;
}
.paper_wrapper_dash 
{
    width: 70%;
    background: var(--primary_text_color);
}
.meta_data_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    padding: 5px 20px;
    text-align: center;
    color: var(--print_paper_background);
}
.meta_data_wrapper h2
{
    font-size: 1.1em;
    margin: 5px;
    text-transform: uppercase;
}

.main_paper_data_wrapper 
{
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: var(--print_paper_background);
    border-bottom: 2px solid var(--inventory-type-color);
    border-top: 2px solid var(--inventory-type-color);
    padding: 10px 0px;
}
.paper_list_wrapper 
{
    width: 100%;
    padding: 5px 20px;
}
.paper_list_wrapper li 
{
    list-style: none;
    border-bottom: 1px solid var(--background-light);

    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
    font-size: var(--default-font-scale-min);
}
.paper_list_wrapper li span, .fake_tabke_header span
{
    width: 16.6%;
    text-align: center;
}
.fake_tabke_header
{
    
    font-size: var(--default-font-scale-mid) !important;
    font-weight: bold !important;
}
.entry_detail 
{
    padding: 20px;
}
.entry_detail li 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    list-style: none;
    font-size: var(--default-font-scale-mid);

    border-bottom: 1px solid var(--background-light);
    margin: 3px;
    padding: 2px 10px;
    background: var(--print_paper_background);
}
.entry_deatil_val 
{
    font-weight: bold;
}

.form_action_button_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: right;
    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.form_action_button_wrapper button 
{
    padding: 5px 10px;
    margin: 5px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.form_action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.form_action_button_approve 
{
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
}
.form_action_button_req_dismiss 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}
.form_action_button_print 
{
    color: var(--primary_text_color);
    background: var(--dimm_white);
    border: 1px solid var(--dimm_white);
}
.form_action_button_dismiss 
{
    color: var(--primary_text_color);
    background: var(--red-color);
    border: 1px solid var(--red-color);
}
@media print {
    .paper_wrapper_dash 
    {
        width: 100%;
    }
  }