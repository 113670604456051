.data_body
{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;  
}
.view_loading 
{
    height: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.locating_in_action_note
{
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    margin: 2px;
    font-weight: bold;
    color: var(--text_light_white);
}
.map_route_view_wrapper 
{
    margin: 5px;
    width: 50%;
}
.pre_readable_data 
{
    margin: 5px;
    width: 40%;
}
.known_location_right li 
{
    border-radius: 10px;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    list-style: none;
    margin: 3px;
    padding: 3px 7px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.known_location_left li 
{
    list-style: none;
    color: var(--color-theme);
    text-align: center;
    font-size: var(--default-font-scale-lrg);
    font-weight: bold;
}
.info_wrapper 
{
    border: 2px solid var(--background-dark_minus);
    background: var(--background-dark_minus);
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}
.known_location_right .loc_tag 
{
    width: 10em;
}
.loc_val 
{
    font-weight: bold;
    text-decoration: underline;
    text-transform: capitalize;
}
.action_button_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px;
}
.map_layer_swicth 
{
    display: flex;
    justify-content: flext-start;
    align-items: center;
    align-content: center;
}