.single_input_val_map 
{
    width: 15em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    background: var(--background-dark);
    border: 1px solid var(--background-dark);
    margin: 5px;
    padding: 2px 5px;
    border-radius: 2px;
}
.item_tag, .item_input input
{
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;
}
.item_input input 
{
    width: 90%;
    text-align: center;
    padding: 7px;
    background: var(--background-dark_minus);
}
.filter_paper_list_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    padding: 10px;
}
.filter_inner_list_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    padding: 5px 10px;
    width: 45%;
    border-radius: 5px;

    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
}
.inventory_critical_actions 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.filter_item_list 
{
    flex: 2 !important;
}
.filter_item_price_total
 {
    color: var(--color-theme);
    font-weight: bold;
    text-decoration: underline;
 }

 .header_wrapper_list 
 {
    border-bottom: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 5px;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    
 }
 .action_button_wrapper_product_class_filter select
{
    margin: 5px;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    border-bottom: 3px solid var(--background-light);
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    user-select: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
}
.helper_tick 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    border-bottom: 3px solid var(--background-light);
    padding: 5px 10px;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    cursor: pointer;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
}
.helper_tick span 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    padding: 2px 10px;
}

.form_action_button_wrapper button
{
    cursor: pointer;
    margin: 5px;
    padding: 5px 20px;
    border-radius: 2px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 0.3s ease-in-out;
}
.form_action_button_save 
{
    background: var(--dimm_white);
    border: 1px solid var(--dimm_white);
    color: var(--primary_text_color);
}
.form_action_button_dismiss 
{
    background: var(--red-color);
    border: 1px solid var(--red-color);
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-min);
}
.empty_not 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    font-size: 1.5em;
    height: 10em;
    color: var(--text_light_white);
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.2;
    user-select: none;
    text-align: center;
}

/* FILTER */
.filter_workshop_by_name 
{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-light);
    border-radius: 5px;
    padding: 3px 8px;
    width: 25em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper input 
{
    padding: 2px 4px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--text_light_white);
    list-style: none;
    width: 100%;
}


/* Report */

.report_prep_config_wrapper 
{
    margin: 5px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    border-radius: 5px;
    width: 50%;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
}
.report_generate_time_frame_selection 
{
    background: var(--background-dark);
    padding: 5px 10px;
    margin: 5px;
    border-radius: 3px;
}
.report_generate_header_wrapper 
{
    background: var(--background-light);
    padding: 5px 10px;
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
}
.report_generate_time_frame_selection_inner_header 
{
    position: relative;
    padding: 5px 10px;
    border-bottom: 2px solid var(--background-light);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.report_generate_time_frame_selection_inner_header .generate_init_spa 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.report_generate_time_frame_selection_inner_header .generate_init_spa:hover 
{
    color: var(--color-theme);
    text-decoration: underline;
}
.report_generate_time_frame_selection_inner_header h3 
{
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    text-transform: capitalize;
}
.report_generate_time_frame_selection_inner 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 10px;
}
.report_generate_time_frame_selection_inner .inner_report_gn_single_input_wrap 
{
    flex: 1;
}
.report_generate_time_frame_selection_report_button_action 
{
    position: absolute;
    right: 0;
    top: 2em;
    z-index: 90;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    padding: 5px 10px;
    flex-direction: column;
    background: var(--background-dark_minus);
    padding: 5px 10px;
    border: 1px solid var(--background-dark);
}