.action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    padding: 15px;

    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.action_button_wrapper button 
{
    font-weight: bold;
    color: var(--background-dark_plus);
    font-size: 0.8em;
    width: 8em;
    padding: 3px;
    cursor: pointer;
    border-top-left-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.button_approve 
{
    background: var(--main-color);
    border: 1px solid var(--main-color);
}
.button_halt 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}

.input_wrapper_batch_process 
{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.single_data_wrapper 
{
    width: 30%;
}
.batch_data_wrapper
 {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    height: 15em;
    max-height: 15em;
    overflow-y: scroll;
 }
 .input_wrapper_batch_process_mix 
 {
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    margin: 10px;
    width: 80%;
 }
 .input_wrapper_batch_process_expanded_mix_list_wrapper 
 {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-left: 10%;
 }
 .single_mix_data_wrapper 
{
    width: 35%;
}
 .data_header 
 {
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .data_header h2 
 {
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-lrg);
    text-align: center;
 }
.data_per_mix_header
{
    padding: 5px;
}
.data_per_mix_header li 
{
    list-style: none;
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    padding: 3px 15px;
    border-bottom: 2px solid var(--background-dark);
}
