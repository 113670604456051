
/* CHART */
.chart_head 
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border-bottom: 2px solid var(--background-dark);
}
.chart_head li 
{
    list-style: none;
    color: var(--text_light_white);
    background: var(--background-light);
    letter-spacing: 2px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    text-transform: capitalize;
    border: 1px solid var(--text_light_white);
    padding: 5px 10px;
    border-radius: 5px;
}
/* END CHART */