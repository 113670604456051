.dz_action_div_master_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.dz_action_left_wrapper 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    padding: 5px 10px;
    width: 97.6%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}