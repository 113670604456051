.root_layout_wrapper 
{
    position: relative;
}
.elment_wrapper 
{
    display: flex;
    background: var(--background-dark_plus);
    z-index: 2;
    height: 100vh;
}
.element_x_route 
{
    flex-grow: 2;
}
.top_nav_bar 
{
    height: 3em;
    background: var(--background-dark_plus);
}
.desktop_icon_view 
{
    width: 100%;
    height: 100%;
}
