.right_settion_main_wrapper
{
    z-index: 999999;
    width: 20em;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark_minus);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    visibility: visible;
    opacity: 1;
    transition: 0.5s ease-in-out;
}
.right_settion_main_wrapper_hide 
{
    width: 0;
    transition: 0.4s ease-in-out;
    visibility: hidden;
    filter: blur(12px);
    opacity: 0;
}

.right_option_top 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px 10px;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    background: var(--background-dark);
    border: 1px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.right_option_top h2 
{
    color: var(--text_light_white);
    font-size: 0.8em;
}
.right_option_top button 
{
    background: none;
    border: none;
    cursor: pointer;
    font-size: var(--default-font-scale-lrg);
    color: var(--red-color);
}

.workspace_access_list 
{
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.color_scheme_wrapper 
{
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.color_scheme_wrapper .color_chice_notice
{
    text-align: center;
    font-weight: bold;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
}
.key_color_scheme_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
    margin: 3px;
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_minus);
    border-radius: 10px;
}
.key_color_scheme_wrapper li 
{
    border: 2px solid var(--background-light);
    list-style: none;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.apint_color_scheme_wrapper
 {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
 }
 .apint_color_scheme_wrapper li , .usr_option_inner_wrapper_access li
 {
    list-style: none;
    padding: 5px 10px;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin: 2px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex: 1;
    text-transform: capitalize;
    
    
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
 }
 .apint_color_scheme_wrapper li:hover 
 {
    background: var(--background-dark_minus);
    color: var(--text_light_white);
 }
 .tool_quick 
 {
    background: var(--background-dark_plus) !important;
    color: var(--text_light_white) !important;
    font-size: var(--default-font-scale-min) !important;
 }