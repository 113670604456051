.shift_closing_master_wrapper 
{
    height: 100vh;
    background: var(--background-dark_plus);
}
.data_package_master_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.desk_view_receive_order_inner_nav_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px;
}
.desk_view_receive_order_nav
{
    border-bottom: 2px solid var(--color-theme);
    background: var(--background-dark);
    width: 70%;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 10px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
