.emp_attendy_calander_wrapper 
{
    
    padding: 20px;
    animation: 0.2s slide_in ease-in-out;
}
@keyframes slide_in {
    0% {
        scale: 6;
     }
    100% {
        scale: 1;
     }
  }
.emp_attendy_importer 
{
    padding: 20px;
    display: flex;
    justify-content: right;
}
.emp_attendy_importer input 
{
    color: var(--primary_text_color);
}
.xls_attendy_file {
opacity: 0;
position: absolute;
z-index: -1;
}
 .xls_attendy_file_locator 
 {
    cursor: pointer;
    font-size: 12px;
    border: 1px solid var(--background-light);
    background: var(--background-light);
    color: var(--primary_text_color);
    width: 200px;
    padding: 7px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: 0.4s ease-in-out;
 }
 .emp_attendy_importer button 
 {
    margin: 5px;
}

 .attendy_load_button_active
 {
    border: 1px solid var(--grren-color);
    background: var(--grren-color);
    color: var(--primary_text_color);
    font-size: 13px;
    padding: 5px;
    cursor: pointer;
    transition: 1s ease-in-out;
}
.attendy_load_button_active:hover 
{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
 }
 .attendy_load_button_inactive 
 {
    border: 1px solid var(--background-light);
    background: var(--background-light);
    color: var(--background-dark_plus);
    font-size: 13px;
    padding: 5px;
    transition: 1s ease-in-out;
    text-decoration: line-through;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }
 .calander_lock_wrapper 
 {
    display: flex;
    justify-content: right;
 }
 .calander_lock_wrapper button 
 {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    transition: 0.3s ease-in-out;
}
.unlock_cal_button 
{
    color: var(--red-color);
}
.lock_cal_button 
{
    color: var(--grren-color);
}
 .calander_lock_wrapper button:hover 
 {
    color: var(--primary_text_color);
 }