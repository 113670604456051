.main_stock_manager_wrapper 
{
    height: 100vh;
    background: var(--background-dark_plus);
    overflow: scroll;
    width: 100%;
}
.new_stock_creator_main_wrapper_show 
{
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease-in-out;
}
.new_stock_creator_main_wrapper_hide
{
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
}
.stock_manager_card_list 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;
    height: 85vh;
    max-height: 85vh;
    overflow: scroll;
}

.stock_manager_admin_branch_selector_wrapper {
    padding: 30px 10px;
}
.stock_card_head_search_wrapper 
{
    
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.stock_card_head_search_wrapper button 
{
    padding: 7px;
    border: 1px solid #252525;
    background: #252525;
    color: #037ef3;
    font-size: 15px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
    margin-left: 5px;
    transition: 0.3s ease-in-out;
}
.stock_card_head_search_wrapper button:hover 
{
    background: #037ef3;
    border: 1px solid #037ef3;
    color: #252525;
}
.stock_manager_admin_branch_selector_button_wrapper 
{
    border: 1px solid #252525;
    background: #252525;
    border-radius: 5px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 10px 4px;
    box-shadow: #037ef3b6 0px 15px 25px, #037ef356 0px 5px 10px;
}
.stock_manager_admin_branch_selector_button_wrapper button 
{
    cursor: pointer;
    padding: 5px;
    width: 150px;
    color: var(--primary-color);
    background: #414141;
    border: 1px solid #414141;
    margin-right: 7px;
    border-radius: 2px;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: 0.4s ease-in-out;
}
.stock_manager_admin_branch_selector_button_wrapper button:hover 
{
    background: #037ef3;
    border: 1px solid #037ef3;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.stock_manager_admin_branch_selector_button_wrapper .active_branach 
{
    background: #037ef3;
    border: 1px solid #037ef3;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.action_button_main_stock_adder button 
{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
    background: none;
    color: #037ef3;
    border: 2px solid #037ef3;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.action_button_main_stock_adder li 
{
    list-style: none;
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #037ef3;
}
.action_button_main_stock_adder button:hover 
{
    background: #037ef3;
    color: #252525;
}
.action_button_main_branch_switch
 {
    margin: 10px;
    text-align: center;
    width: 20%;
    border: 1px solid #037ef3;
    background: #037ef3;
    padding: 15px 15px;
    margin-bottom: 15px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #414141;
    transition: 0.4s ease-in-out;
    animation: card_anim 1.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }
.action_button_main_branch_switch li
{
    padding: 17px;
    list-style: none;
    font-size: 20px;
    font-weight: 400;
    color: #252525;
}
.stock_manager_page_active_branch 
{
    font-weight: 600;
}
.action_button_main_branch_switch button 
{
    width: 100%;
    padding: 5px;
    font-size: 20px;
    background: #252525;
    color: #037ef3;
    font-weight: 500;
    border: 2px solid #252525;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.action_button_main_branch_switch:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

 /* NEw Stock Card Creator */

 .new_stock_creator_main_wrapper 
 {
    position: absolute;
    background: #414141c5;
    width: 100%;
    height: 100vh;
    top: 0;
    overflow: scroll;
    transition: 0.3s ease-in-out;
 }
 .card_store_pointer 
 {
    font-size: 12px;
    color: #17b978;
 }
 .new_stock_creator_main_win_wrapper 
 {
    border: 1px solid #252525;
    background: #252525;
    position: fixed;
    width: 60%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }

 .new_stock_creator_form_wrapper 
 {
    padding: 40px 5px;
 }
 .stock_creator_win_header 
 {
    padding: 4px;
    border: 1px solid #037ef3;
    background: #037ef3;
    display: flex;
    justify-content: space-between;
 }
 .stock_creator_win_name li 
 {
    font-size: 20px;
    list-style: none;
    color: #252525;
    font-weight: 300;
 }
 .stock_creator_win_header .stock_Creator_win_header_bold 
 {
    font-weight: 500;
 }
 .stock_creator_win_close_icon button 
 {
    width: 25px;
    height: 25px;
    padding: 1px;
    border: 1px solid #252525;
    background: #252525;
    color: #037ef3;
    font-size: 17px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .stock_creator_win_close_icon button:hover 
 {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: #414141;
 }
 .new_stock_creator_form_wrapper input
 {
    padding: 5px;
    display: block;
    width: 300px;
    margin-bottom: 7px;
    border: 1px solid #414141;
    background: #414141;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }
 .new_stock_creator_form_wrapper input:focus 
 {
    box-shadow: #037ef371 0px 2px 4px 0px, #037ef34b 0px 2px 16px 0px;
 }
 .stock_creator_form_submit_button_wrapper 
 {
    padding: 20px;
    width: 70%;
    margin: auto;
    text-align: center;
 }
 .stock_creator_form_submit_button_wrapper button 
 {
    width: 150px;
    padding: 5px;
    margin: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.new_stock_creator_dis_button 
{
    border: 1px solid #414141;
    background: #414141;
    color: var(--primary-color);
 }
 .new_stock_creator_sub_button 
 {
    border: 1px solid #037ef3;
    background: #037ef3;
    color: #252525;
 }
 .new_stock_creator_form_input_field_wrapper 
 {
    display: flex;
    justify-content: space-evenly;
 }
 .new_stock_icreator_dy_input_field_wrapper 
 {
    border: 1px solid #252525;
    background: linear-gradient(-90deg, #252525, #037ef3ad, #252525, #037ef3ad);
	background-size: 400% 400%;
    padding: 15px;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
 }
 .new_stock_icreator_dy_input_field_wrapper_closing 
 {
    scale: 0;
    opacity: 0;
    transition: 0.5s ease-in-out;
 }
 .close_win 
 {
    padding: 5px;
    text-align: right;
 }
 .close_win button 
 {
    background: #037ef3;
    border: 1px solid #037ef3;
    color: #252525;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .close_win button:hover 
 {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #252525;
 }
 .new_stock_dynamic_input 
 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-height: 40vh;
    width: 320px;
    transition: 0.5s ease-in-out;
    overflow-y: scroll;
    overflow-x: hidden;
 }
 .stock_creator_form_submit_button_wrapper button:hover 
 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
 }
 .add_new_stock_item_button_wrapper 
 {
    padding: 50px;
    text-align: right;
 }
 .add_new_stock_item_button_wrapper button 
 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 17px;
    border: 1px solid #037ef3;
    background: #037ef3;
    color: #252525;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .add_new_stock_item_button_wrapper button:hover 
 {
    border: 1px solid #414141;
    background: #414141;
    color: var(--primary-color);
 }
 .new_stock_input_field 
 {
    display: flex;
 }
 .stock_in_forget_noti 
 {
    padding: 5px 10px;
    color: #f3bb03;
    transition: 0.3s ease-in-out;
 }
 .empty_input_hide 
 {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in-out;
 }
 .empty_input_show 
 {
   animation: fadeInOut 0.5s ease infinite;
 }
 @keyframes fadeInOut {
   0%, 100% {
     opacity: 0;
   }
   50% {
     opacity: 1;
   }
 }


 /* Header */
 .stock_manager_header_wrapper 
 {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   flex-wrap: wrap;

   background: var(--background-dark_minus);
   padding: 5px 10px;
 }
 .workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    border-radius: 2em;
    padding: 3px 8px;
    width: 25em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.stock_filter_wrapper 
{
   width: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
}
.workshop_filter_input_wrapper input 
{
    padding: 2px 4px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    color: var(--text_light_white);
}
.workshop_filter_input_wrapper span 
{
    padding: 2px 8px;
    font-size: var(--default-font-scale-mid);
    text-wrap: nowrap;
    color: var(--text_light_white);
}