.production_mix_main_wrapper 
{
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background: var(--background-dark_plus);
}
.mix_box_wrapper 
{
    display: flex;
    justify-content: center;
    padding: 20px;
}
.production_main_list_upper 
{
    border: 3px solid var(--background-dark_minus);
    background: var(--background-dark_minus);
    width: 90%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.production_main_list 
{
    display: flex;
    justify-content: center;
    padding: 20px;
    max-height: 500px;
    overflow-y: scroll;
}
.mix_list_wrapper 
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    
}
.mix_list_wrapper li 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 85%;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    border-left: 1px solid var(--color-theme);
    border-right: 1px solid var(--color-theme);
    padding: 2px 20px;
    margin: 3px;
    text-transform: capitalize;
    transition: 0.2s ease-in-out;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.mix_list_wrapper li:hover 
{
    scale: 1.02;
    background: var(--background-dark_minus);
}
.mix_action button
{
    border: 1px solid var(--text_light_white);
    color: var(--text_light_white);
    background: none;
    padding: 4px;
    font-size: 0.8em;
    margin: 3px 5px;
    border-radius: 1px;
    transition: 0.3s ease-in-out;
}
.mix_action button:hover 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    scale: 1.2;
    cursor: pointer;
}
.mix_ing_count 
{
    color: var(--color-theme);
    font-weight: bold;
}
.head_prod 
{
    border: 1px solid var(--background-light);
    background: var(--background-light);
    padding: 5px;
    display: flex;
    justify-content: space-between;
}
.mix_top_button 
{
    border: 1px solid var(--background-dark);
    background: var(--background-dark_plus);
    color: var(--text_light_white);
    font-size: 13px;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.mix_top_button:hover 
{
    background: var(--text_light_white);
    border: 1px solid var(--text_light_white);
    color: var(--background-dark_plus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.head_prod h3 
{
    color: var(--text_light_white);
    font-size: 20px;
    text-transform: capitalize;
}