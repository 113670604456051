.master_product_type_inner_config_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    padding: 10px;
}
.personal_employee_info_input_col_group 
{
   width: 45%;
}
.new_employee_pp_warpper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.new_employee_pp_warpper img 
{
    width: 10em;
    height: 10em;
    border-radius: 10px;
    border: 2px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.new_employee_preview_not_warpper
 {
    padding: 10px;
 }
.new_employee_preview_not_warpper li 
{
    list-style: none;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    text-align: center;
    color: var(--text_light_white);
}