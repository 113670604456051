.emp_penality_inner_wrapper 
{
    display: flex;
    justify-content: space-evenly;
}
.emp_penality_inner_content 
{
    width: 100%;
}
.emp_local_penality_nav 
{
    display: flex;
    justify-content: right;
}
.emp_local_penality_nav button 
{
    padding: 3px 6px;
    background: var(--background-dark);
    border: 1px solid var(--background-dark);
    margin-left: 5px;
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-min);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.emp_local_penality_nav button:hover 
{
    background: var(--color-theme);
    border-color: var(--color-theme);
    color: var(--primary_text_color);
    font-weight: bold;
}
.emp_cash_penality_history, .emp_penality_demotion_history 
{
    border-radius: 5px;
    background: var(--background-dark_minus);
    border: 2px solid var(--background-dark);
    margin: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.emp_penality_demotion_header 
{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    padding: 4px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.emp_penality_demotion_header h2 
{
    font-size: var(--default-font-scale-mid);
    font-weight: 300;
    color: var(--text_light_white);
}
.emp_penality_demotion_header h2 span 
{
    font-weight: 800 !important;
}
.emp_penality_demotion_header_action 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.emp_penality_list 
{ 
    padding: 10px;
    height: 12em;
    max-height: 12em;
    overflow: scroll;
}
.emp_penality_list li 
{
     margin: 5px 2px;
    border: 1px solid var(--background-light);
    border-left: 3px solid var(--red-color);
    padding: 3px 8px;
    background: var(--background-dark_plus);

    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s ease-in-out;
}
.emp_penality_list li:hover 
{
    background: var(--background-dark);
}
.emp_penality_list li span 
{
    flex: 1;
}
.action_preview_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
