.data_upper_wrapper_prox_table 
{
    width: 100%;
}
.core_master_data_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    max-height: 100vh;
    overflow-y: scroll;
}