.da_main_wrapper 
{
    position: fixed;
    background: var(--alpha-background); 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    
    
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 999;
    animation: slow_in 0.5s ease-in-out;
}
@keyframes slow_in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.da_inner_card 
{
    width: 60%;
    border: 2px solid var(--background-dark);
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    animation: slow_in 0.3s ease-in-out;
}
@media (max-width: 1024px) { /* Tablet */
    .da_inner_card {
        width: 80%;  /* 80% width on tablets */
    }
}

@media (max-width: 768px) { /* Mobile */
    .da_inner_card {
        width: 100%; /* Full width on mobile */
    }
}

@keyframes slow_in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.da_inner_card_header 
{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background: var(--background-light);
}
.da_inner_card_header li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
    font-weight: 300;
}
.da_inner_card_header button 
{
    border: none;
    background: none;
    color: var(--text_light_white);
    font-size: 20px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.da_inner_card_header button:hover
{
    color: var(--red-color);
}
.da_inner_card_body 
{
    background: var(--background-dark_plus);
    text-align: center;
}
.da_inner_card_body li 
{
    list-style: none;
    color: var(--text_light_white);
}
.da_inner_card_action_button_wrapper 
{
    padding: 5px;
    border: 1px solid var(--background-light);
    background: var(--background-dark);

    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.da_inner_card_action_button_wrapper button 
{
    width: 100px;
    padding: 3px;
    margin: 5px;
    cursor: pointer;
    border-radius: 3px;
    font-size: var(--default-font-scale-min);
    font-weight: bolder;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: 0.3s ease-in-out;
}
.agree:hover
{
    border: 2px solid var(--grren-color);
    background: var(--background-dark_plus);
    color: var(--grren-color);
}
.agree
{
    border: 2px solid var(--grren-color);
    background: var(--grren-color);
    color: var(--primary_text_color);
}
.disagree:hover
{
    border: 2px solid var(--red-color);
    background: var(--background-dark_plus);
    color: var(--red-color);
}
.disagree 
{
    border: 2px solid var(--red-color);
    background: var(--red-color);
    color: var(--primary_text_color);
}
.da_note_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px 50px;
}
.da_big_icon i
{
    font-size: 3.5em;
    color: var(--red-color);
}
.da_msg 
{
    padding: 20px;
    font-size: var(--default-font-scale-mid);
    user-select: none;
}
.action_name 
{
    font-weight: 700;
}