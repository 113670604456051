.packge_data_wrapper 
{
    margin: 5px 10px;
    padding: 10px;
    /* background: var(--print_paper_background);
    border-bottom: 2px solid var(--inventory-type-color);
    border-top: 2px solid var(--inventory-type-color);
    padding: 10px 0px; */
    border: 1px solid var(--print_paper_background_dark_plus);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 10px;
}
.main_prod_info_wrapper li
{
    border-radius: 10px;
    padding: 5px 15px;
    list-style: none;
    font-weight: bold;
    border: 2px solid var(--print_paper_background);
    background: var(--print_paper_background_dark_plus);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
}
.ing_list_data_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
}
.data_marging_wrapper 
{
    width: 80%;
    border-radius: 10px;
    border: 1px solid var(--print_paper_background);
    background: var(--print_paper_background);
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.data_marging_wrapper li 
{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 2px 10px;
    border-bottom: 2px solid var(--print_paper_background_dark_plus);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
}
.data_marging_wrapper li span 
{
    width: 33%;
}
.right_align 
{
    text-align: right;
}