.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.orderbale_state 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 3px 5px;
    margin: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    user-select: none;
}
.orderbale_state span 
{
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;
}
.master_product_type_inner_avail_workspace_list 
{
    margin: 10px;
    width: 40%;
}
.master_prod_avail_list 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.master_prod_avail_branch 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 4px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 1px 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.workspace_name_tick 
{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
}
.master_prod_avail_branch:hover 
{
    background: var(--background-dark);
    scale: 1.02;
}
.master_prod_avail_branch  li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    margin: 5px;
}
.master_product_type_inner_avail_workspace_list_header 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px;

    border: 2px solid var(--background-dark);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 10px;
}
.master_product_type_inner_avail_workspace_list_header button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 0.8em;
    float: top;
    &:hover 
    {
        color: var(--color-theme);
    }
}
.master_product_type_inner_avail_workspace_list_header h1 
{
    font-size: 0.8em;
    text-transform: capitalize;
    color: var(--text_light_white);
}
.master_product_type_inner_config_wrapper 
{
    width: 90%;
}
.master_product_type_inner_config_wrapper input 
{
    text-transform: capitalize;
}
.master_product_type_inner_config_wrapper li
{
    list-style: none;
    margin: 5px;
}
.master_product_type_inner_config_wrapper li input 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    border-radius: 3px;
    width: 70%;
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
}
.data_per_workspace 
{
   width: 50%;
}
.data_per_workspace input, .data_per_workspace input
{
   width: 80%;
   padding: 5px 10px;
   border: 1px solid var(--background-dark);
   border-bottom: 2px solid var(--background-dark);
   background: var(--background-dark_plus);
   color: var(--color-theme);
   border-radius: 1px;
   font-size: 0.8em;
   font-weight: bold;
   box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
   transition: 0.3s ease-in-out;
}
.data_per_workspace input:hover 
{
    background: var(--background-dark_minus);
    border-bottom: 2px solid var(--color-theme);
}


.po_mat_list_wrapper 
{
    width: 90%;
    padding: 10px;
    margin: 10px 20px;
    border-radius: 5px;

    background: var(--background-dark_minus);
    max-height: 8em;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.po_mat_list 
{
    width: 100%;
    padding: 10px;
    height: 8em;
    max-height: 8em;
    overflow-y: scroll;
    
}
.remove_button_wrapper
{
  text-align: center;
  margin: 5px;
  padding: 3px;
  background: var(--red-color);
  border: 2px solid var(--red-color);
  color: var(--primary_text_color);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.remove_button_wrapper:hover 
{
  font-weight: bold;
  scale: 1.1;
  background: none;
  color: var(--red-color);
}
.remove_button_wrapper_fake 
{
   margin: 10px;
  padding: 6px;
}
.input_fild_shild 
{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
 }
 .dx_stock_request_new_add_button_wrapper, .reason_to_eject_stock_input_wrapper 
 {
    padding: 5px 10px;
 }