.data_pro_table_wrapper
 {
   min-width: 45em;
   flex: 1;
 }
 .md_inner_data_table_wrap 
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
 }
 .tax_view_allowace_amount_has_tax 
 {
   color: var(--red-color);
 }
 .tax_view_allowace_amount_has_zero_tax 
 {
  color: var(--color-theme);
 }