.x_chef_production_stack_group_wrapper 
{
    border: 1px solid var(--background-dark);
    width: 80%;
    min-width: 35em;
    flex: 1;
    border-radius: 5px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
} 
.x_chef_task_body_wrapper 
{
    background: var(--background-dark);
    overflow: scroll;
}
.x_chef_production_header 
{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: var(--background-light);
    padding: 5px 10px;
}
.x_chef_production_header h2 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
}
.chef_profile_outer_wrapper 
{
    display: flex;
    padding: 5px;
    gap: 1px;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
}
.chef_profile 
{
    text-align: center;
    width: 20%;
    padding: 10px;
    background: var(--background-dark_plus);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.chef_profile img 
{
    width: 5em;
    height: 5em;
    border-radius: 2em;
}
.owner_name_and_stuff li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    text-align: center;
}
.active_task_bundle_wrapper 
{
    width: 75%;
    
}
.data_view_mini_header
{
    margin: 10px 3px;
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.data_view_mini_header h3 
{
    color: var(--text_light_white);
    font-size: 0.8em;
    text-transform: uppercase;
}
.dispatched_production_order 
{
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
    border: 1px solid var(--background-dark_plus);
    padding: 10px;
    background: var(--background-dark_plus);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.dispatched_production_order  li 
{
    font-weight: bold;
    list-style: none;
    display: flex;
    margin: 6px 3px;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 1px solid var(--background-light);
    background: var(--background-dark);
    border-radius: 5px;
    padding: 3px 6px;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);

    cursor: pointer;
    transition: 0.3s ease-in-out;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.dispatched_production_order  li span 
{
    flex: 1;
}
.action_buttons_wrapper 
{
    display: flex;
    justify-content: right;
}
.state_proccessed 
{
    color: var(--color-theme) !important;
    font-weight: bolder !important;
}
.state_unppro 
{
    color: var(--red-color) !important;
    font-weight: bolder !important;
}
.minute_value 
{
    color: var(--color-theme);
    font-weight: bolder !important;
    text-decoration: underline;
}