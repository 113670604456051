.login_main_wrapper {
  height: 100vh;
  opacity: 1;
  overflow: hidden;
  background: var(--background-dark_minus);
  background-position: center;
}
.form_wrapper 
{
  position: absolute;
  width: 18em;
  padding: 30px 10px;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 1em;
  border: 2px solid var(--background-dark_minus);
  background: var(--background-dark_plus);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.form_wrapper_loading .head_stick_logo img
{                  
  animation: rotate360 1s ease-in-out infinite;
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotateShadow {
  0% {
    box-shadow: 5px 5px 30px rgb(1, 1, 1), -5px -5px 30px rgb(57, 57, 57);
  }
  25% {
    box-shadow: 5px -5px 30px rgb(1, 1, 1), -5px 5px 30px rgb(57, 57, 57);
  }
  50% {
    box-shadow: -5px -5px 30px rgb(1, 1, 1), 5px 5px 30px rgb(57, 57, 57);
  }
  75% {
    box-shadow: -5px 5px 30px rgb(1, 1, 1), 5px -5px 30px rgb(57, 57, 57);
  }
  100% {
    box-shadow: 5px 5px 30px rgb(1, 1, 1), -5px -5px 30px rgb(57, 57, 57);
  }
}
.comp_name 
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.head_stick_logo 
{
  width: 100%;
  text-align: center;
  user-select: none;
}
.company_info 
{
  margin-top: -1em;
  padding-bottom: 5px;
}
.head_stick_logo img {
  -webkit-user-drag: none;
  width: 6em;
  height: 6em;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.07);
  }
}
.head_stick_logo h2 
{
  font-size: 1.2em;
  color: var(--text_light_white);
  font-weight: bolder;
  margin-top: -0.5em;
}
.login_main_wrapper .form_wrapper form 
{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}



.input_field_wrapper 
{
  border: 2px solid var(--background-dark_minus);
  background: var(--background-dark_minus);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2em;
  margin: 5px;
  width: 15em;
  transition: 0.3s ease-in-out;
  /* box-shadow: 3px 3px 15px rgb(0, 0, 0),
                   -3px -3px 15px rgb(58, 58, 58); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.input_field_wrapper_key 
{
  border: 2px solid var(--background-dark_minus);
  background: var(--background-dark_minus);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2em;
  margin: 5px;
  width: 15em;
  transition: 0.3s ease-in-out;
  /* box-shadow: 3px 3px 15px rgb(0, 0, 0),
                   -3px -3px 15px rgb(58, 58, 58); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.left_side_input_wrapper 
{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.show_pass_key 
{
  padding: 5px 10px;
  font-size: var(--default-font-scale-mid);
  color: var(--text_light_white);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.show_pass_key:hover 
{
  color: var(--primary_text_color);
}
.input_field_wrapper:hover .input_icon 
{
  color: var(--background-dark_plus);
  background: var(--primary_text_color);
}
.input_field_wrapper_key:hover .input_icon 
{
  color: var(--background-dark_plus);
  background: var(--primary_text_color);
}
.input_icon 
{
  padding: 7px;
  font-size: var(--default-font-scale-mid);
  border-radius: 2em;
  border: 1px solid var(--background-dark_plus);
  background: var(--background-dark_plus);
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  box-shadow: 3px 3px 15px rgb(0, 0, 0),
                   -3px -3px 15px rgb(58, 58, 58);
}
.input_value input
{
  padding: 4px 5px;
  width: 90%;
  outline: none;
  font-size: var(--default-font-scale-min);
  font-weight: bold;
  color: var(--text_light_white);
  transition: none !important;
  background: var(--background-dark_minus);
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

.input_value input:-webkit-autofill {
  -webkit-box-shadow: 10px 10px 10px 100px var(--background-dark_minus) inset !important;
  -webkit-text-fill-color: var(--text_light_white);
  outline: 1px solid var(--background-dark_minus) !important;
  font-size: var(--default-font-scale-min) !important;
  font-weight: bold !important;
}
/* input:-webkit-autofill {
  background-color: var(--background-dark) !important;
}
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    background-color: #f9f9f9 !important; 
    border: 1px solid #ff0000 !important;
}
input:-moz-autofill {
  background-color: #310000 !important;
  color: #333 !important;
} */
.user_image_wrapper img 
{
  width: 10em;
  height: 10em;
}
.input_icon 
{
  color: var(--text_light_white);
}
.forget_pass 
{
  padding: 10px;
  text-align: right;
}
.forget_pass a 
{
  font-size: var(--default-font-scale-mid);
  color: var(--text_light_white);
}
.action_button_wrapper 
{
  padding: 5px;
  display: flex;
  justify-content: center;
}
.action_button_wrapper button 
{
  width: 15em;
  padding: 3px;
  font-size: var(--default-font-scale-mid);
  font-weight: bold;
  text-transform: uppercase;
  background: var(--background-dark_minus);
  border: 2px solid var(--background-dark_minus);
  color: var(--text_light_white);
  cursor: pointer;
  border-radius: 2em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  transition: 0.3s ease-in-out;
}
.action_button_wrapper button:hover 
{
  /* color: var(--primary_text_color); */
  background: var(--background-dark);
  /* background: none; */
  /* box-shadow: 3px 3px 15px rgb(58, 58, 58),
  -3px -3px 15px rgb(0, 0, 0); */
}

/* Sing in msg */
.msg_good 
{
  border: 2px solid var(--red-color);
  background: var(--red-color);
  margin: 10px;
  border-radius: 5px;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.msg_load 
{
  border: 2px solid var(--inventory-type-color);
  background: var(--inventory-type-color); 
  margin: 10px;
  border-radius: 5px;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in-out;
}
.msg_icon 
{
  padding: 10px;
  background: var(--background-dark_plus);
  color: var(--red-color);
  border-radius: 5px;
}
.msg_icon_load 
{
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in-out;
  padding: 5px;
  font-size: var(--default-font-scale-min);
  background: var(--background-dark_plus);
  color: var(--inventory-type-color);
  border-radius: 5px;
}
.msg_text 
{
  color: var(--background-dark_plus);
  font-weight: bold;
  font-size: var(--default-font-scale-min);
  text-transform: uppercase;
}
.msg_load_hide 
{
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}