.input_list_wrapper
 {
    width: 45%;
    padding: 10px;
 }
 .form_wrapper 
 {
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
 }
