.on_prodcution_line_master_wrapper 
{
    width: 100%;
    height: 100vh;
    overflow: scroll;
}
.x_chef_workgroup_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    width: 100%;
}