.unknown_route 
{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: var(--background-dark_plus);    
}
.show_case 
{
    width: inherit;
    height: inherit;
}
/* .error_code 
{
    transform:
    perspective(50px)
    rotateX(15deg);
} */
.error_code h2 
{
    border: 2px solid var(--red-color);
    padding: 3px 8px;
    border-radius: 5px;
    color: var(--red-color);
}
.data_wrapper 
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
    gap: 10px;
    height: inherit;
}
.inner_wrap 
{
    color: var(--background-light);
    font-size: 0.8em;
    margin: 5px;
    user-select: none;
}
.inner_wrap span 
{
    text-decoration: underline;
    cursor: help;
    font-weight: bold;
}