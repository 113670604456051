.mini_card_main_wrapper 
{
    min-width: 20em;
    margin: 5px;
    background: var(--background-light);
    border: 2px solid var(--background-light);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.mini_card_main_wrapper:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-bottom: 2px solid var(--color-theme);
}
.mini_card_header 
{
    padding: 5px 10px;
    background: var(--background-dark_minus);
    display: flex;
    justify-content: space-between;
}
.mini_card_header h2 
{
    color: var(--text_light_white);
    font-size: 13px;
    text-transform: uppercase;
}
.noti_wrapper 
{
    list-style: none;
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    color: var(--background-dark_plus);
    font-size: 10px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.1s ease-in-out;
    animation: pulse 1.0s infinite, smooth_in 0.6s;
}
.noti_wrapper_hide
{
    display: none;
}
@keyframes pulse {
	0% {
		/* transform: scale(0.95); */
		box-shadow: 0 0 0 0 var(--color-theme);
	}

	70% {
		/* transform: scale(1); */
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}

	100% {
		/* transform: scale(0.95); */
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.noti_wrapper:hover 
{
    width: 130px;
    border-radius: 2px;
}
.mini_card_body_wrapper 
{
    padding: 10px;
}
.mini_card_body_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: 11px;
    margin: 3px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 10px;
}
.card_min_val 
{
    padding: 1px 5px;
    font-weight: bold;
}