.input_list_wrapper
 {
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    flex-wrap: wrap;
    animation: slid_in 0.2s ease-in-out;
    width: 100%;
 }
 .input_min 
 {
   width: 45%;
 }
  @keyframes slid_in {
   0% {
      margin-left: -150%;
      opacity: 0;
   }
   100% {
      margin-left: 0;
      opacity: 1;
   }
 }