.offline_page_main_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    width: 100%;
    height: 100vh;
    background: var(--background-dark_plus);
    position: absolute;
}
.offline_page_main_wrapper h1 
{
    font-size: 1.2em;
    margin: 5px;
    color: var(--text_light_white);
}
.main_icon_wrap 
{
    width: 100%;
    color: var(--red-color);
    font-size: var(--default-font-scale-lrg);
    text-align: center;
}
.relax_text 
{
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    list-style: none;
    color: var(--background-light);
}