.Employee_manager_main_wrapper
 {
    background: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
 }
 .employee_bundle_wrapper 
 {
   display: flex;
   gap: 15px;
   justify-content: center;
   align-items: start;
   flex-wrap: wrap;
 }
