.box_wrapper_filter 
{
    border: 2px solid var(--background-dark);
    border-top: 2px solid var(--color-theme);
    background: var(--background-dark_plus);
    width: 15em;
    border-radius: 3px;
    z-index: 3;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.mini_win_header 
{
    background: var(--background-dark_minus);
    padding: 3px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.mini_win_header h4 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
}
.mini_win_header button 
{
    color: var(--red-color);
    background: none;
    border: none;
    cursor: pointer;
}
.chef_list_wrapper 
{
    height: 10em;
    max-height: 10em;
    overflow: scroll;
    padding: 10px;
}
.chef_list_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 3px 6px;
    border: 2px solid var(--background-dark);
    margin: 4px;
    background: var(--background-dark_plus);
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 1;

    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.chef_list_wrapper li span 
{
    flex: 1;
}
.chef_list_wrapper li:hover 
{
    border-color: var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.chef_image_wrapper img
{
    width: 3em;
    height: 3em;
    border-radius: 50%;
    border: 2px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.chef_name_wrapper 
{
    text-align: left;
    flex: 4 !important;
    font-weight: bolder !important;
}

.mini_box_dash_filter 
{
    padding: 5px;
}
.mini_box_dash_filter input 
{
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    border: 3px 6px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    font-size: var(--default-font-scale-min);
    font-weight: bolder;
    color: var(--color-theme);
    width: 90%;
    transition: 0.3s ease-in-out;
}
.mini_box_dash_filter input:hover 
{
    border-bottom: 2px solid var(--color-theme);
}

.mini_navigator 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 5px;

    border-bottom: 2px solid var(--background-light);
}
.mini_navigator button 
{
    padding: 3px 6px;
    border-radius: 20px;
    margin: 3px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    font-size: var(--default-font-scale-min);
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    color: var(--background-dark_plus);
    font-weight: bolder;
}
.data_input_filter li
{
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 2em;
}
.data_input_filter input {
    padding: 3px 6px;
    text-align: center;
    font-size: var(--default-font-scale-lrg);
    width: 90%;
}