.role_managmanet_main_wrapper 
{
    background: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
}
.role_managmanet_inner_wrapper 
{
    padding: 10px;
}
.list_parent 
{
    display: flex;
    justify-content: center;
}
.list_wrapper 
{
    width: 95%;
    background: var(--background-dark);
    border: 1px solid var(--background-light);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.list_action_but_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: left;
    margin: 5px;
    border-radius: 10px;
    border-bottom: 2px solid var(--background-light);
    background: var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.list_col 
{
    padding: 10px;
    min-height: 30em;
    height: 40em;
    max-height: 50em;
    overflow-y: scroll;
}
.list_wrapper li 
{
    list-style: none;
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 5px;
    padding: 3px 6px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    margin: 3px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.role_name 
{
    width: 30%;   
}

.list_wrapper li:hover 
{
    background: var(--background-dark_minus);
    border-bottom: 2px solid var(--text_light_white);
}
.bar_wrap 
{
    width: 35%; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bar_value 
{
    padding: 5px;
    color: var(--color-theme);
    font-weight: bold;
    font-size: 0.8em;
}
.bar_wrap_out {
    border: 1px solid var(--background-light);
    background: var(--background-light);
    width: 100%;
    height: 4px;
    border-radius: 10px;
    padding: 1px;
}
.bar_wrap_in 
{
    background: var(--default_gradient);
    width: 100%;
    height: 4px;
    border-radius: 10px;
    transition: 0.5s ease-in-out;
}
.role_action 
{
    display: flex;
    justify-content: center;
    align-items: center;
}