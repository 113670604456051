.pre_order_main_wrapper {
    background-color: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
}
.desk_view_manager_master_list_view_wrapper 
{
    max-height: 90vh;
    overflow: scroll;
    padding: 4px 8px;
}
.desk_view_manage_header_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    padding: 5px;
    margin-top: 10px;
}
.desk_view_manage_header_inner_wrapper 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    width: 90%;
    padding: 5px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
