.transaction_list_inner_single
{
    position: relative;
    cursor: pointer;
    list-style: none;
    color: var(--text_light_white);
    border-radius: 1em;
    border-bottom: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    margin: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-min);
    padding: 3px 10px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.transaction_list_inner_single:hover 
{
    border-bottom: 2px solid var(--color-theme);
}
.single_entry_action_take_incoming_stock_request 
{
    z-index: 99;
    border-radius: 5px;
    top: 2em;
    right: 0;
    position: absolute;
    border: 1px solid red;
    width: 15em;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.single_entry_action_take_incoming_stock_request li 
{
    list-style: none;
    color: var(--text_light_white);
    font-weight: bold;
    padding: 3px 6px;
    border: 1px solid var(--background-dark);
    background: var(--background-light);
    margin: 1px;
    transition: 0.3s ease-in-out;
}
.single_entry_action_take_incoming_stock_request li:hover 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
}
.transaction_list_inner_single
{
    position: relative;
    cursor: pointer;
    list-style: none;
    color: var(--text_light_white);
    border-radius: 1em;
    border-bottom: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    margin: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-min);
    padding: 3px 10px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.transaction_list_inner_single:hover 
{
    border-bottom: 2px solid var(--color-theme);
}
.transaction_list_inner_single li 
{
    flex: 1;
    text-align: left;
}
.transaction_list_inner_single .center_align_span 
{
    gap: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}