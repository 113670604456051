.employee_bundle_handler_wrapper 
{
    margin: 10px;
    width: 90%;
    border-radius: 2px; 
    background: var(--background-dark_minus);
    border: 2px solid var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.emp_list_wrapper {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    border-bottom-right-radius: 20px; 
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
    overflow-x: hidden;
}
.employee_master_list_header 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    border: 2px solid var(--background-dark);
    padding: 3px 15px;
}
.header_info h3 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    font-weight: 300;
    text-transform: uppercase;
}
.data_filter_action_wrapper 
{
    padding: 6px 10px;
    background: var(--background-light);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 3px;
}
.data_employee_input_field_wapper input 
{
    color: var(--primary_text_color);
    border: 1px solid var(--background-dark_plus);
    padding: 4px 8px;
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 2em;
    width: 15em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.action_button_wrapper button 
{
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark_plus);
    color: var(--text_light_white);
    padding: 3px 6px;
    border-radius: 2em;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.action_button_wrapper button:hover 
{
    background: var(--text_light_white);
    border-color: var(--text_light_white);
    color: var(--background-dark_plus);
}