
.form_wrapper 
{
    padding: 10px;
}
.form_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.page_list 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.pages 
{
    border: 2px solid var(--background-dark);
    height: 15em;
    max-height: 15em;
    overflow-y: scroll;
    padding: 10px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.head_text 
{
    padding: 3px;
    text-align: center;
}
.filter_role_input 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1px 10px;
    width: 50%;
    border-radius: 10px;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    margin: 10px;
}
.filter_role_input input 
{
    outline: none;
    padding: 5px 10px;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
}
.filter_role_icon 
{
    color: var(--color-theme);
    font-size: var(--default-font-scale-mid);
}
.head_text h2 
{
    color: var(--text_light_white);
    font-size: 1.2em;
}
.page_access_tick 
{
    margin: 3px;
    padding: 5px;
    border-radius: 0.5em;
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.clickable_route_group_wrapper 
{
    padding: 2px 5px;
    margin: 5px;
    border: 2px solid var(--background-dark);
    border-radius: 3px;
    background: var(--background-dark_minus);
    width: 10em;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.clickable_route_group_wrapper:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    scale: 1.02;
}
.clickable_route_group_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    padding: 5px;
}

.router_inner_items 
{
    padding: 5px;
    max-height: 5em;
    height: 5em;
    overflow: scroll;
}
.router_inner_items li 
{
    font-size: var(--default-font-scale-min);
    list-style: none;
    font-weight: bold;
    color: var(--text_light_white);
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    border-radius: 0.5em;
    padding: 3px 10px;
    margin: 2px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.input_wrap_check_box 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid var(--background-dark);;
    background: var(--background-dark);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.input_wrap_check_box span 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    margin: 3px 8px;
}


.open_special_access_menu_wrapper 
{
    padding: 5px 10px;
}
.part_of_option 
{
    width: 85%;
    margin: 10px 2px;
}
.part_of_option ul
{
    padding: 2px 10px;
    background: var(--background-light);
    border: 1px solid var(--background-light);
    border-radius: 1px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.setting_items_config  
{
    margin-left: 3em;
    width: 100%;
}
.setting_items_config input[type="number"]::-webkit-outer-spin-button,
.setting_items_config input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* .setting_items_config input[type="number"] {
    -moz-appearance: textfield;
} */
.setting_items_config li
{
    list-style: none;
    color: var(--text_light_white);
    padding: 2px 10px;
    background: var(--background-dark);
    border: 1px solid var(--background-light);

    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 2px;
    margin: 3px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.setting_items_config li:hover 
{
    background: var(--background-dark_minus);
}
.setting_items_config .input_value
 {
    text-align: center;
    width: 4em;
    padding: 2px 4px;
    color: var(--text_light_white);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }
 .setting_items_config select 
 {
    text-align: center;
    padding: 2px 8px;
    color: var(--text_light_white);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }