.input_list_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    margin: 5px;
    border-bottom: 1px solid var(--background-light);
    animation: slid_in 0.2s ease-in-out;
 }
  @keyframes slid_in {
   0% {
      margin-left: -150%;
      opacity: 0;
   }
   100% {
      margin-left: 0;
      opacity: 1;
   }
 }
 .input_list_wrapper input
 {
   font-size: 10px;
 }
 .input_fold_wrapper 
 {
   width: 33%;
 }