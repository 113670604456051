.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    padding: 10px;
    height: 18em;
}
.master_product_type_inner_config_wrapper li
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    width: 90%;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    padding: 3px 10px;

    display: flex;
    justify-content: space-between;
    align-items: center; 
    align-content: center;
    flex-wrap: wrap;
    margin: 3px;
    cursor: pointer;
}
.request_info_upper, .master_product_type_inner_config_wrapper
{
    padding: 5px 10px;
    width: 45%;
}
.request_info_upper 
{
    background: var(--background-dark);
    height: 10em;
    overflow-y: scroll;
    border: 2px solid var(--background-light);
    border-radius: 5px;
}
.master_product_type_inner_config_wrapper
{
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 15em;
    overflow-y: scroll;
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
}
.request_info_upper li 
{
    padding: 3px 10px;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_plus);
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 5px;
}

