.pto_main_wrapper 
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--alpha-background);
    height: 100vh;
    z-index: 2;
}
.pto_form_wrapper 
{
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,  var(--background-light) 0px 30px 60px -30px,  var(--background-light) 0px -2px 6px 0px inset;
    animation: smooth_in 0.3s;
}
@keyframes smooth_in {
    0% {
        scale: 0;
        width: 0;
        height: 0;
    }
    100% {
        scale: 1;
    }
}
.pto_header 
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid var(--background-light);
    background: var(--background-light);
    padding: 5px;
}
.pto_header h1 
{
    font-size: 20px;
    color: var(--text_light_white);
}
.pto_header button 
{
    background: none;
    border: none;
    font-size: 17px;
    color: var(--background-dark_plus);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.pto_header button:hover 
{
    color: var(--text_light_white);
}
.pto_input_main 
{
    display: flex;
    margin: 5px;
    width: 400px;
    padding: 3px;
    background: var(--background-dark);
    border: 1px solid var(--background-dark);
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.pto_input_main_icon 
{
    font-size: 13px;
    border: 1px solid var(--background-light);
    background: var(--background-light);
    color: var(--primary_text_color);
    padding: 8px;
    transition: 0.3s ease-in-out;
}
.pto_input_main:hover 
{
    border: 1px solid var(--color-theme);
}
.pto_input_main:hover .pto_input_main_icon 
{
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    color: var(--primary_text_color);
}
.pto_action_body input 
{
    width: 100%;
    color: var(--primary_text_color);
    padding: 8px;
    background: none;
    border: none;
}
.pto_action_body 
{
    display: flex;
    justify-content: center;
    padding: 20px;
}
.pto_input_submit_action_button 
{
    text-align: center;
    padding: 10px;
}
.pto_input_submit_action_button button 
{
    width: 150px;
    padding: 5px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    color: var(--primary_text_color);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 3px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.pto_input_submit_action_button button:hover 
{
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
}
.input_extra_layer li
{
    padding-left: 20px;
    list-style: none;
    color: var(--background-light);
}
.input_extra_layer_caution li 
{
    padding-left: 20px;
    list-style: none;
    color: var(--background-light);
}
.input_extra_layer_caution 
{
    display: flex;
    justify-content: start;
}
.input_extra_layer_caution input 
{
    padding: 2px;
    height: 15px;
    width: 15px;
    margin-left: 10px;
}
.tool_quick 
{
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}