.input_list_wrapper 
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
}
.input_adder 
{
    padding: 10px;
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
}
.input_adder button 
{
    user-select: none;
    padding: 5px 15px;
    margin-right: 10px;
    border: 2px solid var(--text_light_white);
    background: none;
    border-radius: 20px;
    color: var(--text_light_white);
    transition: 0.3s ease-in-out;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.input_adder button:hover 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
}
.form_action_button_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.input_fild_shild 
 {
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .remove_button_wrapper
 {
   text-align: center;
   margin: 5px;
   padding: 3px;
   background: var(--red-color);
   border: 2px solid var(--red-color);
   color: var(--primary_text_color);
   border-radius: 5px;
   cursor: pointer;
   transition: 0.3s ease-in-out;
 }
 .remove_button_wrapper:hover 
 {
   font-weight: bold;
   scale: 1.1;
   background: none;
   color: var(--red-color);
 }
 .remove_button_wrapper_fake 
 {
    margin: 10px;
   padding: 6px;
 }
 .po_mat_list_wrapper 
 {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .po_mat_list 
 {
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
    width: 80%;
    height: 200px;
    max-height: 200px;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
 }
 .qr_code_saver 
 {
    display: flex;
    justify-content: center;
     align-items: center;
    border: 2px solid var(--color-yellow);
    background: var(--color-yellow);
    background: var();
    padding: 0px 5px;
    height: 20px;
    font-size: 12px;
    border-radius: 3px;
    color: var(--background-dark_plus);
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .qr_code_saver:hover 
 {
    background: none;
    color: var(--color-yellow);
    border: 2px solid var(--color-yellow);
 }

 .qr_code_wrapper 
 {
    border: 1px solid var(--primary_text_color);
    padding: 10px;
    background: var(--primary_text_color);
 }