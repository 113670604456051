.color_full_salary_indactor 
{
    color: var(--color-theme);
}
.legal_not_about_acc_data p 
{
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    text-align: center;
}
.legal_not_about_acc_data 
{
    margin: 5px;
}