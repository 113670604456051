.dashboard_main_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
    overflow: scroll;
    
}
.dashboard_main_wrapper  h1 
{
    color: var(--background-light);
    text-transform: uppercase;
    font-size: 2em;
}