.paper_hash 
{
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(-45deg);
    font-size: 50px;
    font-weight: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.35;
    white-space: nowrap;
    text-transform: uppercase;
}
@media print {
    .print_only {
      display: block;
    }
  }
@media screen {
    .print_only {
        display: none;
    }
}