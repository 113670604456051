.data_view_table_proxy_main_wrapper 
{
    position: relative;
    width: 90%;
    padding: 2px;
    margin: 10px;
    overflow: hidden;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
/* .data_view_table_proxy_main_wrapper::before {
    background: conic-gradient(transparent 120deg, var(--color-theme) 200deg);
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: border-spin 7s linear infinite;
    scale: 10;
} */
@keyframes border-spin {
    100% {
        transform: rotate(360deg);
    }
}
.data_view_table_proxy_main_wrapper_animation 
{
    position: relative;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    width: 100%;
    border-top: 2px solid var(--color-theme);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    overflow: hidden;
}



.data_view_table_proxy_header_wrapper 
{
    padding: 5px;
    margin: 1px 15px;
    border-bottom: 2px solid var(--background-dark);
}
.data_view_table_proxy_header_content 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 20px 0px 0px 0px;
}
.data_view_table_proxy_header_content h2 
{
    color: var(--color-theme);
    font-size: 1.2em;
    font-weight: 300;
}
.data_view_table_proxy_body_wrapper 
{
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
    padding: 10px;
    background: var(--background-dark_minus);
}
.data_view_table_proxy_body_wrapper li
{
    list-style: none;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    margin: 5px;
    padding: 5px 10px;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 2px;

    cursor: pointer;
    transition: 0.3s ease-in-out;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 1;
}
.data_view_table_proxy_body_wrapper li:hover 
{
    background: var(--background-dark_minus);  
    border-bottom: 2px solid var(--text_light_white);
}
.data_view_table_proxy_body_wrapper li span 
{
    flex: 1;
    font-weight: bold;
}
.data_view_table_proxy_body_wrapper li span button 
{
    font-size: var(--default-font-scale-lrg);
    padding: 2px 5px;
    border-radius: 10px;
    border: 1px solid var(--text_light_white);
    color: var(--background-dark_plus);
    background: var(--text_light_white);
    font-weight: bold;
    margin: 2px 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.data_view_table_proxy_body_wrapper li span button span
{
    margin: 3px;
}
.data_view_table_proxy_body_wrapper li span button:hover 
{
    border-radius: 1px;
}

.data_view_table_proxy_footer_wrapper 
{
    border-top: 2px solid var(--background-dark);
    padding: 10px 15px;
    margin: 10px;
}

.data_view_table_proxy_footer_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.action_button_bar button
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-min);

    border: 1px solid var(--background-dark);
    background: var(--background-dark_plus);
    color: var(--text_light_white);
    border-radius: 10px;
    padding: 1px 10px;

    cursor: pointer;
    user-select: none;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.action_button_bar button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 1px;
}
.action_button_bar button span 
{
    margin: 3px;
}
.filter_input_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 3px 10px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.filter_input_wrapper input 
{
    padding: 3px 10px;
    font-size: var(--default-font-scale-min);
    color: var(--color-theme);
    font-weight: bold;
}
.filter_input_wrapper span 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
}