.data_body 
{
    padding: 20px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
}
.data_preview_wrapper 
{
    margin: 10px;
}
.inner_preview_data_wrapper 
{
    display: flex;
    justify-content: center;

    height: 20em;
    max-height: 20em;
    overflow: scroll;
}
.paper_wrapper_dash 
{
    width: 90%;
    background: var(--primary_text_color);

    height: 20em;
    overflow: scroll;
}
.meta_data_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    padding: 5px 20px;
    text-align: center;
    color: var(--print_paper_background);
}
.meta_data_wrapper h2
{
    font-size: 0.8em;
    margin: 5px;
    text-transform: uppercase;
}
.transcation_owner_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.transcation_owner 
{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    background: var(--print_paper_background);
    border: 2px solid var(--dimm_white);
    margin: 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.transcation_init li, .transcation_recive li
{
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--background-dark_plus);
    margin: 3px;
    font-weight: bold;
    text-align: center;
}
.transcation_init h2, .transcation_recive h2
{
    text-align: center;
    font-size: 0.9em;
    margin: 4px;
    color: var(--background-dark_plus);
    text-decoration: underline;
}
/* .paper_list_wrapper 
{
    width: 100%;
    padding: 5px 20px;
}
.paper_list_wrapper li 
{
    list-style: none;
    border-bottom: 1px solid var(--background-light);

    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
    font-size: var(--default-font-scale-min);
}
.paper_list_wrapper li span, .fake_tabke_header span
{
    width: 16.6%;
    text-align: center;
} */
.fake_tabke_header
{
    
    font-size: var(--default-font-scale-mid) !important;
    font-weight: bold !important;
}
.entry_deatil_val 
{
    font-weight: bold;
}

.form_action_button_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: right;
    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.form_action_button_wrapper button 
{
    padding: 5px 10px;
    margin: 5px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.form_action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.form_action_button_approve 
{
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
}
.form_action_button_req_dismiss 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}
.form_action_button_print 
{
    color: var(--primary_text_color);
    background: var(--dimm_white);
    border: 1px solid var(--dimm_white);
}
.form_action_button_dismiss 
{
    color: var(--primary_text_color);
    background: var(--red-color);
    border: 1px solid var(--red-color);
}
@media print {
    .paper_wrapper_dash 
    {
        width: 100%;
        height: 100%;
    }

  }
.action_wrapper_text_good 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bold;

    color: var(--background-dark_plus);
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    padding: 2px 5px;
}
.action_wrapper_text_bad 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    font-weight: bold;
    
    color: var(--background-dark_plus);
    border: 1px solid var(--red-color);
    background: var(--red-color);
    padding: 2px 5px;
}
.check_valid 
{
    color: var(--background-dark_plus);
    font-size: 1.3em;
}
.check_invalid 
{
    color: var(--background-dark_plus);
    font-size: 1.3em;
}



/* BREAK */
.action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    padding: 15px;

    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.action_button_wrapper button 
{
    font-weight: bold;
    color: var(--background-dark_plus);
    font-size: 0.8em;
    width: 8em;
    padding: 3px;
    cursor: pointer;
    border-top-left-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.button_approve 
{
    background: var(--main-color);
    border: 1px solid var(--main-color);
}
.button_halt 
{
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}
.data_list_paid 
{
    color: var(--red-color);
    font-weight: bold;
}
.button_print 
{
    background: var(--inventory-type-color);
    border: 1px solid var(--inventory-type-color);
}
.button_dismiss 
{
    background: var(--red-color);
    border: 1px solid var(--red-color);
}
.main_paper_data_wrapper 
{
    margin: 10px;
}
.group_id_wrapper 
{
    background: var(--print_paper_background_dark_plus);
    padding: 5px;
    margin-top: 5px;
}
.group_id_wrapper li {
    list-style: none;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--background-dark_plus);
}
.inner_sub_group_cash_sales_data_wrapper
 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
 }
.data_marging_wrapper
 {
    width: 95%;
    padding-top: 10px;
    background: var(--print_paper_background);
    border: 2px solid var(--print_paper_background_dark_plus);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
.data_marging_wrapper li 
{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 2px 10px;
    border-bottom: 2px solid var(--print_paper_background_dark_plus);
    font-size: var(--default-font-scale-min);
    flex: 1;
}
.data_marging_wrapper_summery 
{
    padding: 10px;
    width: 90%;
    background: var(--print_paper_background);
    border: 2px solid var(--print_paper_background_dark_plus);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.data_marging_wrapper_summery li
{
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    padding: 10px;
    border-bottom: 2px solid var(--print_paper_background_dark_plus);
    font-size: var(--default-font-scale-min);
    flex: 1;
}
.summery_tag_sp_wrap 
{
    width: 15em;
    padding: 1px;
}
.data_header_list 
{
    font-weight: bold;
    padding: 2px 0px !important;
}
.data_marging_wrapper li span 
{
    flex: 1;
    text-align: center;
}
.total_local_summery
 {
    margin-top: 3px;
    font-weight: bold !important;
    background: var(--print_paper_background_dark_plus);
 }
.text_left 
{
    text-align: left !important;
    text-transform: capitalize !important;
}

.info_tag 
{
    width: 10em;
}
.info_tag_value 
{
    font-weight: bold;
    text-decoration: underline;
}
.data_marging_wrapper_special
{
    padding-top: 10px;
    margin: 10px;
    background: var(--print_paper_background);
    border: 2px solid var(--print_paper_background_dark_plus);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.paper_body_content 
{
    margin: 10px;
}