.data_main 
{
    width: 50%;
}
.input_list_wrapper 
{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    
}
.input_wrap
 {
   width: 80%;
 }
 .quick_check_wrapper 
 {
   width: 97%;
   padding: 5px 10px;
 }
 .input_wrap_check 
 {
   font-size: var(--default-font-scale-min);
   font-weight: bold;
   color: var(--text_light_white);
   padding: 5px 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   background: var(--background-dark);
   border: 1px solid var(--background-light);
   cursor: pointer;
   box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .input_wrap_check input 
 {
   width: 15px;
   height: 15px;
 }