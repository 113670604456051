.right_msg_panal_wrapper 
{
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 25em;

    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    border-radius: 5px;
    z-index: 5;

    visibility: visible;
    opacity: 1;

    transition: 0.3s ease-in-out;
}
.right_msg_panal_wrapper_hide 
{
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 0em;

    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    border-radius: 5px;
    z-index: 5;

    visibility: hidden;
    opacity: 1;

    transition: 0.3s ease-in-out;
}
.right_msg_header 
{
    background: var(--background-light);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 1px 10px;
}
.right_msg_header h3 
{
    color: var(--text_light_white);
    font-size: 0.8em;
}
.right_msg_header button 
{
    background: none;
    border: none;
    color: var(--red-color);
    cursor: pointer;
    font-size: var(--default-font-scale-lrg);
    padding: 2px;
} 
.empty_msg_bucket 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.empty_msg_bucket li 
{
    list-style: none;
    color: var(--text_light_white);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8em;
}
