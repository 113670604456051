.warehouse_master_wrapper 
{
    max-height: 100vh;
    height: 100vh;
    overflow: scroll;
}
.stock_warehouse_body_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

}