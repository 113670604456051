.job_and_pay_master_wrapper
{
    height: 18em;
}
.job_and_pay_main_wrapper
 {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
 }
.job_and_pay_inner_wrapper 
{
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    border-radius: 2px;
    padding: 10px;
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.job_and_pay_inner_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    padding: 3px;
    margin: 3px;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    border: 1px solid var(--background-light);
    background: var(--background-dark);

}
.job_and_pay_inner_wrapper li .value_value_emp_date 
{
    font-weight: bold;
    text-transform: capitalize;
}
.job_and_pay_inner_wrapper li .value_tag 
{
    width: 13em;
}
 .tool_quick 
{
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.employee_termination_resign_transfer_action_wrapper_master 
{
    position: relative;
    top: 7em;
    left: 0;
    border-top: 1px solid var(--background-light);
    border-radius: 2px;
    padding: 10px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    margin: 5px;
}
.employee_termination_resign_transfer_action_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: center;
    gap: 2px;
}
.transfer_employee_branch_list 
{
    position: absolute;
    top: 2em;
    width: 10em;
    border-radius: 5px;
    border: 1px solid var(--background-light);
    padding: 3px 6px;
    background: var(--background-dark_plus);

    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
    gap: 2px;
}