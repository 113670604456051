.main_product_shelf_card_wrapper 
{
    width: 15em;
    cursor: pointer;
    background: var(--background-dark_plus);
    border: 2px solid var(--background-dark);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.2s ease-in-out;
    margin: 5px 10px;
}
.prod_other_info_wrpper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    transition: 0.2s ease-in-out;
}
.main_product_shelf_card_wrapper:hover .icon_wrapper span
{
    color: var(--color-theme);
}
.main_product_shelf_card_wrapper:hover 
{
    border: 2px solid var(--background-dark);
    border-top: 2px solid var(--color-theme);
}
.icon_wrapper 
{
    background: var(--background-dark_plus);
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.icon_wrapper span 
{
    color: var(--text_light_white);
    font-size: 2.0em;
    transition: 0.1s ease-in-out;
}

.action_data_wrapper 
{
    width: 80%;
    background: var(--background-dark_plus);
    padding: 5px 10px;
    border-left: 2px solid var(--background-dark_minus);
}
.prod_saved_header 
{
    padding: 3px;
    background: var(--background-dark_minus);
}
.header_text_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.header_text_wrapper h2
{
    font-size: 0.9em;
    color: var(--text_light_white);
}
.data_owner_lab 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.data_owner_lab li 
{
    border-left: 2px solid var(--color-theme);
    border-right: 2px solid var(--color-theme);
    width: 60%;
    padding: 2px;
    list-style: none;
    text-wrap: nowrap;
    text-align: center;
    background: var(--background-dark_plus);

    font-size: 0.5em;
    color: var(--color-theme);
    font-weight: bold;
}
.action_data_wrapper li 
{
    color: var(--text_light_white);
    font-weight: bold;
    list-style: none;
    font-size: var(--default-font-scale-min);
    margin: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.val_no 
{
    color: var(--red-color) !important;
}
.val_yes 
{
    color: var(--color-theme) !important;
}