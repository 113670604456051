.doc_card_valid
{
    margin: 5px;
    background: var(--background-dark_minus);
    border: 2px solid var(--background-light);
    border-radius: 5px;
    padding: 10px;
    width: 10em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.doc_card_valid h3 
{
    padding: 5px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
    text-align: center;
}
.card_icon_wrapper
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px;
}
.card_icon_signed
{
    margin: 3px;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    width: 4em;
    height: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
 .card_icon_wrapper span 
 {
    font-size: 2em;
    color: var(--text_light_white);
 }
 .card_doc_info
 {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
 }
 .avial_doc 
 {
    color: var(--color-theme) !important;
 }
 .unavail_doc
 {
    color: var(--red-color) !important;
 }