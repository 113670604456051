.budget_allocation_history_local_view_master_wrapper 
{
    width: 60%;
    margin: 5px;
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 10px;
    background: var(--background-dark_minus);
}
.budget_allocation_history_local_view_header 
{
    background: var(--background-light);
    border-top: 2px solid var(--primary_text_color);
    padding: 6px 8px;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.budget_allocation_history_local_view_header h2
{
    font-size: var(--default-font-scale-mid);
    color: var(--primary_text_color);
    letter-spacing: 2px;
}
.budget_allocation_history_local_view_body_master_wrapper
 {
    padding: 5px 10px;
    height: 12em;
    overflow-y: scroll;
 }
 .budget_allocation_history_local_view_body_master_wrapper li 
 {
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bold;

    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    padding: 2px 4px;
    margin: 3px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .budget_allocation_history_local_view_body_master_wrapper li:hover 
 {
    background: var(--background-dark);
    border-bottom: 2px solid var(--text_light_white);
 }
 .budget_allocation_history_local_view_body_master_wrapper li span 

 {
    text-align: left;
    flex: 1;
 }
 .center_align_span 
 {
    display: flex;
    justify-content: center;
 }
 .center_align_span_disable 
 {
    display: flex;
    justify-content: center;
    color: var(--background-light);
    text-decoration: line-through;
    transition: 0.3s ease-in-out;
 }
 .budget_allocation_history_local_view_body_master_wrapper li:hover .center_align_span_disable 
 {
   color: var(--text_light_white);
   text-decoration: none;
 }