.mini_req_visual_modal_master_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    margin: 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.modeal_requst_info_wrapper
{
    padding: 4px 8px;
    background: var(--background-dark_plus);
    border-bottom: 2px solid var(--background-light);
}
.modeal_requst_info_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
}
.modeal_requst_info_wrapper li span 
{
    font-weight: bold;
}
.modal_action_button_req 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px 10px;

    background: var(--background-dark);
}
.datalist_master_wrapper_hint 
{
    padding: 10px;
    height: 5em;
    overflow: scroll;
}
.datalist_master_wrapper_hint li
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    padding: 2px 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    border: 2px solid var(--background-light);
    margin: 2px;
}