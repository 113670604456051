.budget_allocation_history_local_view_master_wrapper 
{
    width: 90%;
    margin: 5px;
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 10px;
    background: var(--background-dark_minus);
}
.budget_allocation_history_local_view_header 
{
    background: var(--background-light);
    border-top: 2px solid var(--primary_text_color);
    padding: 4px 8px;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.budget_allocation_history_local_view_header h2
{
    font-size: var(--default-font-scale-mid);
    color: var(--primary_text_color);
    letter-spacing: 2px;
}
.budget_allocation_history_local_view_body_master_wrapper
 {
    padding: 5px 10px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
 }
 .single_transaction_preview 
 {
    position: relative;
    padding: 2px 4px;
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    margin: 3px;
    border-left: 3px solid var(--color-theme);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
 .single_transaction_preview:hover 
 {
    background: var(--background-light);
 }
.single_transaction_preview li 
{
    list-style: none;
    color: var(--text_light_white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    padding: 3px 6px;   
}
.single_transaction_preview li span 
{
    flex: 1;
    text-align: left;
}
.cash_view_value 
{
    color: var(--red-color);
    font-weight: bold;
    text-decoration: underline;
    display: flex;
    justify-content: center;
}
.transaction_detail_inner 
{
    visibility: visible;
    padding: 4px 8px;
    background: var(--background-dark);
    border: 1px solid var(--background-light);
    transition: 0.4s ease-in-out;
    height: 6em;
    max-height: 6em;
    overflow-y: scroll;
}
.transaction_detail_hide 
{
    visibility: collapse;
    height: 0;
    transition: 0.4s ease-in-out;
}
.transaction_detail_inner li, .transaction_detail_hide li
{
    border-left: 3px solid var(--color-theme);
    background: var(--background-dark_plus);
    margin: 3px;
    font-weight: lighter;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 20px;
}
.transaction_detail_inner li span 
{
    font-weight: bold;
}
