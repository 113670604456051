.pitty_cash_finance_level_master_wrapper 
{
    background: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
}

.charts_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.char_head_name_Wrapper 
{
    padding: 4px;
    text-align: center;
    border-bottom: 2px solid var(--background-light);
    background: var(--background-light);
}
.char_head_name_Wrapper h1 
{
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
}

.single_chart_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 5px;
    width: 95%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.char_body_wrapper 
{
    padding: 10px;
}

.branch_card_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 10px;
}