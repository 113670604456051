.main_product_shelf_card_wrapper 
{
    background: var(--background-dark);
    border: 2px solid var(--background-light);
    min-width: 15em;
    min-height: 4em;
    
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.main_product_shelf_card_wrapper:hover 
{
    border: 2px solid var(--text_light_white);
}
.ing_amount_c 
{
    font-weight: bold;
}
.prod_name_info_wrapper   
{
    padding: 5px 10px;
    background: var(--background-dark);
    padding: 3px 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.prod_name_info_wrapper h2 
{
    width: 100%;
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-lrg);
    font-weight: 400;
}
.prod_name_info_wrapper li 
{
    list-style: none;
    color: var(--color-theme);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    text-transform: uppercase;
}