.emp_graph_used 
{
    z-index: 1;
    position: relative;
    border-radius: 50%;
    /* background: conic-gradient(var(--grren-color) 0 70%, var(--background-dark) 0 0); */
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 1.5s ease-in-out;

    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.emp_graph_used_puls 
{
    animation: puls_green 2s infinite;
}

@keyframes puls_green {
    0% {
        transition: all 0.75s ease-in-out;
        transform-origin: center;
        transform: scale(1);
        opacity: 0.2;
    }
    100% {
        transition: all 0.75s ease-in-out;
        transform-origin: center;
        transform: scale(1.3);
        opacity: 0;
    }
}

.emp_graph_used_val 
{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in-out;
}
.emp_graph_used_val  h1 
{
    z-index: 1;
    font-size: 17px;
    font-weight: bold;
}
.emp_val_count_value_count 
{
    font-size: 0.8em;
}
.emp_val_count_value_perc 
{
    font-size: var(--default-font-scale-mid);
}
.emp_val_count_value 
{
    font-size: 12px;
}