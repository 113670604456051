.data_table_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
 .cash_view_value 
 {
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    color: var(--background-dark_plus);
    font-weight: bolder;
    padding: 3px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }
 .gram_view_amount 
 {
    color: var(--color-theme);
    text-decoration: underline;
 }