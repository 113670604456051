.final_product_choice_input_package_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    animation: slideInFromLeft 0.4s ease-out;
}
@keyframes slideInFromLeft {
    from {
        opacity: 0;
        filter: blur(10px);
    }
    to {
        opacity: 1;
        filter: blur(0px);
    }
}
.final_product_input_package_input_wrapper_single 
{
    flex: 1;
}
.possibel_production_volume 
{
    color: var(--color-theme);
}
.lack_production_volume 
{
    color: var(--red-color);
}