.vender_main_wrapper
 {
    background: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
 }
 .vender_header 
 {
    padding: 10px;
    text-align: center;
    background: var(--background-dark);
    border: 2px solid var(--background-light);
 }
 .vender_header h1 
 {
    color: var(--text_light_white);
    text-transform: uppercase;
    font-size: var(--default-font-scale-lrg);
 }
 .vender_main_inner_wrapper 
 {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 500px;
    border-bottom: 2px solid var(--background-light);
    overflow: scroll;
 }
 .vender_main_inner_list_wrapper 
 {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
 }
 .action_button 
 {
    padding: 10px;
    text-align: right;
 }
  .action_button button 
  {
    width: 30px;
    height: 30px;
    background: none;
    border: 2px solid var(--primary_text_color);
    color: var(--primary_text_color);
    font-size: 15px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    border-radius: 50%;
    background: var();
}
.action_button button:hover 
{
   border-radius: 20%;
   background: var(--primary_text_color);
   color: var(--background-dark_plus);
}