.input_list_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    animation: slid_in 0.2s ease-in-out;
 }
  @keyframes slid_in {
   0% {
      margin-left: -150%;
      opacity: 0;
   }
   100% {
      margin-left: 0;
      opacity: 1;
   }
 }
 .input_list_wrapper input
 {
   font-size: 10px;
 }
 .input_fold_wrapper 
 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
 }
 .single_input_wrapper_stock_req 
 {
    width: 100%;
 }