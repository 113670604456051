/* li */
.single_entry_li_dash 
{
    position: relative;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);

    border-radius: 2em;
    padding: 3px 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    animation: smoth_in 0.3s ease-in-out;
}
@keyframes smoth_in {
    0% {
        opacity: 0.0;
        filter: blur(5px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}
.single_entry_li_dash:hover 
{
    background: var(--background-dark_plus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.single_entry_li_dash span 
{
    flex: 1;
    text-align: center;
}
.action_button_wrapper 
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

 .order_state_button_wrap 
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
 }

