.form_wrapper 
{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
  flex-wrap: wrap;
}
.input_list_wrapper
 {
   width: 45%;
 }

 .single_input 
 {
   width: 90%;
 }
 .prod_preview_wrpper 
 {
   width: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   flex-wrap: wrap;

   background: var(--background-dark_minus);
   border-radius: 10px;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
 .prod_image_input_wrapper 
 {
   width: 10em;
   height: 8em;
   border: 2px solid var(--background-dark);
   background: var(--color-theme);
   border-radius: 10px;
   cursor: pointer;

    transform:
    perspective(200px)
    rotateX(10deg);

   /* box-shadow: var(--color-theme) 0px 10px 20px, var(--color-theme) 0px 6px 6px; */
   box-shadow: rgba(0, 0, 0, 0.85) 0px 35px 25px -25px;
   transition: 0.3s ease-in-out;
 }
 .prod_image_input_wrapper:hover 
 {
    box-shadow: var(--color-theme)0px 3px 6px, var(--color-theme) 0px 3px 6px;
    transform:  rotateX(0deg); 
    scale: 1.08;
 }
 .prod_image_input_wrapper img
 {
    width: 10em;
    height: 8em;
   border-radius: 10px;
 }
 .click_upload_helper 
 {
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   flex-wrap: wrap;

   height: 100%;
   width: 90%;
 }
 .click_upload_helper li 
 {
   width: 100%;
   list-style: none;
   color: var(--background-dark_plus);
   text-align: center;
   font-size: 0.9em;
   font-weight: bold;
   text-transform: uppercase;
 }
 .remove_image 
 {
  margin: 5px;
  width: 100%;
  padding: 10px;

  text-align: right;
 }
 .remove_image button 
 {
  padding: 5px 10px;
  font-size: var(--default-font-scale-min);
  background: var(--red-color);
  border: 1px solid var(--red-color);
  color: var(--primary_text_color);
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
 }
 .remove_image button:hover 
 {
    border-radius: 1px;
 }