.stock_view_main_wrapper
{
    position: absolute;
    background: var(--alpha-background);
    width: 100%;
    height: 106vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 99;
}
.stock_data_wrapper_frame
 {
    width: 80%;
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 2px;

    max-height: 70%;
    overflow-y: scroll;
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;

    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
 }
 .stock_detail_body
  {
    max-height: 500px;
    overflow-y: scroll;
  }
  .asset_loading_placeholder 
  {
   height: 600px;
   max-height: 600px;
   overflow: scroll;

   display: flex;
   justify-content: center;
   align-items: center;
  }
 .stock_data_wrapper_header 
 {
    background: var(--background-light);
    border: 1px solid var(--background-light);

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 5px;
 }
 .stock_data_wrapper_header li 
 {
    list-style: none;
    color: var(--primary_text_color);
    font-size: 18px;
    padding-left: 5px;
 }
 .stock_data_wrapper_header button 
 {
    border: none;
    background: none;
    font-size: 20px;
    color: var(--primary_text_color);
    transition: 0.3s ease-in-out;
    cursor: pointer;
 }
 .stock_data_wrapper_header button:hover 
 {
    color: var(--red-color);
 }
 .stock_db_detail 
 {
    display: flex;
    justify-content: space-between;
    padding: 20px;
 }
 .stock_chart_wrapper 
 {
    padding-top: 10px;
    border-top: 1px solid var(--background-dark);
 }
 .stock_db_detail_left
 {
   border-radius: 5px;
    width: 45%;
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .stock_db_detail_right_bot_detail_header 
 {
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   padding: 5px 10px;
   background: var(--background-dark);

   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
 }
 .right_side_data_wrapp 
 {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   align-content: center;
 }
 .stock_db_detail_right_bot_detail_header h2 
 {
   font-size: 0.9em;
   color: var(--text_light_white);
 }
 .stock_db_detail_right_bot_detail_body_wrapper 
 {
   padding: 10px;
   height: 20em;
   max-height: 20em;
   overflow-y: scroll;
 }
 .stock_db_detail_left li,  .stock_db_detail_right_bot_detail li 
 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;

    border-radius: 5px;
    list-style: none;
    color: var(--text_light_white);
    padding: 5px;
    font-size: var(--default-font-scale-min);
    text-transform: capitalize;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    margin: 5px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
 }
 .stock_db_detail_left li:hover,  .stock_db_detail_right_bot_detail li:hover 
 {
   background: var(--background-dark);;
 }
 .stock_db_detail_left li .stock_db_detail_val 
 {
    font-weight: bold;
    float: right;
 }
 .stock_db_detail_right 
 {
    width: 45%;
    padding: 20px;
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .stock_db_detail_right_bot_detail 
 {
    padding: 10px;
 }
.stock_db_detail_right_bot_detail_value 
{
    float: right;
    font-weight: bold;
}
.stock_circle_Chart_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stock_name 
{
    font-weight: bold;
}
.chart_head 
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart_head li 
{
    list-style: none;
    color: var(--text_light_white);
    background: var(--background-light);
    letter-spacing: 2px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    text-transform: capitalize;
    border: 1px solid var(--text_light_white);
    padding: 5px 10px;
    border-radius: 5px;
}
.stock_uom 
{
    padding: 1px 5px;
}
 /* Icon */
 .list_pointer
  {
    font-size: 13px;
    padding: 1px 5px;
  }

  /* Pending */
.pending_stock_out_wapper 
{
   padding: 10px;
   border: 2px solid var(--color-yellow);
   border-radius: 5px;
}
.pending_stock_out_wapper p 
{
   font-size: 14px;
   color: var(--text_light_white);
   font-weight: bold;
}
.pending_stock_out_amount 
{
   color: var(--background-dark_plus);
   border-radius: 5px;
   padding: 3px 10px;
   float: right;
   margin-top: -5px;
   background: var(--color-yellow);
   border: 2px solid var(--color-yellow);
}
.animate_action_stock 
{
   animation: flip 0.5s ease-in-out;
}
@keyframes flip {
   0% {
      transform: perspective(400px) rotateX(0);
    }
    100% {
      transform: perspective(400px) rotateX(360deg);
    }
}
.pending_stock_out_indicator 
{
   padding: 5px;
   color: var(--color-yellow);
   animation: blip 0.7s infinite;
}
@keyframes blip {
   50% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.more_button_wrapper 
{
   display: flex;
   justify-content: flex-end;
   align-content: center;
   align-items: center;
   padding: 10px;
}
.more_button_wrapper button 
{
   border: none;
   background: none;
   font-size: var(--default-font-scale-mid);
   color: var(--text_light_white);
   cursor: pointer;
   user-select: none;
   font-weight: bold;
   margin: 5px;
   text-decoration: underline;
   transition: 0.3s ease-in-out;
}
.more_button_wrapper button:hover 
{
   color: var(--primary_text_color);
}
