.data_table_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    padding: 5px 10px;
}
.cash_view_value 
{
   border: 1px solid var(--color-theme);
   background: var(--color-theme);
   color: var(--background-dark_plus);
   font-weight: bolder;
   padding: 3px 10px;
   border-radius: 10px;
   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.active_prod 
{
    background: var(--color-theme) !important;
    border: 2px solid var(--color-theme) !important;

    width: 10em;
}
.inactive_prod 
{
    background: var(--red-color) !important;
    border: 2px solid var(--red-color) !important;
    color: var(--primary_text_color) !important;
    width: 10em;
}