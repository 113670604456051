.order_group_main_wrapper 
{
    width: 35em;
    min-width: 33em;
    margin: 10px;
    

    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    flex: 1;
}
.group_body_wrapper 
{
    height: 20em;
    max-height: 20em;
    overflow: scroll;
}
.order_group_main_header_wrapper 
{
    position: relative;
    background: var(--background-light);
    border-bottom: 1px solid var(--text_light_white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 4px 8px;
}
.order_group_main_header_wrapper h2 
{
    color: var(--text_light_white);
    font-size: 0.8em;
}
.dispatch_group_footer 
{
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_minus);
    padding: 4px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.dispatch_group_action_button_wrapper button, .right_side_dispatch_group_button_wrapper button 
{
    padding: 4px 8px;
    background: var(--text_light_white);
    border: 1px solid var(--text_light_white);
    margin: 5px;
    color: var(--background-dark_plus);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
}
.dispatch_group_action_button_wrapper button:hover 
{
    background: var(--primary_text_color);
}


