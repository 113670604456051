.production_log_group 
{
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus); 
    width: 90%;
    min-width: 25em;
    margin: 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.production_log_group_header 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 2px solid var(--background-dark);
    background: var(--background-dark);
    border-radius: 2px;
    border-bottom: 3px solid var(--color-theme);
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.production_log_group_header button 
{
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    border: none;
    background: none;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
}
.production_log_group_header button:hover 
{
    color: var(--color-theme);
}
.production_log_group_header h2 
{
    font-size: 0.8em;
    color: var(--text_light_white);
}
.production_log_group_body
{
    height: 25em;
    max-height: 25em;
    overflow-y: scroll;
}
.production_log_group_data_list_wrapper 
{
    padding: 10px;
}
@keyframes scale-from-middle {
    0% {
        transform: scale(0) translateY(50px);
        filter: blur(10px);
        opacity: 0;
        visibility: hidden;
      }
      60% {
        transform: scale(1.1) translateY(0);
        opacity: 1;
        visibility: visible;
      }
      100% {
        filter: blur(0px);
        transform: scale(1) translateY(0);
        visibility: visible;
      }
  }
.production_log_group_data_list_wrapper  li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 5px 10px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    border-radius: 2px;
    margin: 3px 1px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    visibility: hidden;
    animation: scale-from-middle 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
.production_log_group_data_list_wrapper  li span 
{
    width: 25%;
}
.span_p_log_right_ 
{
    text-align: right;
}
.production_log_group_data_list_wrapper li:hover 
{
    background: var(--background-dark);
    border-bottom: 2px solid var(--color-theme);
}

.production_log_group_filter_wrapper 
{
    width: 8em;
    padding: 2px;

    background: var(--background-dark_plus);
    border: 2px solid var(--color-theme);
    border-radius: 5px;
    margin-left: -10%;

    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    transition: 0.3s ease-in-out;
    z-index: 2;
}
.production_log_group_filter_wrapper_hide 
{
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: 0.3s ease-in-out;
}
.production_log_group_filter_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    margin: 2px;
    padding: 2px 5px;

    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);

    cursor: pointer;
    user-select: none;

    transition: 0.3s ease-in-out;
}
.production_log_group_filter_wrapper li:hover 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
}
.grand_total_val 
{
    border: 1px solid var(--color-theme) !important;
    color: var(--color-theme) !important;
    margin: 10px 2px !important;
    border-radius: 10px !important;
}

.loader_gif_wrapper 
{
    position: relative;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.empty_log 
{
    width: 100%;
    height: 10em;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    flex-wrap: wrap;
}
.empty_log ul 
{
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    font-size: 1.5em;
    color: var(--background-dark);
}
.perfect_output 
{
    color: var(--color-theme);
    text-align: center;
}
.has_defect 
{
    color: var(--red-color);
    font-size: 1.1em;
    text-align: center;
    font-weight: bolder !important;
}
.has_defect_surpless 
{
    color: var(--color-yellow);
    text-align: center;
    font-weight: bolder;
}
.has_defect_final_cash 
{
    /* color: var(--red-color); */
    background: var(--red-color);
    color: var(--primary_text_color);
    padding: 2px 5px;
    border-radius: 2em;
}