.stock_card_main_wrapper
{
    width: 100%;
    height: 100vh;
    background: var(--background-dark_minus);
    overflow: scroll;
}
.tool_quick 
{
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
}
.production_log_group_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
}


 .production_log_header_right_action_wrapper input[type="date"] {
    color: var(--text_light_white); 
    border: 2px solid var(--text_light_white);
    background: var(--background-dark_plus); 
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
  }
.production_log_header_right_action_wrapper input[type="date"]:hover 
{
    /* background: var(--primary_text_color);
    color: var(--background-dark_plus); */
}
.production_log_header_right_action_wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1); 
    cursor: pointer;
}

.production_log_group_wrapper 
{
    width: 100%;
}
.day_shift_production_log_wrapper 
{
    width: 47%;
    min-width: 25em;
    margin: 5px;
}
.day_shift_production_log_wrapper_summary 
{
    width: 90%;
    min-width: 25em;
    margin: 5px;
}
.prodution_log_header_wrapper 
{
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
}
.prodution_log_header_wrapper_inner 
{
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus); 
    width: 80%;
    min-width: 25em;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.prodcution_log_head_inner h2
{
    font-size: 0.9em;
    color: var(--text_light_white);
}

.prodution_log_header_wrapper_inner
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.prodcution_log_head_inner_right, .production_log_header_right_action_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.production_log_header_right_action_wrapper 
{
    margin: 1px 5px;
}
.production_log_header_right_action_wrapper button 
{
    font-size: var(--default-font-scale-min);
    padding: 5px 10px;
    color: var(--text_light_white);
    border: 2px solid var(--text_light_white);
    border-radius: 5px;
    background: none;
    font-weight: bold;

    cursor: pointer;
    user-select: none;

    transition: 0.2s ease-in-out;
}
.production_log_header_right_action_wrapper_summerize span
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    margin: 5px;
}
.production_log_header_right_action_wrapper button:hover, .production_log_header_right_action_wrapper_summerize:hover
{
    color: var(--background-dark_plus);  
    border: 2px solid var(--text_light_white);
    background: var(--text_light_white);
}
.production_log_header_right_action_wrapper_summerize 
{
    border: 2px solid var(--text_light_white);
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    transition: 0.2s ease-in-out;
}
.production_log_header_right_action_wrapper_summerize:hover span 
{
    color: var(--background-dark_plus);
}
.side_ncoming_production_request_notify 
{
    z-index: 10;
    position: fixed;
    border: 2px solid var(--background-dark_minus);
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;

    width: 25em;
    margin: 5px;
    background: var(--background-dark_plus);
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

    animation: notice_me_client 1s ease-in-out infinite alternate;
}
@keyframes notice_me_client {
    0% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(0);
    }
}
.notification_main_message_wrapper_final_product_notify 
{
    margin: 5px;
    padding: 5px 10px;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.notification_main_message_action_take_wrapper_final_product_notify 
{
    text-align: center;
    margin: 5px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
}
.message_header_notification_main_message_wrapper_final_product_notify 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border-bottom: 2px solid var(--background-light);
}
.message_header_notification_main_message_wrapper_final_product_notify li
{
    list-style: none;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--text_light_white);
    padding: 3px;
}
.message_body_notification_main_message_wrapper_final_product_notify p 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    padding: 4px 8px;
}