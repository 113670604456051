.kitchen_var_bundle_outer 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.kitchen_var_bundle_wrapper 
{
    border: 2px solid var(--background-dark);
    border-radius: 2px;
    margin: 10px;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.kitchen_var_head_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 5px 10px;

    background: var(--background-dark_minus);
}
.kitchen_var_head_wrapper h1 
{
    font-size: 1.2em;
    color: var(--text_light_white);
}
.kitchen_var_head_wrapper button 
{
    background: none;
    border: none;
    font-size: 1.2em;
    transition: 0.3s ease-in-out;
    color: var(--primary_text_color);
    cursor: pointer;
}
.kitchen_var_head_wrapper button:hover 
{
    color: var(--red-color);
}
.kitchn_var_list_body
 {
    padding: 10px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
 }
 .kitchn_var_list_body li 
 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 5px;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 3px 10px;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }
 .kitchn_var_list_body li:hover 
 {
    border-bottom: 2px solid var(--text_light_white);
    background: var(--background-dark);
 }
 .action_button_wrapper button
 {
    font-weight: bold;
    font-size: var(--default-font-scale-lrg);
    margin: 3px 5px;
    cursor: pointer;
    padding: 2px 10px;
    user-select: none;
    border-radius: 10px;
    transition: 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }
 .action_button_wrapper button:hover 
 {
    border-radius: 0px;
 }
 .action_button_wrapper button span 
 {
    margin: 3px;
 }
 .purge_kv
  {
    background: var(--red-color);
    border: 1px solid var(--red-color);
    color: var(--primary_text_color);
  }
  .manage_kv 
  {
    background: var(--text_light_white);
    border: 1px solid var(--text_light_white);
    color: var(--background-dark_plus);
  }

 .kitchen_var_footer_wrapper 
 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 10px;

    background: var(--background-light);
 }
 .kitchen_var_footer_wrapper button 
 {
    background: var(--text_light_white);
    border: 1px solid var(--text_light_white);
    color: var(--background-dark_plus);
    font-size: var(--default-font-scale-min);
    padding: 2px 10px;
    font-weight: bold;
    border-radius: 10px;

    cursor: pointer;
    user-select: none;
    transition: 0.3s ease-in-out;
 }
 .kitchen_var_footer_wrapper button:hover 
 {
    background: var(--primary_text_color);
    border-color: var(--primary_text_color);
 }
 .kitchen_var_footer_wrapper button span 
 {
    margin: 1px 5px;
 }