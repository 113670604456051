.bar_loader_main_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: 100%;
}
.bar_loader_min_wrapper 
{
    border: 1px solid var(--background-light);
    height: 3px;
    width: 100%;
    background: var(--background-light);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.5s ease-in-out;
}
.bar_loader_bar_wrapper
 {
    border: 1px solid var(--primary_text_color);
    height: 1.5px;
    width: 50%;
    background: var(--primary_text_color);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
 }
 .bar_text 
 {
    margin: 1px 5px;
    text-wrap: nowrap;
 }