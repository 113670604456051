.__ui_button 
{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bolder;

    margin: 1px 3px;

    
    padding: 1px 6px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s ease-in-out;
    border-radius: 2px;
    text-transform: capitalize;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.__ui_icon 
{
    font-size: 0.9em;

}
.__ui_lable 
{
    text-wrap: nowrap;
    font-size: var(--default-font-scale-mid);
}
.__ui_button:hover 
{
    border-radius: 10px;
}
.__ui_button span 
{
    margin: 0px 2px;
    display: inline-flex;
}
.primary
{
    background: var(--color-theme);
    border: 2px solid var(--color-theme);
    color: var(--background-dark_plus);
}
.primary:hover 
{
    background: var(--background-dark_plus);
    color: var(--color-theme);
}
.secondary
{
    background: var(--text_light_white);
    border: 2px solid var(--text_light_white);
    color: var(--background-dark_plus);
}
.secondary:hover 
{
    color: var(--text_light_white);
    background: var(--background-dark_plus);
}
.danger
{
    background: var(--red-color);
    border: 2px solid var(--red-color);
    color: #f7f7f7;;
}
.danger:hover 
{
    color: var(--red-color);
    background: var(--background-dark_plus);
}
.disabled
{
    text-decoration: line-through;
    background: var(--background-light);
    border: 2px solid var(--background-light);
    color: #9f9fa3;
    cursor: not-allowed;
}
.disabled:hover 
{
    border-radius: 2px;
}
.tool_quick 
{
    z-index: 999;
    font-size: var(--default-font-scale-min) !important;
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}

.top_count 
{
    position: absolute;
    top: -1em;
    right: -0.6em;
    border-radius: 50%;
    background: var(--red-color);
    border: 2px solid var(--red-color);
    width: 1em;
    height: 1em;
    padding: 1px;


    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    animation: pulse 1.0s infinite, smooth_in 0.6s;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--red-color);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.top_count li
{
    color: var(--primary_text_color);
    font-weight: bold;
    font-size: var(--default-font-scale-mid);
    list-style: none;

}