.prodcution_stack_order_group_wrapper_main 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.production_stack_group_wrapper
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
 }
 .tool_quick 
 {
    font-size: var(--default-font-scale-min) !important;
    background: var(--text_light_white) !important;
    color: var(--background-dark_plus) !important;
 }
.pro_stck_group_pkg_wrapper 
{
    position: relative;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    width: 90%;
    margin: 10px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.prodution_stock_single_card_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    max-height: 15em;
    overflow-y: scroll;
}
.production_stack_group_footer 
{
    border: 1px solid var(--background-light);
    background: var(--background-light);
    padding: 5px 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.footer_wrap_inner button 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 0px 5px;
    border: 1px solid var(--background-light);
    color: var(--background-dark_plus);
    background: var(--text_light_white);
    padding: 5px 10px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    text-wrap: nowrap;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
}
.footer_wrap_inner button span 
{
    margin: 0px 3px;
}
.footer_wrap_inner button:hover 
{
    background: var(--primary_text_color);
}
.footer_wrap_inner 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.production_stack_group_footer 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.batch_process 
{
    background: var(--color-theme) !important;
    border: 1px solid var(--color-theme) !important;
    color: var(--background-dark_plus);
}
.fail_save_purge 
{
    background: var(--red-color) !important;
    border: 1px solid var(--red-color) !important;
    color: var(--primary_text_color) !important;
}
.order_info_header 
{
    border: 1px solid var(--background-dark);
    padding: 3px 5px;
    background: var(--background-dark);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.order_info_header li
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    padding: 3px;
}
.order_main_value_data 
{
    font-weight: bold;
}
.expired_stock_order li
{
    list-style: none;
    color: var(--red-color);
    font-size: 1.1em;
    transition: 0.3s ease-in-out;
    animation: blip 1s infinite alternate;
 }
 @keyframes blip {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
 }
 .group_block_interaction 
 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(20px); 
    -webkit-backdrop-filter: blur(20px); 
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
 }
 .block_show_message 
 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
 }
 .block_show_message h2 
 {
    color: var(--primary_text_color);
    font-weight: bold;
    font-size: var(--default-font-scale-lrg);
 }
 .block_show_message h6
 {
    color: var(--text_light_white);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
 }
 .block_show_message li 
 {
    list-style: none;
    color: var(--red-color);
    font-size: 1.5em;
 }