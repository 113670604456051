.master_product_group_card_main_wrapper 
{
    margin: 5px;
    width: 13em;
    min-width: 13em;
    border: 2px solid var(--background-dark);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    flex: 1;
}
.header_wrapper 
{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding: 5px;
    background: var(--background-dark_minus);
}
.header_wrapper  h1 
{
    font-size: 0.9em;
    color: var(--text_light_white);
}
.master_product_action_button 
{
    background: var(--background-dark);
    padding: 3px;
    display: flex;
    justify-content: right;
}
.master_product_avial_workspace_list_wrapper 
{
    padding: 5px;
}
.master_product_avial_workspace_list_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);

    border: 2px solid var(--background-light);
    padding: 3px 8px;
    margin: 5px;
    border-radius: 2em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.master_product_avial_workspace_list_wrapper li:hover 
{
    background: var(--background-dark);
    scale: 1.02;
}
.avail_state_icon_has 
{
    font-size: 1.3em;
    color: var(--color-theme);
}
.avail_state_icon_has_not 
{
    font-size: 1.3em;
    color: var(--red-color);
}