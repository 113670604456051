.input_list_wrapper 
{
    flex-direction: column;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.input_wrap 
{
    width: 70%;
}