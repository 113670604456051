.main_menu_tag_wrapper 
{
    background: var(--background-dark_plus);
    padding-right: 10px;
    margin: 1.5px 0px;
    transition: 0.3s ease-in-out;
}
.main_menu_tag_wrapper:hover 
{
    background: var(--default_gradient);
}
.active_main_menu 
{
    background: var(--default_gradient);
    transition: 0.3s ease-in-out;
}
.main_menu_tag_wrapper .main_menu 
{
    list-style: none;
    font-size: var(--default-font-scale-min);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bold;
    padding:  3px;

    text-decoration: none;
    color: var(--text_light_white);
}
.active_menu_item 
{
    color: var(--background-dark_plus) !important;
}
.main_menu_tag_wrapper:hover .main_menu {  
    color: var(--background-dark_plus) !important;
}
.main_link_tag_info 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

}
.main_menu_left_side_icon 
{
    padding: 2px;
    margin: 0px 3px;
    width: 2em;
    font-size: 1.0em;
    text-align: center;
}
.main_link_tag, .main_menu_left_side_icon, .pointer_icon
{
}