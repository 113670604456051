.group_batch_manager_wrapper 
{
    border: 2px solid var(--background-dark);
    margin: 10px;
    width: 80%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.group_manager_list_wrapper 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.group_manager_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.group_batch_header_wrapper
{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    background: var(--background-dark_minus);
    padding: 5px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.order_has_wrapper h2
{
    font-size: 0.9em;
    color: var(--text_light_white);
}
.order_hash_value 
{
    color: var(--background-light);
}
.order_action_wrapper button 
{
    font-size: var(--default-font-scale-min);
    padding: 3px 10px;
    font-weight: bold;
    border-radius: 20px;
    margin: 1px 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    user-select: none;
}
.order_action_wrapper button:hover 
{
    border-radius: 2px;
}
.reject_request 
{
    color: var(--primary_text_color);
    background: var(--red-color);
    border: 1px solid var(--red-color); 
}
.accept_request 
{
    color: var(--background-dark_plus);
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
}
.batch_group_footer_wrapper 
{
    background: var(--background-light);
    padding: 5px 10px;
}
.footer_action_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.footer_action_wrapper li 
{
    list-style: none;
    color: var(--background-dark_plus);
    background: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    margin: 2px 5px;
    padding: 3px 10px;
    border-radius: 20px;
}