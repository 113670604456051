.sidebar_expanded
{
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: left;
    width: 300px;
    height: 100vh;
    background: var(--background-dark_minus);
    transition: 0.1s ease-in-out;
    border-right: 2px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sidebar_collapsed 
{
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: left;
    width: 40px;
    height: 100vh;
    background: var(--background-dark_minus);
    border-right: 2px solid var(--background-dark);
    transition: 0.1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  

.menu 
{
    padding: 0;
}
.sub_menu 
{
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease-in-out;
}
.open_sub_menu 
{
    max-height: 500px;
    overflow: hidden;
    visibility: visible;
}
.menu_icon_info 
{
    background: none;
    font-size: 12px;
    padding: 0px 10px 0 10px;
    color: var(--primary_color);
}
.pointer_icon 
{
    float: right;
}
.menu_separator_tag li
{
    padding: 3px;
}
.wrap_toggel_button 
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 5px;
}
.wrap_toggel_button button
{
    z-index: 9;
    border: none;
    background: none;
    color: var(--color-theme);
    font-size: 1.5em;
    cursor: pointer;
    padding: 0px;
}
.menu_separator_tag_open li 
{
    font-weight: bold;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--text_light_white);
    background: var(--background-light);
}
.menu_separator_tag_open li:hover 
{
    cursor:help;
}
.menu_separator_tag_close li 
{
    display: none;
}

.loger_info_close 
{
    display: none;
}
.loger_info_open 
{
    text-align: center;
    padding: 10px;
}
.loger_info_open img 
{
    border-radius: 10%;
    border: 3px solid var(--primary_color);
    box-shadow: 0 8px 16px  rgba(0, 0, 0, 0.6);
}
.user_info
{
    text-align: left;
    padding: 20px;
}
.user_info li 
{
    font-size: 14px;
    padding: 5px;
    width: 200px;
    list-style: none;
}
.user_info_detail
{
    display: flex;
}
.req_name 
{
    color: var(--primary_color);
}
.req_value 
{
    color: var(--user_info_text_color);
    font-weight: bold;
}
.expand_icon 
{
    padding: 10px;
}
.admin_menu_head_hide 
{
    display: none;
}
.admin_menu_head 
{
    padding: 15px;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid var(--background-light);
}