.request_track_main_wrapper 
{
    position: relative;
    max-height: 97vh;
    overflow-y: scroll;
}
.request_track_inner_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}
.track_request_list_wrapper
 {
    margin: 5px;
    width: 47%;
    min-width: 20em;
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark_minus);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
  .list_wrapper 
  {
    padding: 5px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
  }
  .name_workspace 
  {
    font-weight: bold;
  }
  .source_help 
  {
    font-size: var(--default-font-scale-min);
  }
  .list_header 
  {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    background: var(--default_gradient);
    padding: 7px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
 }
 .list_header span 
 {
  padding: 0px 5px;
 }
 .list_header h2 
 {
  font-size: 0.8em;
 }
 .track_request_list_wrapper li 
 {
    padding: 5px 10px;
    color: var(--text_light_white);
    list-style: none;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 2px;
    margin: 5px;
    font-size: var(--default-font-scale-min);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
 }
 .track_request_list_wrapper li:hover 
 {
  background: var(--background-dark);
 }
.two_state_wrapper 
{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    padding: 20px;
}
 .wrapper_inner_last_log 
 {
    position: relative;
    width: 70%;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
 }
 .place_holder_main 
{
  padding: 20px;
  height: 5em;
  color: var(--text_light_white);
  text-align: center;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0.2;
}
 .state_log_top_main_wrapper 
 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
 }

 .action_data_wrapper
  {
    width: 30%;
    padding: 20px;
  }
  .action_main_req 
  {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .action_main_req_dash 
  {
    width: 70%;
  }
.action_main_req_dash button
{
    display: block;
    width: 100%;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: var(--default-font-scale-min);
    margin: 5px;
    cursor: pointer;
    user-select: none;
    border-top-left-radius: 10px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.action_terminate
{
    background: var(--red-color);
    border: none;
    color: var(--primary_text_color);
    font-weight: bold;
}
.action_terminate_dis
{
    background: var(--red-color);
    border: none;
    color: var(--primary_text_color);
    font-weight: bold;
    text-decoration: line-through;
}
.action_new
{
    background: var(--default_gradient);
    color: var(--background-dark_plus);
    border: none;
    font-weight: bold;
}
  .state_log_wrapper
  {
    padding: 10px;
  }
  .state_log_wrapper li 
  {
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    margin: 4px;
  }
  .ac_state_wrapper  
  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .request_transaction_wrapper_id
  {
    padding: 5px;
  }
  .request_transaction_wrapper_id span 
  {
    padding: 0px 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .request_transaction_wrapper_id span:hover 
  {
    color: var(--color-theme);
  }
  .approve_but 
  {
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
  }
  .pend_but 
  {
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
  }
  .declined_but 
  {
    background: var(--red-color);
    border: 1px solid var(--red-color);
  }
  .ing_but 
  {
    background: var(--color-yellow);
    border: 1px solid var(--color-yellow);
  }
  .ing_but_transferred 
  {
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
  }
  .tag_header 
  {
    list-style: none;
    font-size: 0.8em;
    font-weight: bold;
    color: var(--primary_text_color);
    margin: 10px;
    text-decoration: underline;
  }
  .tag_val 
  {
    list-style: none;
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    margin: 10px;
  }
  .tag_val_bold 
  {
    list-style: none;
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    margin: 10px;
    font-weight: bold;
  }
  .state_requester_main_wrapper 
  {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      padding: 20px;
      margin: 10px;
      border-top: 1px solid var(--background-dark);
  }
  .ac_requester_info
   {
    width: 40%;
   }
   .order_pending 
  {
    text-align: center;
    font-weight: bold;
    border: 1px solid var(--color-yellow);
    background: var(--color-yellow);
    color: var(--background-dark_plus);
    padding: 3px 10px;
    border-radius: 2px;
  }
  .order_moving 
  {
    text-align: center;
    font-weight: bold;
    border: 1px solid var(--dimm_white);
    background: var(--dimm_white);
    color: var(--background-dark_plus);
    padding: 3px 10px;
    border-radius: 2px;
  }
  .order_complete 
  {
    text-align: center;
    font-weight: bold;
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    color: var(--background-dark_plus);
    padding: 3px 10px;
    border-radius: 2px;
  }
.order_rejected 
{
  text-align: center;
  font-weight: bold;
  border: 1px solid var(--red-color);
  background: var(--red-color);
  color: var(--primary_text_color);
  padding: 3px 10px;
  border-radius: 2px;
}
.ac_requester_info_recive 
{
  position: relative;
  display: flex;
  justify-content: right;
  align-items: flex-start;
  align-content: flex-start;
}
.ac_requester_info_receive_method button
{
  position: relative;
  margin: 5px;
  font-size: var(--default-font-scale-min);
  padding: 3px 10px;
  font-weight: bold;
  user-select: none;
  color: var(--background-dark_plus);
  background: var(--default_gradient);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: 0.3s ease-in-out;
}
.ac_requester_info_receive_method button:hover 
{
  border-radius: 2px;
}
.order_item_approved_action_wrapper 
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.order_item_approved_action_wrapper span 
{
  margin: 2px 5px;
}
.order_request_approved 
{
  background: var(--color-theme);
  border: 1px solid var(--color-theme);
  color: var(--background-dark_plus);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 2px;
}
.order_request_pending 
{
  background: var(--color-yellow);
  border: 1px solid var(--color-yellow);
  color: var(--background-dark_plus);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 2px;
}
.order_request_decline 
{
  background: var(--red-color);
  border: 1px solid var(--red-color);
  color: var(--primary_text_color);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 2px;
}
.order_request_print 
{
  background: var(--dimm_white);
  border: 1px solid var(--dimm_white);
  color: var(--primary_text_color);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 2px;
}
.order_request_view 
{
  background: var(--text_light_white);
  border: 1px solid var(--text_light_white);
  color: var(--background-dark_plus);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 2px;
}

.take_key_order 
{
  position: absolute;
  right: 0;
  margin: 10px;
  margin-top: -90px;

  border: 1px solid var(--background-light);
  background: var(--background-light);

  opacity: 1;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  visibility: visible;
  transition: 0.3s ease-in-out;
}
.take_key_order button 
{
  width: 80%;
  padding: 5px 10px;
  transition: 0.1s ease-in-out !important;
}
.take_key_order_hide 
{
  position: absolute;
  right: 0;
  margin: 10px;

  margin-top: -150px;
  opacity: 0.0;
  visibility: hidden;

  border: 1px solid var(--background-light);
  background: var(--background-light);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transition: 0.3s ease-in-out;
}
.take_key_order li 
{
  list-style: none;
  color: var(--text_light_white);
  font-size: var(--default-font-scale-mid);
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  padding: 5px;
}
.take_key_order input 
{
  font-size: var(--default-font-scale-min);
  font-weight: bold;
  color: var(--color-theme);
  border: 1px solid var(--background-light);
  padding: 3px 10px;
  background: var(--background-dark_plus);
  width: 80%;
}
.ac_provided_reason_value 
{
  font-size: var(--default-font-scale-mid);
  font-weight: bold;
  list-style: none;
  text-wrap: wrap;
  width: 300px;
  color: var(--text_light_white);
}