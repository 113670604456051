.message_card_main_wrapper 
{
    margin: 3px 6px;
}
.message_card_main_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 2px 4px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    border-radius: 5px;
    margin: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 1;

    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.message_card_main_wrapper li span 
{
    flex: 1;
}
.message_card_main_wrapper li:hover 
{
    border-color: var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.send_name 
{
    color: var(--color-theme) !important;
    font-weight: bolder !important;
}
.reading 
{
    border-color: var(--background-light) !important;
    background: var(--background-dark) !important;
    margin: 5px;
}
.chef_image_wrapper img
{
    width: 3em;
    height: 3em;
    border-radius: 50%;
    border: 2px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.view_message_action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.chef_name_wrapper 
{
    text-align: left;
    flex: 4 !important;
    font-weight: bolder !important;
}
.main_message 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    width: 80%;
    background: var(--background-light);
    padding: 10px;
    border-radius: 10px;
    height: 5em;
    overflow: scroll;
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.main_message_hide 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    width: 80%;
    background: var(--background-light);
    border-radius: 10px;
    height: 0em;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
}
.main_message p, .main_message_hide p 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
}