.user_managment_main_wrapper 
{
    width: 100%;
    height: 100vh;
    background: var(--background-dark_plus);
    overflow: scroll;
}
.user_data_wrapper 
{
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.user_box_wrapper 
{
    border-radius: 10px;
    width: 95%;
    background: var(--background-dark);
    border: 1px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.user_list_wrapper 
{
    padding: 10px;
    overflow-y: scroll;
    min-height: 20em;
    max-height: 70vh;
}

.user_list_wrapper li 
{
    border-radius: 5px;
    margin: 5px;
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    padding: 2px 8px;
    list-style: none;
    color: var(--text_light_white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.user_list_wrapper li:hover 
{
    background: var(--background-dark_minus);
    border-bottom: 2px solid var(--text_light_white);
}
.user_list_wrapper li span 
{
    flex: 1;
}

.account_state_active 
{
    color: var(--background-dark_plus);
    border-radius: 10px;
    font-weight: bold;
    padding: 2px 15px;
    background: var(--default_gradient);
}
.online_state 
{
    color: var(--color-theme);
    font-size: 1.5em;
}

.offline_state {

    color: var(--red-color);
    font-size: 1.5em;
}
.account_state_suspend
{
    color: var(--primary_text_color);
    border-radius: 10px;
    font-weight: bold;
    padding: 2px 15px;
    background: var(--red-color);
}


.filter_header_wrapper 
{
    padding: 10px;
    border-bottom: 2px solid var(--background-light);

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.filter_action_button_wrapper  
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 5px;
}

.reg_action_button_wrapper 
{
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: left;
    background: var(--background-light);
}
.filter_input_wrapper span 
{
    color: var(--text_light_white);
    transition: 0.3s ease-in-out;
}
.filter_input_wrapper:hover span 
{
    color: var(--main-color);
}
.filter_input_wrapper input 
{
    border: 1px solid var(--background-dark_plus);
    padding: 5px 10px;
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 3px;
    width: 20em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.user_name 
{
    text-align: left;
    width: 25%;
}
.user_list_wrapper li .active span
{
    text-align: left;
    width: 25%;
    color: var(--color-theme);
    font-size: var(--default-font-scale-lrg);
}
.user_list_wrapper li .inactive span
{
    text-align: left;
    width: 25%;
    color: var(--red-color);
    font-size: var(--default-font-scale-lrg);
}
.account_state_active, .account_state_suspend 
{
    text-align: center;
    width: 15%;
}
.user_role 
{
    width: 20%;
    text-align: left;
}
.user_last_time 
{
    font-size: 0.8em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.user_more 
{
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8em;
}