.store_card_main_wrapper {
    min-width: 25em;
    width: 45%;
    margin: 10px;
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    border-radius: 3px;
    opacity: 0;
    transform: translateY(-100%);
    animation: slide_in 0.4s forwards;
    animation-delay: var(--animation-delay);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

@keyframes slide_in {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.store_card_main_wrap 
{
    height: 7em;
    max-height: 7em;
    overflow-y: scroll;
    padding: 10px 0px;
}
.warehouse_icon_wrapp 
{
    padding: 0px 3px;
    color: var(--text_light_white);
}
.store_card_header 
{
    background: var(--background-dark);
    color: var(--text_light_white);
    padding: 2px 10px;
}
.store_card_header h2 
{
    font-size: var(--default-font-scale-lrg);
}
.store_card_main_wrap li 
{
    display: flex;
    justify-content: space-between;
    border-radius: 2em;
    list-style: none;
    color: var(--text_light_white);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    text-transform: capitalize;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);;
    padding: 5px 10px;
    margin: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.warehouse_code 
{
    text-transform: uppercase;
    color: var(--text_light_white_fade);
    font-weight: bold;
}
.store_card_main_wrap li:hover 
{
    background: var(--background-dark);
    color: var(--primary_text_color);
}
.warehouse_add 
{
    text-align: center;
    width: 100%;
    display: block;
    font-size: 1.5em;
    color: var(--color-theme);
}