.hr_data_feed_pass_master_wrapper{
    height: 100vh;
    overflow: scroll;
}
.hr_data_feed_pass_workspace_wrapper_upper_layer
{
    background: var(--background-dark_plus);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

/* EMPTY BOX */

.empty_box_wrapper
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 10em;
 }
 .empty_box_wrapper ul 
 {
    margin: 3px;
    text-align: center;
    width: 100%;
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
    text-transform: uppercase;
 }


 /* Emplty */
 .empty_data_hr_data_feed_ready 
{
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    color: var(--text_light_white);
    padding: 20px;
}
.empty_data_hr_data_feed_ready li 
{
    width: 100%;
    text-align: center;
    color: var(--text_light_white);
    list-style: none;
    font-weight: 300;
    font-size: 0.8em;
    margin: 2px;
    text-transform: uppercase;

}
.empty_data_hr_data_feed_ready span 
{
    font-size: 2em;
}