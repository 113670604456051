.order_detail_man_wrapper 
{
    flex: 1;
    border-radius: 10px;
    border: 2px solid var(--background-light);
    margin: 10px;
    padding: 5px;
    background: var(--background-dark_minus);
    min-width: 40em;
}
.list_box_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.active_order_main_list_wrapper 
{
    padding: 10px;
    width: 98%;
    border-radius: 1em;
    border: 2px solid var(--background-dark_minus);
    background: var(--background-dark_plus);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.list_data_wrapper 
{
    height: 80vh;
    min-height: 20em;
    overflow: scroll;
}
.active_order_main_list_wrapper  li 
{
    
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);

    border-radius: 2em;
    padding: 3px 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    animation: smoth_in 0.3s ease-in-out;
}
@keyframes smoth_in {
    0% {
        opacity: 0.0;
        filter: blur(5px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}
.active_order_main_list_wrapper  li:hover 
{
    background: var(--background-dark_plus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    scale: 1.01;
}
.active_order_main_list_wrapper  li span 
{
    flex: 1;
    text-align: center;
}
.header_list 
{
    font-weight: bold;
    background: var(--background-light) !important;
    border: 2px solid var(--background-light) !important;
    border-radius: 5px !important;
    margin: 5px !important;
}
.header_list span 
{
    border-left: 2px solid var(--background-dark) !important;
    padding: 2px !important;
}
.action_button_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.order_status_pending 
{
    display: inline-flex;
    border: 1px solid var(--text_light_white);
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    padding: 2px 8px;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.order_stat_text 
{
    margin: 0px 3px;
}
.pre_payment_remain_amount 
{
    color: var(--color-theme) !important;
    font-weight: bolder !important;
    text-decoration: underline;
}

/* FILTER */
.filter_workshop_by_name 
{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-light);
    border-radius: 5px;
    padding: 3px 8px;
    width: 25em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper  li
{
    padding: 2px 4px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--text_light_white);
    list-style: none;
}
.workshop_filter_input_wrapper input 
{
    padding: 2px 4px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--text_light_white);
    list-style: none;
    width: 30%;
}

/* EMPTY BOX */

.empty_box_wrapper
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 10em;
 }
 .empty_box_wrapper ul 
 {
    margin: 3px;
    text-align: center;
    width: 100%;
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
    text-transform: uppercase;
 }