.data_body 
{
    padding: 20px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;

}
.data_preview_wrapper 
{
    margin: 10px;
    display: flex;
    justify-content: center;
}
.inner_preview_data_wrapper 
{
    width: 70%;
    background: #fff;
    height: 20em;
    max-height: 20em;
    overflow: scroll;
}
.meta_data_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    padding: 5px 20px;
    text-align: center;
    color: var(--print_paper_background);
}
.meta_data_wrapper h2
{
    font-size: 0.8em;
    margin: 5px;
    text-transform: uppercase;
}

.transcation_init li
{
    width: 100%;
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--background-dark_plus);
    margin: 3px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 6em;
}

.final_note_po_stock_out 
{
    padding: 5px;
}
.final_note_po_stock_out li 
{
    list-style: none;
    font-size: 0.5em;
    font-weight: bold;
    text-align: right;
}

/* BREAK */
.action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    padding: 15px;

    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.action_button_wrapper button 
{
    font-weight: bold;
    color: var(--background-dark_plus);
    font-size: 0.8em;
    width: 8em;
    padding: 3px;
    cursor: pointer;
    border-top-left-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.button_print 
{
    background: var(--inventory-type-color);
    border: 1px solid var(--inventory-type-color);
}
.button_dismiss 
{
    background: var(--red-color);
    border: 1px solid var(--red-color);
}


.letter_issue_date_recipiant li 
{
    list-style: none;
    margin: 2px;
    padding: 1px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    border-radius: 3px;
}
.employee_personal_info_label 
{
    width: 3em;
}
.employee_personal_info_value 
{
    text-transform: capitalize;
    font-weight: bold;
    width: 13em;
}

/* NN */
.main_paper_data_wrapper
 {
    padding-top: 240px;
 }
.letter_header 
{
    padding: 5px 10px;
}
.letter_issue_date_recipiant 
{
    padding: 5px;
}
.letter_issue_date_recipiant li 
{
    list-style: none;
    font-size: var(--default-font-scale-mid);
}
.letter_init_header 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
}
.letter_init_header h2 
{
    font-weight: bold;
    font-size: var(--default-font-scale-lrg);
    text-transform: uppercase;
    text-decoration: underline;
}
.letter_body_xp_doc 
{
    padding: 10px 50px;
}
.letter_body_xp_doc p 
{
    line-height: 20px;
    font-size: 0.9em;
    word-spacing: 2px;
}
.letter_xp_footer 
{
    padding: 10px 80px;
    padding-top: 80px;
}
.letter_xp_footer li 
{
    list-style: none;
    font-size: 0.8em;
    padding: 3px;
    font-weight: bolder;
}