.data_main 
{
    width: 95% !important;
    overflow-y: scroll;
}
@media (max-width: 1024px) {
    .data_main {
        width: 100% !important; /* Full width on smaller screens */
        height: 100vh !important; /* Full viewport height on smaller screens */
    }
}


.batch_operation_action 
{
    padding: 5px;
    margin: 3px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
    height: 8em;
    overflow: scroll;

    transition: 0.3s ease-in-out;
}
.batch_operation_action_hide 
{
    border: 2px solid var(--background-light);
    background: var(--background-light);
    filter: blur(3px);
    border-radius: 1px;
    margin: 3px 15px;
    height: 2px;
    padding: 2px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
}
.toggle_advance_setting_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.toggle_advance_setting_wrapper button 
{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--text_light_white);
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: none;
    color: var(--text_light_white);
    font-size: 1.0em;
    cursor: pointer;
    user-select: none;
    border-color: var(--color-theme);
    transition: 0.3s ease-in-out;
}
.toggle_advance_setting_wrapper button:hover 
{
    color: var(--color-theme);
}
.order_mark_action 
{
    padding: 5px;
    width: 45%;
}
.store_choice 
{
    width: 75%;
    margin: 5px;
}
.left_size_manipulate_wrapper 
{
    padding: 5px;
    width: 45%;
}
.order_mark_action .shift_controller, .left_size_manipulate_wrapper .shift_controller
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    padding: 6px 10px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    border-radius: 1px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 10px;

}
.day_shift 
{
    background: var(--dimm_white) !important;
    border: 1px solid var(--dimm_white) !important;
    color: var(--primary_text_color) !important;
}
.night_shift 
{
    background: var(--color-yellow) !important;
    border: 1px solid var(--color-yellow) !important;
    color: var(--background-dark_plus) !important;
}
.order_mark_action li, .left_size_manipulate_wrapper li 
{
    user-select: none;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    width: 70%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    padding: 3px 10px;
    margin: 5px 10px;
    border-radius: 1px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.order_save 
{
    user-select: none;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    width: 70%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    padding: 7px 10px;
    margin: 5px 10px;
    border-radius: 1px;
    gap: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.order_mark_action li span, .left_size_manipulate_wrapper li span 
{
    padding: 5px 10px;
}
.input_list_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
    margin: 10px;
    width: 45%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.po_mat_list 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.order_prod_list 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 3px;
}
.order_prod_list_active 
{
    border: 1px solid var(--color-theme);
    border-radius: 1px;
    background: var(--background-dark_plus);
    /* background: var(--color-theme); */
    box-shadow: var(--color-theme) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.input_adder 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 10px 15px;

    border-bottom: 2px solid var(--background-dark);
    background: var(--background-dark_plus) !important;
}
.input_adder li 
{
    list-style: none;
    padding: 3px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-bottom: 1px solid var(--text_light_white);
}
.input_adder span
 
{
    margin: 0px 3px;
}.input_adder button 
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;

    font-size: var(--default-font-scale-mid);
    background: var(--text_light_white);
    border: 1px solid var(--text_light_white);
    color: var(--background-dark_plus);
    padding: 2px 5px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.input_adder button:hover 
{
    background: var(--primary_text_color);
}
.order_item_spec 
{
    width: 50%;
}
.dat_wrap_flex 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.raw_material 
{
    width: 50%;
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.input_fild_shild 
 {
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .remove_button_wrapper
 {
   text-align: center;
   margin: 5px;
   padding: 3px;
   background: var(--red-color);
   border: 2px solid var(--red-color);
   color: var(--primary_text_color);
   border-radius: 5px;
   cursor: pointer;
   transition: 0.3s ease-in-out;
 }
 .remove_button_wrapper:hover 
 {
   font-weight: bold;
   scale: 1.1;
   background: none;
   color: var(--red-color);
 }
 .remove_button_wrapper_fake 
 {
    margin: 10px;
   padding: 6px;
 }
 .unified_stock_name_head 
 {
    color: var(--color-yellow) !important;
    border-color: var(--color-yellow) !important;
 }
 .config_name_setup_wrapper 
 {
    right: 15em;
    padding: 10px;
    width: 300px;
    border: 1px solid var(--background-light);
    position: absolute;
    margin-top: 50px;
    background: var(--background-dark);
    z-index: 999;
    border-radius: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: center;
    opacity: 1;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transition: 0.3s ease-in-out;
 }
 .config_name_setup_wrapper_hide 
 {
    right: 15em;
    padding: 10px;
    width: 300px;
    border: 1px solid var(--background-light);
    position: absolute;
    margin-top: 200px;
    background: var(--background-dark);
    z-index: 999;
    border-radius: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
 }
 .config_name_setup_wrapper input 
 {
    border: 1px solid var(--background-dark_minus);
    margin: 5px 10px;
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-min);
    border-radius: 10px;
    font-weight: bold;
    width: 50%;
    color: var(--text_light_white);
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
 .config_name_setup_wrapper button 
 {
    width: 40%;
    border: 1px solid var(--background-dark_minus);
    border-radius: 20px;
    font-size: var(--default-font-scale-min);
    padding: 5px 10px;
    background: var(--text_light_white);
    color: var(--background-dark_minus);
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .config_name_setup_wrapper button:hover 
 {
    border-radius: 1px;
 }