.list_box_wrapper 
{
    border: 1px solid var(--background-dark);
    border-radius: 5px;
    margin: 5px;
    width: 30em;
    min-width: 30em;
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.list_content_section_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.heder_type_main_wrapper 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    padding: 5px 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.heder_type_main_wrapper input 
{
    width: 10em;
    border: 1px solid var(--background-dark);
    background: var(--background-light);
    padding: 3px 8px;
    margin: 0px 2px;
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.heder_type_main_wrapper button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    cursor: pointer;
}
.bottom_action_wrapper 
{
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background: var(--background-dark_minus);
}
.heder_type_main_wrapper h3 
{
    color: var(--text_light_white);
    font-size: 0.8em;
    font-weight: bold;

    display: inline-flex;
}
.group_filter_and_action 
{
    display: inline-flex;
}
.items_list_wrapper 
{
    width: 100%;
    height: 20em;
    max-height: 20em;
    overflow: scroll;
    padding: 10px;
}
.list_single_item 
{
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 1px 10px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.list_single_item:hover 
{
    scale: 1.02;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-bottom: 1px solid var(--color-theme);
}
.product_input_data_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.product_input_data_wrapper li, .produc_singluar_name
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
}
.list_single_item input 
{
    width: 4em;
    margin: 5px;
    border-radius: 3em;
    padding: 2px;
    background: var(--background-dark);
    font-size: var(--default-font-scale-min);
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.list_single_item input[type="number"]::-webkit-outer-spin-button,
.list_single_item input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.data_inner_wrapper 
{
    flex: 1;
}
.data_inner_wrapper span 
{
    font-weight: bold;
    text-decoration: underline;
}
.damage_prod_report 
{
    display: inline-flex;
    padding: 2px 4px;
    border: 1px solid var(--red-color);
    background: var(--red-color);
    color: var(--primary_text_color) !important;
    border-radius: 2em;
}
.unsold_prod_report 
{
    display: inline-flex;
    padding: 2px 4px;
    border: 1px solid var(--text_light_white);
    background: var(--text_light_white);
    color: var(--background-dark_plus) !important;
    border-radius: 2em;
}
.damage_prod_report span, .unsold_prod_report span 
{
    margin-right: 2px;
}
.data_left_side_input_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}