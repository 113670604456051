.distro_branch_list_wrapper 
{
    padding: 10px;
    margin: 10px;

    height: 90vh;
    overflow: scroll;
}

.distro_branch_list_wrapper
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}