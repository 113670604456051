.emp_bank_main_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.user_selectde_bank_image, .user_bank_info_wrapper
{
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.user_bank_info_wrapper li 
{
    width: 100%;
    text-align: center;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    padding: 3px;
}
.user_bank_info_wrapper h4 
{
    color: var(--text_light_white);
}
.user_selectde_bank_image img 
{
    transform:
    perspective(400px)
    rotateX(20deg);
    width: 12em;
    height: 7em;
    border-radius: 10px;
    border: 2px solid var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: transform 1.8s cubic-bezier(0.42, 0, 0.58, 1);
    cursor: pointer;
}
.user_selectde_bank_image img:hover 
{
    transform: perspective(400px) rotateX(20deg) rotateY(360deg) rotateX(-360deg);
}
.action_button_wrapper_bank_edit 
{
    padding: 10px;
}