.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    padding: 15px;
    border: 1px dotted var(--background-light);
    font-weight: bold;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    padding: 5px;
    border: none;
    border-bottom: 2px solid var(--background-light);
    transition: 0.3s ease-in-out;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: var(--background-dark);
    color: var(--background-light);
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: none !important;
    color: var(--primary_text_color) !important;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day 
  {
    color: var(--primary_text_color);
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: var(--grren-color);
  }
  
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
  }
  
  .react-calendar__tile:disabled {
    background-color: var(--background-dark);
    color: var(--background-light);
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }

  
  /* .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  } */
  
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  /* Custom override */
  .react-calendar { 
    width: 100%;
    max-width: 100%;
    background-color: var(--background-dark);
    border: 2px solid var(--background-light);
    color: var(--primary_text_color);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
   }
   .react-calendar__navigation button {
    color: var(--grren-color);
    min-width: 44px;
    background: none;
    font-size: 18px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: var(--background-dark);
    color: var(--background-light);
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   /* Active Day Marker */
   .present-day 
   {
        font-size: 13px;
        font-weight: 700;
        color: var(--primary_text_color);
        background: var(--grren-color);
   }
   .absent-day 
   {
    font-size: 13px;
    font-weight: 300;
    color: var(--primary_text_color);
    background: #141414;
   }


   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    border: 1px solid var(--grren-color);
    background: var(--background-dark_plus);
    color: var(--primary_text_color);
    border-radius: 1px;
   }


   /* .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: var(--primary_text_color);
   } */
   /* .react-calendar__tile--active {
    background: var(--background-light);

    font-weight: bold;
    color:var(--background-dark);
   } */
   /* .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: var(--background-light);
    color: var(--primary_text_color);
   } */
 

    /* Active Day Marker */
   
   /*  Today */
   .react-calendar__tile--now {
    background: var(--background-dark_plus);
    border: 1px solid var(--grren-color) !important;
    color: var(--grren-color);
    font-weight: bold;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: var(--background-dark_plus);
    border: 1px solid var(--grren-color);
    color: var(--grren-color);
    font-weight: bold;
   }
/* End Today */
.present_day  
{
     font-size: 13px;
     font-weight: 700;
     color: var(--primary_text_color) !important;
     background: var(--grren-color) !important;
}
.absent_day 
{
 font-size: 13px;
 font-weight: 300;
 color: var(--primary_text_color);
 background: var(--background-dark_plus);
}
.pto_day 
{
    font-size: 13px;
    font-weight: 700;
    color: var(--background-dark) !important;
    background: var(--color-yellow) !important;
}