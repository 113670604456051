.chef_lineup_main_workshop_wrapper 
{
    width: 80%;

    background: var(--background-light);
    background: var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;
    margin: 10px;
}
.chef_lineup_header 
{
    position: relative;
    background: var(--background-dark);
    padding: 2px 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;


    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.chef_lineup_header h3 
{
    color: var(--text_light_white);
    font-size: 0.9em;
}
.chef_lineup_header button 
{
    background: none;
    color: var(--text_light_white);
    border: none;
    font-size: 1.3em;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.chef_lineup_header button:hover 
{
    color: var(--color-theme);
}
.chef_line_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 15px;

    max-height: 15em;
    overflow-y: scroll;
}
.workspace_active_employee_list_wraper
{
    z-index: 10;
    position: absolute;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    padding: 5px;
    border-radius: 5px;

    top: 2em;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    visibility: visible;
    opacity: 1;
    width: 15em;
}
.workspace_active_employee_list_wraper_hide
 {
    position: absolute;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    padding: 5px;
    border-radius: 10px;

    top: 2em;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    width: 15em;
 }

.employee_assignement_list 
{
    max-height: 10em;
    overflow: scroll;
}
.employee_assignement_list li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 3px 6px;
    border: 2px solid var(--background-dark);
    margin: 5px 2px;
    background: var(--background-dark_plus);
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 1;

    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.employee_assignement_list li span 
{
    flex: 1;
    margin: 3px;
}
.employee_assignement_list li:hover 
{
    border-color: var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.chef_image_wrapper img
{
    width: 3em;
    height: 3em;
    border-radius: 50%;
    border: 2px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.chef_name_wrapper 
{
    text-align: left;
    flex: 4 !important;
    font-weight: bolder !important;
}
.emplye_employee_notify 
{
    padding: 10px 20px;
    text-align: center;
}
.emplye_employee_notify h5 
{
    width: 100%;
    color: var(--text_light_white);
    text-transform: uppercase;
    font-size: var(--default-font-scale-min);
    text-align: center;
}
.emplye_employee_notify h2 
{
    text-align: center;
    color: var(--primary_text_color);
}

.empty_chef_Collcation 
{
    padding: 10px;
}
.empty_chef_Collcation h1 
{
    color: var(--text_light_white);
    text-align: center;
}
.empty_chef_Collcation h2 
{
    color: var(--text_light_white);
    font-size: 0.8em;
    text-transform: uppercase;
}