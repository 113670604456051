.vh_preview_card_master_wrapper 
{
    position: relative;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 5px;
    width: 15em;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 2em;
}
.vh_preview_card_master_wrapper:hover 
{
    border-bottom: 2px solid var(--color-theme);
    background: var(--background-dark);
    box-shadow: var(--color-theme) 0px 25px 20px -20px;
}
.preview_card_image 
{
    border-radius: 2em;
    background: var(--background-dark_minus);
    display: flex;
    height: 3em;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-position: center;
    background-size: cover;
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.preview_card_image li
{
    list-style: none;
    color: var(--color-theme);
    font-size: 2em;
}
.card_body_wrapper
{
    padding: 10px;
}
.card_body_wrapper li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    margin: 3px;

    display: flex;
    padding: 2px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid var(--background-dark_minus);
}
.vh_preview_card_tag 
{
    width: 8em;
}
.vh_preview_card_value
 {
    font-weight: bold;
 }

 .car_moving 
 {
    position: absolute;
    top: 2.5em;
    padding: 5px;
    animation: blip 1s ease-in-out infinite, moveRight 10s linear infinite;
 }
 .car_moving li
 {
    list-style: none;
    color: var(--color-theme);
 }
 .speed_metric 
 {
    font-size: 0.5em;
    text-align: center;
 }
 @keyframes moveRight {
    0% {
      right: 85%;
    }
    100% {
      right: 1%;
    }
  }
 @keyframes blip {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
 }
 .car_move_icon 
 {
    animation: up_down 1s alternate infinite;
 }
 @keyframes up_down {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform:  translateY(4px);
    }
 }