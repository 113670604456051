.active_order_list_master_wrapper 
{
    min-width: 30em;
    width: 48%;
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
    margin: 10px;
    flex: 1;
    animation: sooth_in 0.3s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.hr_data_feed_header 
{
    padding: 5px 10px;
    background: var(--background-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.hr_data_feed_header h2 
{
    font-weight: lighter;
    font-size: 0.8em;
    color: var(--text_light_white);
}
.list_box_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.active_order_main_list_wrapper 
{
    padding: 10px;
    width: 100%;
    border: 2px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
}
.list_data_wrapper 
{
    height: 15em;
    max-height: 15em;
    overflow: scroll;
}
.dots_view_loading_hr_pass_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 15em;
    max-height: 15em;
}
.active_order_main_list_wrapper  li 
{
    
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-weight: bold;

    border: 2px solid var(--background-light);
    background: var(--background-dark);

    border-radius: 2em;
    padding: 2px 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    animation: smoth_in 0.3s ease-in-out;
}
@keyframes smoth_in {
    0% {
        opacity: 0.0;
        filter: blur(5px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}
.active_order_main_list_wrapper  li:hover 
{
    background: var(--background-dark_plus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.active_order_main_list_wrapper  li span 
{
    flex: 1;
    text-align: left;
}
.header_tab_tabe 
{
    border-radius: 2em;
    background: var(--text_light_white) !important;
    color: var(--background-dark_plus) !important;
    font-weight: bolder !important;
    margin-bottom: 10px !important;
    margin-bottom: 5px !important;
}
.action_button_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.order_status_pending 
{
    display: inline-flex;
    border: 1px solid var(--text_light_white);
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    padding: 2px 8px;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.order_stat_text 
{
    margin: 0px 3px;
}
.pre_payment_remain_amount 
{
    color: var(--color-theme) !important;
    font-weight: bolder !important;
    text-decoration: underline;
}

/* FILTER */
.filter_workshop_by_name 
{
    background: var(--background-dark_minus);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-light);
    border-radius: 5px;
    padding: 2px 6px;
    width: 25em;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.workshop_filter_input_wrapper input 
{
    padding: 1px 2px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    color: var(--text_light_white);
}
.workshop_filter_input_wrapper span 
{
    color: var(--text_light_white);
    font-size: 0.8em;
}

/* EMPTY BOX */

.empty_box_wrapper
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 10em;
 }
 .empty_box_wrapper ul 
 {
    margin: 3px;
    text-align: center;
    width: 100%;
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
    text-transform: uppercase;
 }


.employee_name_text
{
    text-align: left !important;
}
.employee_bank_account_wrapper_item  
{
    text-align: left !important;
}
.employee_salary 
{
    font-weight: bold !important;
    color: var(--color-theme) !important;
}
.ot_adder_input_wrap 
{
    float: left;
    margin-right: 8px;
}
.excel_input_like 
{
    width: 60%;
    padding: 1px 2px;
    text-align: center;
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
    font-weight: bold;
}