.chefs_dashboard_inner_main_wrapper 
{
    height: 90vh;
    overflow: scroll;
}
.chefs_dashboard_recipe_card_list 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
}
/* Header Mean */
.chefs_dashboard_header_wrapper
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
}
.chefs_dashboard_wrapper_header_nav 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    width: 80%;
    padding: 5px 10px;
    border-radius: 3px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.action_button_nav_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 99%;
}
.chef_setting_action_button 
{
    text-align: right;
}
.chef_setting_action_button button
{
    background: none !important;
    border: none !important;
    font-size: 1em !important;
    box-shadow: none !important;
    color: var(--primary_text_color) !important;
}
.chefs_dashboard_wrapper_header_nav button 
{
    position: relative;
    margin: 3px 6px;
    padding: 3px 10px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;

    color: var(--text_light_white);
    border-radius: 3px;

    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);

    cursor: pointer;
    transition: 0.3s ease-in-out;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.chefs_dashboard_wrapper_header_nav button:hover 
{
    background: var(--text_light_white);
    border-color: var(--text_light_white);
    color: var(--background-dark_plus);
}
.active_nav 
{
    background: var(--text_light_white) !important;
    border-color: var(--text_light_white) !important;
    color: var(--background-dark_plus) !important;
}
.kicthen_stock_buffer_wrapper 
{
    z-index: 10;
    position: absolute;
    right: 0;
    top: 3em;
    margin: 5px;
    width: 25em;
    height: 20em;
    max-height: 20em;
    overflow: scroll;

    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

    visibility: visible;
    opacity: 1;
    transition: 0.2s ease-in-out;
}
.kicthen_stock_buffer_wrapper_hide 
{
    visibility: hidden;
    width: 20em;
    opacity: 0;

    position: absolute;
    right: 0;
    top: -50em;


    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 0.2s ease-in-out;

}
.kicthen_stock_buffer_header 
{
    background: var(--background-dark);
    padding: 3px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.kicthen_stock_buffer_header h3 
{
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
}
.kicthen_stock_buffer_header button 
{
    background: none;
    border: none;
    color: var(--primary_text_color);
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.kicthen_stock_buffer_header button:hover 
{
    color: var(--red-color);
}
.local_buffer_count 
{
    position: absolute;
    top: -1em;
    right: -1em;
    height: 1em;
    width: 1em;
    padding: 4px;
    font-size: 0.9em;
    border: 2px solid var(--color-them) !important;
    background: var(--color-theme) !important;
    color: var(--background-dark_plus) !important;
    font-weight: bold;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.kicthen_store_buffer_list 
{
    padding: 10px;
}
.kicthen_store_buffer_list li
{
    color: var(--text_light_white);
    font-weight: bold;
    font-size: var(--default-font-scale-min);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 5px 10px;
    margin: 3px;
    border: 1px solid var(--text_light_white);
    background: var(--background-light);
    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.kicthen_store_buffer_list li:hover 
{
    scale: 1.05;
}
.recipe_card_val, .recipe_card_tag
{
    text-align: left !important;
    width: 25%;
}
.action_button_wrapper 
{
    text-align: right !important;
    width: 10em !important;
}

.chefs_dashboard_production_card_list 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

}
.request_queue_halo
{
    padding: 10px;
}
.request_queue_halo h2
{
    color: var(--text_light_white);
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    font-size: var(--default-font-scale-lrg);
}
.request_queue_halo h1 
{
    color: var(--text_light_white);
    font-size: 3em;
    padding: 5px;
    text-align: center;
}
