.master_product_type_inner_config_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    padding: 10px;
}
.personal_employee_info_input_col_group 
{
   width: 45%;
}