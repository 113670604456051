.group_body_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    border-radius: 3px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 6px 12px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
}
.sub_group_data_list_wrapper 
{
    width: 98%;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.sub_group_data_list_wrapper li
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    min-width: 25em;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);

    border-radius: 3px;

    font-weight: bold;

    width: 98%;
    padding: 5px 10px;
    margin: 3px 10px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 6px 12px, rgba(0, 0, 0, 0.23) 0px 3px 3px;

    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.sub_group_data_list_wrapper li:hover 
{
    border-bottom: 2px solid var(--color-theme);
    background: var(--background-dark_minus);
}
.sub_group_data_list_wrapper li span 
{
    flex: 1;
}
.quantity_data_wrapper span
{
    margin: 0px 3px;
    padding: 1px 10px;
    background: var(--background-light);
    border: 1px solid var(--background-light);
    color: var(--text_light_white) !important;
    font-weight: bolder;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.action_button_wrapper_group 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.sub_group_action_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px 10px;

    background: var(--background-dark_minus);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.tag_val 
{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.date_info_action_wrap_sub_group li 
{
    text-transform: capitalize;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    margin: 2px;
}