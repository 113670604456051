.data_main 
{
  width: 80% !important;
}
.input_list_wrapper
 {
    display: flex;
    justify-content: left;
    padding-bottom: 10px;
    flex-wrap: wrap;

    border-bottom: 2px solid var(--background-light);
 }
 .form_wrapper 
 {
    padding: 10px;
 }
 .form_action_button_wrapper 
 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
 }
 .ing_selection 
 {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
 }
 .input_adder 
 {
   display: flex;
   justify-content: left;
   padding: 10px;
   border-top: 2px solid var(--background-light);
 }
 .input_adder button
 {
   border: 1px solid var(--text_light_white);
   background: var(--text_light_white);
   font-size: var(--default-font-scale-min);
   font-weight: bold;
   padding: 5px 10px;
   color: var(--background-dark_plus);
   border-radius: 3px;
   cursor: pointer;
   transition: 0.3s ease-out;
   user-select: none;
}
.input_adder button:hover 
{
   background: var(--background-light);
   color: var(--color-theme);
   box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
 }
 .input_fild_shild 
 {
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .remove_button_wrapper
 {
   text-align: center;
   margin: 5px;
   padding: 3px;
   background: var(--red-color);
   border: 2px solid var(--red-color);
   color: var(--primary_text_color);
   border-radius: 5px;
   cursor: pointer;
   transition: 0.3s ease-in-out;
 }
 .remove_button_wrapper:hover 
 {
   font-weight: bold;
   scale: 1.1;
   background: none;
   color: var(--red-color);
 }
 .remove_button_wrapper_fake 
 {
    margin: 10px;
   padding: 6px;
 }
 .mix_list_ing_wrap 
 {
   width: 60%;
   padding: 20px 10px;
   border-radius: 10px;
   max-height: 12em;
   height: 12em;
   overflow-y: scroll;
   background: var(--background-dark_minus);
   box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 7px, rgba(0, 0, 0, 0.22) 0px 2px 2px;
 }

 .recipe_usage_wrapper 
 {
    margin: 5px;
    padding: 3px 5px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    user-select: none;
    height: 1.5em !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 }
 .recipe_usage_wrapper span 
 {
  font-size: 0.55em;
  color: var(--text_light_white);
  padding: 1px 10px;
  text-transform: uppercase;
 }

 .master_product_type_inner_avail_workspace_list 
 {
     margin: 10px;
     width: 30%;

     padding: 20px 10px;
    border-radius: 10px;
    max-height: 15em;
    overflow-y: scroll;
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 7px, rgba(0, 0, 0, 0.22) 0px 2px 2px;
 }
 .master_prod_avail_list 
 {
     padding: 10px;
     height: 7em;
     max-height: 7em;
     overflow-y: scroll;
 }
 .master_prod_avail_branch 
 {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     align-content: center;
     margin: 4px;
     border: 1px solid var(--background-dark);
     background: var(--background-dark_minus);
     padding: 1px 10px;
     transition: 0.3s ease-in-out;
     cursor: pointer;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
 .master_prod_avail_branch:hover 
 {
     background: var(--background-dark);
     scale: 1.02;
 }
 .master_prod_avail_branch  li 
 {
     list-style: none;
     color: var(--text_light_white);
     font-size: var(--default-font-scale-mid);
     margin: 5px;
 }
 .master_product_type_inner_avail_workspace_list_header 
 {
     display: flex;
     justify-content: space-between;
     align-items: center;
     align-content: center;
     padding: 5px;
 
     border: 2px solid var(--background-dark);
     background: var(--background-dark);
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     margin: 10px;
 }
 .master_product_type_inner_avail_workspace_list_header button 
 {
     background: none;
     border: none;
     color: var(--text_light_white);
     cursor: pointer;
     transition: 0.3s ease-in-out;
     font-size: 0.8em;
     float: top;
     &:hover 
     {
         color: var(--color-theme);
     }
 }
 .master_product_type_inner_avail_workspace_list_header h1 
 {
     font-size: 0.8em;
     text-transform: capitalize;
     color: var(--text_light_white);
 }
 .master_product_type_inner_config_wrapper 
 {
     width: 40%;
 }
 .master_product_type_inner_config_wrapper li
 {
     list-style: none;
     margin: 5px;
 }
 .master_product_type_inner_config_wrapper li input 
 {
     border: 1px solid var(--background-light);
     background: var(--background-dark);
     border-radius: 3px;
     width: 70%;
     font-size: var(--default-font-scale-mid);
     color: var(--text_light_white);
     box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
     transition: 0.3s ease-in-out;
 }