.data_main 
{
    width: 50em !important;
}
.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}
.master_product_type_inner_config_wrapper 
{
    width: 60%;
    padding: 15px;
}