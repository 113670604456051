.dispatched_iniventory_request_bundle_master_wrapper 
{
    margin: 5px;
    min-width: 30em;
    width: 45%;
    flex: 1;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.dispatched_iniventory_request_bundle_wrapper_header 
{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--color-theme);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding: 5px 6px;
}
.dispatched_iniventory_request_bundle_filter_drop_down_list
 {
    z-index: 1;
    position: absolute;
    left: 0;
    min-width: 10em;
    top: 1.5em;
    padding: 5px 4px;
    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;

    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
 }
 .dispatched_iniventory_request_bundle_filter_drop_down_list li 
 {
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    margin: 1px;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    padding: 2px 4px;
    width: 90%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 2em;
 }
 .dispatched_iniventory_request_bundle_filter_drop_down_list hr 
 {
    width: 100%;
    margin: 3px;
    border: 1px solid var(--background-light);
    background: var(--background-light);
 }
 .dispatched_iniventory_request_bundle_filter_drop_down_list li:hover 
 {
    background: var(--background-light);
 }
.dispatched_iniventory_request_bundle_wrapper_header h2
{
    font-size: 0.8em;
    color: var(--background-dark_plus);
    font-weight: bold;
}
.dispatched_iniventory_request_bundle_wrapper_header span 
{
    display: inline-flex;
}
.transaction_list_wrapper 
{
    height: 20em;
    background: var(--background-dark_minus);
    overflow-y: scroll;
    border: 2px solid var(--background-dark);
}
.transaction_list_wrapper .transaction_list_inner_single
{
    position: relative;
    cursor: pointer;
    list-style: none;
    color: var(--text_light_white);
    border-radius: 1em;
    border-bottom: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    margin: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-min);
    padding: 3px 10px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.transaction_list_wrapper .transaction_list_inner_single:hover 
{
    border-bottom: 2px solid var(--color-theme);
}
.transaction_list_wrapper li 
{
    flex: 1;
    text-align: left;
}
.transaction_list_wrapper .center_align_span 
{
    gap: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.dispatched_iniventory_request_bundle_wrapper_footer_action 
{
    padding: 5px 10px;
    background: var(--background-light);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.dispatched_iniventory_request_bundle_wrapper_footer_action input 
{
    border: 1px solid var(--background-dark_plus);
    padding: 4px 8px;
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 2em;
    width: 25em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.empty_stock_transaction_history 
{
    padding: 5px;
    height: 15em;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.empty_stock_transaction_history li 
{
    color: var(--text_light_white);
    font-weight: bold;
    list-style: none;
    text-align: left;
    font-size: 0.8em;
    padding: 3px;
}