.data_body 
{
    height: 20em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
}
.button_action_menu_choice_list_wapper 
{
    padding: 2px;
    background: var(--background-dark_minus);
    height: 19.8em;
    border-right: 2px solid var(--background-dark);
}
.left_action_button_wrapper button 
{
    width: 20em;
    font-weight: bold;
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    padding: 3px 6px;
    font-size: var(--default-font-scale-min);
    text-align: left;
    color: var(--text_light_white);
    transition: 0.1s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.left_action_button_wrapper button:hover 
{
    background: var(--background-light);
}
.active_side_left_action_tab
 {
    background: var(--background-light) !important;
    border-bottom: 2px solid var(--color-theme) !important;
 }
 .master_setting_right_contaient_wrapper 
 {
    flex: 1;
    padding: 10px;
 }
 .footer_action_buttons 
 {
    padding: 5px 8px;
    background: var(--background-light);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
 }