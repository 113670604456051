.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}
.orderbale_state 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 3px 5px;
    margin: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    user-select: none;
}
.orderbale_state span 
{
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;
}
.master_product_type_inner_avail_workspace_list 
{
    margin: 10px;
    width: 40%;
}
.master_prod_avail_list 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.master_prod_avail_branch 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 4px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 1px 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.workspace_name_tick 
{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
}
.master_prod_avail_branch:hover 
{
    background: var(--background-dark);
    scale: 1.02;
}
.master_prod_avail_branch  li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    margin: 5px;
}
.master_product_type_inner_avail_workspace_list_header 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px;

    border: 2px solid var(--background-dark);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 10px;
}
.master_product_type_inner_avail_workspace_list_header button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 0.8em;
    float: top;
    &:hover 
    {
        color: var(--color-theme);
    }
}
.master_product_type_inner_avail_workspace_list_header h1 
{
    font-size: 0.8em;
    text-transform: capitalize;
    color: var(--text_light_white);
}


