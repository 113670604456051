.hr_data_workshope_data_bundle 
{
    width: 48%;
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 5px;
    margin: 10px;
    flex: 1;
    animation: sooth_in 0.3s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@keyframes sooth_in {
    0% {
        filter: blur(10px);
        opacity: 0;
    }
    100% {
        filter: blur(0px);
        opacity: 1;
    }
}

.top_hr_data_feed_pass_right_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.hr_data_feed_header 
{
    padding: 5px 10px;
    background: var(--background-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.hr_data_feed_header h2 
{
    font-weight: lighter;
    font-size: 0.8em;
    color: var(--text_light_white);
}
.hidden_file_input 
{
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0; /* Completely hide the file input */
    overflow: hidden;
    z-index: -1;
}
.custom_file_input
{
    display: inline-block;
    padding: 3px 10px;
    background-color: var(--background-dark_plus);
    color: var(--text_light_white);
    border-radius: 2px;
    cursor: pointer;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: background-color 0.3s;
}
.hr_data_feed_pass_workspace_wrapper 
{
    position: relative;   
}
.data_roll_hr_data_payroll_feed 
{
    height: 15em;
    max-height: 15em;
    overflow: scroll;
}
.hr_data_feed_pass_workspace_wrapper li 
{
    padding: 3px 6px;
    background: var(--background-dark);
    border: 1px solid var(--background-dark);
    margin: 3px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    color: var(--text_light_white);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    cursor: pointer;
    animation: slide_in 0.5s ease-in-out;
    transition: 0.3s ease-in-out;
}

@keyframes slide_in {
    0% {
        background: var(--primary_text_color);
        filter: blur(10px);
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        background: var(--background-dark);
        filter: blur(0px);
        transform: translateX(0);
        opacity: 1;
    }
}
.hr_data_feed_pass_workspace_wrapper li:hover 
{
    background: var(--background-dark_minus);
    border: 1px solid var(--background-light);
    border-radius: 2em;
}
.hr_data_feed_pass_workspace_wrapper li span 
{
    text-align: center;
    width: 10em;
}
.valid_read 
{
    color: var(--color-theme);
    font-size: 1em !important;
}
.invalid_read 
{
    color: var(--red-color);
    font-size: 1em !important;
}
.header_tab_tabe 
{
    border-radius: 2em;
    background: var(--text_light_white) !important;
    color: var(--background-dark_plus) !important;
    font-weight: bolder !important;
    margin-bottom: 10px !important;
    margin-bottom: 5px !important;
}
.dragging_package_info 
{
    position: absolute;
    width: 100%;
    height: 16.5em;
    border: 1px solid var(--background-dark);
    background: var(--alpha-background); 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.dragging_package_info ul
{
    font-size: 1.3em;
    color: var(--text_light_white);
    text-transform: uppercase;
    font-weight: bold;
}

.employe_employee_attendy_data 
{
    height: 15em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.employe_employee_attendy_data ul 
{
    font-size: var(--default-font-scale-lrg);
    color: var(--background-light);
    text-transform: uppercase;
    font-weight: bold;
    margin: 5px;
    text-align: center;
    width: 100%;
}
.hr_data_feed_footer_data_View 
{
    background: var(--background-light);
    padding: 3px 10px;
    
}
.hr_data_feed_footer_data_View li 
{
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
}
.ot_adder_input_wrap 
{
    float: left;
}
.excel_input_like 
{
    width: 60%;
    padding: 1px 2px;
    text-align: center;
    font-size: var(--default-font-scale-lrg);
    color: var(--text_light_white);
    font-weight: bold;
}


.filter_workshop_by_name 
{
    background: var(--background-dark_minus);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-light);
    border-radius: 5px;
    padding: 2px 6px;
    width: 25em;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.workshop_filter_input_wrapper input 
{
    padding: 1px 2px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    color: var(--text_light_white);
}
.workshop_filter_input_wrapper span 
{
    color: var(--text_light_white);
    font-size: 0.8em;
}