.po_main_wrapper 
{
    width: 100%;
    height: 100vh;
    background: var(--background-dark_minus);
    overflow: scroll;
}
.tool_quick 
{
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.list_wrapper, .view_saved_list_item 
{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}
.list_wrapper_with_action_but, .action_button_wrapper_po 
{
  padding: 10px;
  margin: 10px;
}
.action_button_wrapper_po 
{
  border: 1px solid var(--background-dark);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.action_button_wrapper_po
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.order_button_group_left button, .order_button_group_right button
{
  cursor: pointer;
  background: var(--background-dark_minus);
  border: 1px solid var(--background-light);
  font-size: var(--default-font-scale-mid);
  font-weight: bold;
  padding: 5px 10px;
  color: var(--text_light_white);
  margin: 0px 5px;
  text-wrap: nowrap;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: 0.3s ease-in-out;
}
.order_button_group_left button:hover, .order_button_group_right button:hover 
{
  background: var(--text_light_white);
  color: var(--background-dark_plus);
}
.order_button_group_right 
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  width: 40%;
}
.prod_order_filter_input_wrapper 
{
  width: 50%;
}
.prod_order_filter_input_wrapper input
{
  border: 2px solid var(--background-dark);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: var(--default-font-scale-min);
  color: var(--text_light_white);
  font-weight: bold;
  margin: 5px 10px;
  width: 80%;
  background: var(--background-dark_plus);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.process_batch 
{
  background: var(--color-theme) !important;
  color: var(--background-dark_plus) !important;
}
.po_info_wrapper 
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.po_info_wrapper_main 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    width: 40%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.order_query_body 
{
   height: 400px;
   max-height: 400px;
   overflow: scroll;
}

/* Pointer */

.po_info_wrapper_main_pointer 
{
    width: 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.process_pointer div
 {
    
    width: 100%;
    height: 100%;
    color: var(--background-light);
    font-size: 18px;
    animation: blip 2s infinite;
 }
 @keyframes blip {
    50% {
        scale: 0.7;
    }
    100% {
        scale: 1.0;
    }
    
 }

 /* in of header */
 .po_info_wrapper_main_header 
 {
    background: var(--background-light);
    padding: 10px;
    text-align: left;
    display: flex;
    justify-content: space-between;
 }
 .po_info_wrapper_main_header h1
 {
    font-size: 15px;
    color: var(--primary_text_color);
 }
 .po_info_special_head 
 {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
 }
 .po_info_wrapper_main_header .po_date 
 {
    border: 1px solid var(--grren-color);
    background: var(--grren-color);
    color: var(--primary_text_color);
    padding: 2px 10px;
    border-radius: 1px;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 0.3s ease-in-out;
 }
 .po_info_wrapper_main_header .po_shift 
 {
    border: 1px solid var(--color-yellow);
    background: var(--color-yellow);
    color: var(--background-dark_plus);
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 1px;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 0.3s ease-in-out;
 }
 .shift_reminder 
 {
    padding: 20px;
    display: flex;
    justify-content: right;
}
.count_down_wrapper
 {
    border: 1px solid var(--background-light);
    background: var(--background-light);
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
 }
.count_down_wrapper li
{
    text-align: center;
    list-style: none;
    color: var(--text_light_white);
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
}
.count_down_wrapper .shift_remain_time_static_info 
{
     border-bottom: 1px solid var(--text_light_white);
 }
 .shift_remain_time span 
 {
    padding: 2px;
    font-weight: 600;
    font-size: 14px;
 }


 /* Create PO */
 .po_create_button_wrapper 
 {
    padding: 20px;
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
 }
 .po_create_button_wrapper button 
 {
   margin: 5px;
    padding: 5px 10px;
    border: 2px solid var(--grren-color);
    background: var(--grren-color);
    color: var(--primary_text_color);
    font-weight: bold;
    font-size: 12px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
 }
 .po_create_button_wrapper button:hover 
 {
    background: none;
    color: var(--grren-color);
 }
 .order_query_body 
 {
   padding: 10px;
 }
 .order_query_body .po_list_single .order_inner_list_point
 {
   border: 1px solid var(--background-dark);
   border-left: 3px solid var(--color-yellow);
   padding: 5px 10px;
   background: var(--background-dark);
   color: var(--text_light_white);
   
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;

   margin-top: 10px;
   margin-bottom: 10px;

   box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
 }
 .order_action_wrapper button
 {
   border: 1px solid var(--text_light_white);
   background: none;
   color: var(--text_light_white);
   border-radius: 5px;
   cursor: pointer;
   font-size: 10px;
   padding: 5px;
   margin-left: 5px;
   margin-right: 5px;
   transition: 0.3s ease-in-out;
 }
 .order_action_wrapper button:hover 
 {
   background: var(--text_light_white);
   color: var(--background-dark_plus);
 }
 .order_batch 
 {
   border: 1px solid var(--background-light);
   background: var(--background-light);
   padding: 2px 10px;
   border-radius: 2px;
   box-shadow: rgba(31, 31, 31, 0.16) 0px 3px 6px, rgba(31, 31, 31, 0.23) 0px 3px 6px;
 }
 .order_expire 
 {
   border: 1px solid var(--red-color);
   background: var(--red-color);
   padding: 2px 10px;
   color: var(--background-dark_plus);
   font-size: 12px;
   font-weight: bold;
   border-radius: 2px;
   box-shadow: rgba(31, 31, 31, 0.16) 0px 3px 6px, rgba(31, 31, 31, 0.23) 0px 3px 6px;
 }

 .mix_detail_view_hide
  {
      height: 0;
      visibility:collapse;
      transition: 0.2s ease-in-out;
      opacity: 0;
  }
 .mix_detail_view 
 {
   background: var(--background-dark_minus);
   border: 2px solid var(--background-light);
   padding: 10px;
   border-radius: 10px;
   transition: 0.2s ease-in-out;
   height: 250px;
   max-height: 250px;
   overflow: scroll;
   visibility: visible;
   opacity: 1;
   box-shadow: rgba(184, 184, 184, 0.25) 0px 14px 28px, rgba(184, 184, 184, 0.22) 0px 10px 10px;
 }

 .mix_low_level_detail li 
 {
  font-size: 13px;
   list-style: none;
   color: var(--text_light_white);
   border-left: 3px solid var(--primary_text_color);
    background: var(--background-dark);
    margin: 3px;
   padding: 8px;
   display: flex;
   font-weight: bold;
   justify-content: space-between;
   transition: 0.3s ease-in-out;
   cursor: pointer;
 }
 .mix_low_level_detail .detail_view_value 
 {
   color: var(--primary_text_color);
 }
 .mix_low_level_detail li:hover 
 {
   background: var(--background-light);
 }
 .mix_high_level_detail_header
 {
   padding: 10px;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .mix_high_level_detail_header h4 
  {
   color: var(--text_light_white);
   padding-bottom: 5px;
   border-bottom: 2px solid var(--background-dark);
 }
 .ing_list 
 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
 }
 .ing_list li 
 {
    width: 70%;
    font-size: 13px;
    border-left: 3px solid var(--grren-color);
    background: var(--background-dark);
    margin: 3px;
    list-style: none;
    color: var(--primary_text_color);
    padding: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .ing_list li:hover 
 {
   background: var(--background-light);
 }
 .mix_mat_list_wrapper 
 {
  display: flex;
  justify-content: space-between;
 }
 .mix_mat_name_right 
 {
  color: var(--primary_text_color);
  font-weight: bold;
 }
 .mix_capture_detail_qt 
 {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px;
 }
 .mix_capture_detail_qt button
  {
    cursor: pointer;
    background: var(--background-light);
    border: 2px solid var(--background-light);
    color: var(--primary_text_color);
    transition: 0.3s ease-in-out;
    padding: 5px;
    border-radius: 3px;
    font-size: 12pxs;
  }
  .mix_capture_detail_qt button:hover 
  {
    background: none;
  }
  .empty_saved_list 
  {
    padding: 10px;
    height: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .empty_saved_list li 
  {
    list-style: none;
    width: 100%;

    color: var(--background-dark);
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    user-select: none;

  }
  .empty_icon 
  {
    font-size: 2em !important;
  }

