

.po_mat_list_wrapper 
{
    padding: 10px;
    margin: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    border-radius: 10px;

    /* background: var(--background-dark_minus); */
    height: 10em;
    max-height: 15em;
    overflow-y: scroll;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
}
.po_mat_list 
{
    width: 90%;
    padding: 10px;
}
.input_list_wrapper 
{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.input_header_single_wrapper_st_req 
{
    width: 45%;
}


/* REMOVE BUTTON */
.remove_button_wrapper
{
  text-align: center;
  margin: 5px;
  padding: 3px;
  background: var(--red-color);
  border: 2px solid var(--red-color);
  color: var(--primary_text_color);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.remove_button_wrapper:hover 
{
  font-weight: bold;
  scale: 1.1;
  background: none;
  color: var(--red-color);
}
.remove_button_wrapper_fake 
{
   margin: 10px;
  padding: 6px;
}
.input_fild_shild 
{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
 }

 /* REMOVE BUTTON END */