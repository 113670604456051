.shiping_group_list_wrapper
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 90vh;
    overflow: scroll;
    margin: 10px;
}
.filter_workshop_by_name 
{
    background: var(--background-dark_minus);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-light);
    border-radius: 5px;
    padding: 3px 8px;
    width: 25em;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.workshop_filter_input_wrapper input 
{
    padding: 2px 4px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--primary_text_color);
}
.workshop_filter_input_wrapper span 
{
    color: var(--text_light_white);
}