.employee_master_card_main_wrapper 
{
    padding: 5px;
    margin: 5px;
    border: 2px solid var(--background-dark);
    width: 45%;
    height: 4.5em;
    border-radius: 5px;
    background: var(--background-dark_plus);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    animation: smoth_in 0.4s ease-in-out;
}
@keyframes smoth_in {
    0% {
        opacity: 0.0;
        filter: blur(10px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}
.employee_master_card_main_wrapper:hover 
{
    border-color: var(--background-light);
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.employee_master_card_header 
{
    display: flex;
    justify-content: left;
    align-items: top;
}
.employee_master_card_header_inner 
{
    padding: 2px;
}
.employee_master_card_header_image 
{
    padding: 5px;
    border: 5px solid var(--background-dark);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.employee_master_card_header_inner li
{
    font-weight: 600;
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    padding: 3px;
}
.employee_master_card_header_inner .employee_name_in_master_Card 
{
    list-style: none;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--primary_text_color);
    text-transform: capitalize;
}
.employee_workspace 
{
    color: var(--color-theme);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
}