.register_vender_card_main_wrapper 
{
    background: var(--alpha-background);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    transition: 1s ease-in-out;
    animation: smooth_in 0.7s ease-in-out;
}
@keyframes smooth_in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.register_vender_card_main_inner_wrapper 
{
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid var(--background-light);
    border-radius: 5px;
    position: fixed;
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.register_vender_header 
{
    display: flex;
    justify-content: space-between;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 4px;
    background: var(--text_light_white);
}
.register_vender_header h2 
{
    color: var(--background-dark_minus);
    font-size: 20px;
}
.register_vender_header button 
{
    background: none;
    border: none;
    color: var(--background-dark_minus);
    font-size: 20px;
    cursor: pointer;
}
.vender_reg_input_wrapper 
{
    width: 80%;
    display: flex;
    justify-content: center;
}
.vender_reg_main_wrapper 
{
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.form_action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    padding: 10px;
    background: var(--background-dark);
    border: 1px solid var(--background-light);
}