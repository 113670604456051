.budget_allocation_history_local_view_master_wrapper 
{
    max-width: 25em;
    margin: 5px;
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 10px;
    background: var(--background-dark_minus);
}
.budget_allocation_history_local_view_header 
{
    background: var(--background-light);
    border-top: 2px solid var(--primary_text_color);
    padding: 6px 10px;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.budget_allocation_history_local_view_header h2
{
    font-size: var(--default-font-scale-mid);
    color: var(--primary_text_color);
    letter-spacing: 2px;
}
.budget_allocation_history_local_view_body_master_wrapper
 {
    padding: 5px 10px;
    height: 12em;
    overflow-y: scroll;
 }