.single_warehouse_entry_view_master_wrapper 
{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items:center;
    align-content: center;

    padding: 5px 10px;

    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    
    
    margin: 2px;
    border-radius: 5px;
    font-weight: bold;

    transition: 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.single_warehouse_entry_view_master_wrapper li 
{
    font-size: var(--default-font-scale-min);
    list-style: none;
    color: var(--text_light_white);
    flex: 1;
}
.filter_item_list 
{
    flex: 2 !important;
}
.inventory_critical_actions 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.single_warehouse_entry_view_hidden_until 
{
    position: absolute;
    right: 0;
    border: 1px solid red;
    z-index: 100;
    padding: 5px 10px;
    background: var(--background-dark_minus);
    border: 2px solid var(--background-light);
    margin: 5px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
    margin-top: 6em;
}