.letter_head_main_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
    z-index: -1; 
}
@media print {
    .print_only {
      display: block;
    }
  }
@media screen {
    .print_only {
        display: none;
    }
}