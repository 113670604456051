.navbar_main_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    flex: 1;
}
.right_side_cont 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex: 1;
    width: 50%;
}
.tool_quick 
{
    z-index: 999;
    font-size: var(--default-font-scale-min) !important;
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}
.navbar_main_wrapper 
{ 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3em;
    /* background: linear-gradient(-65deg, var(--background-dark), var(--background-dark_plus), var(--background-dark), var(--background-dark_plus)); */
	/* background-size: 400% 400%; */
	/* animation: gradient 14s ease infinite; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    z-index: 10;

    background: rgba(50, 50, 50, 0.2); 
    backdrop-filter: blur(7px); 
    -webkit-backdrop-filter: blur(7px); 
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.logo h2, .bar i
{
    color: var(--text_light_white);
    padding: 5px;
}
.logo, .bar 
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text_light_white);
    text-align: center;
}
.workspace_selection_wrapper 
{
    min-width: 10em;
}
.workspace_name_wrapper 
{
    margin: 3px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--default_gradient);
    border-radius: 1px;
    cursor: pointer;
    padding: 6px;
    transition: 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.workspace_name
{
    list-style: none;
    color: var(--background-dark_plus);
    font-weight: bold;
    font-size: var(--default-font-scale-mid);
}
.logo_menu_icon 
{
    width: 30%;
    padding: 2px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.noti_seeting 
{
    padding: 5px;
}
.noti_wrapper 
{
    position: relative;
}
.noti_wrapper button
{
    background: none;
    border: none;
    color: var(--primary_text_color);
    font-size: 1.1em;
    cursor: pointer;
}
.noti_wrapper .notify_dot 
{
    position: absolute;
    width: 0.3em;
    height: 0.3em;
    border: 1px solid var(--primary_text_color);
    background: var(--primary_text_color);
    border-radius: 50%;
    top: 0;
    right: 0.4em;
    animation: pulse 0.9s infinite, ease-in 0.6s;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--primary_text_color);
	}
	70% {
		box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.option_but, .bar
{
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text_light_white);
    text-align: center;
    border: 2px solid var(--background-light);
    background-color: var(--background-dark);
    width: 25px; 
    height: 25px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transition: 0.5s ease-in-out;
}
.noti_seeting i 
{
    font-size: var(--default-font-scale-mid);
}
.bar i 
{
    transition: 0.5s ease-in-out;
}
.bar:hover, .option_but:hover
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
}
.bar:hover i
{
    color: var(--color-theme);
}

.user_info 
{
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    padding: 5px;
    gap: 5px;
    margin-right: 1em;
}
.user_pic img
{
    width: 2em;
    height: 2em;
    border-radius: 50%;
}
.user_name li 
{
    padding: 0px;
    list-style: none;
    font-size: var(--default-font-scale-mid);
    margin: 3px;
}
.option_expose span
{
    color: var(--primary_text_color);
}
.user_name .name
{
    color: var(--primary_text_color);
    font-weight: 600;
}
.user_name .job
{
    color: var(--text_light_white);
    font-weight: 300;
}
.logo_img img 
{
    width: 2em;
    height: 2em;
}
.logo_txt 
{
    padding: 3px 5px;
}
.logo_txt h3 
{
    color: var(--primary_text_color);
    font-weight: bold;
    font-size: 0.9em;
    margin-top: -5px;
}
.logo_txt span 
{
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    font-weight: lighter;
}
.logo_txt li 
{
    font-size: var(--default-font-scale-min);
    list-style: none;
    font-weight: bold;
    color: var(--primary_text_color);
    padding: 1px 6px;
    border-bottom: 2px solid var(--color-theme);
    text-align: center;
}

.account_log_in_info 
{
    z-index: 99;
    margin: 5px;
    width: 13em;

    position: absolute;
    right: 3em;
    top: 2.5em;
    background: var(--background-dark_plus);
    border: 2px solid var(--background-dark_minus);
    border-radius: 10px;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.4s ease-in-out;
}
.account_log_in_info_hide 
{
    margin: 5px;
    width: 13em;
    opacity: 0;

    position: absolute;
    right: 3em;
    top: 2.5em;
    background: var(--background-dark_plus);
    border: 2px solid var(--background-dark_minus);
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.4s ease-in-out;
}
.user_detail_rep 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
}
.user_detail_rep div 
{
    margin: 1px 4px;
}
.account_log_in_info button, .account_log_in_info_hide button 
{
    position: relative;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    cursor: pointer;

    background: none;
    border: none;
    font-size: var(--default-font-scale-mid);
    padding: 5px 10px;

    color: var(--text_light_white);
    transition: 0.3s ease-in-out;
}
.account_log_in_info button:hover 
{
    color: var(--primary_text_color);
}
.account_log_in_info button span, .account_log_in_info_hide button span
{
    margin: 1px 3px;
}

.usr_option_main_wrapper 
{
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_plus);
    overflow-y: scroll;
    width: 25em;
    max-height: 15em;

    position: absolute;
    right: 15em;
    top: 3.5em;    
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

    transition: 0.3s ease-in-out;
}
.usr_option_main_wrapper_hide 
{
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark_plus);
    overflow-y: scroll;
    width: 25em;
    max-height: 15em;

    position: absolute;
    right: 15em;
    top: 10em;

    opacity: 0.0;
    visibility: hidden;

    transition: 0.3s ease-in-out;
}


.usr_option_inner_wrapper 
{
    margin: 10px;
    padding: 5px;
    z-index: 2;
}
.usr_option_inner_wrapper li 
{
    position: relative;
    list-style: none;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    margin: 3px;
    padding: 7px 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.usr_option_inner_wrapper li:hover 
{
    scale: 1.06;
}
.option_val 
{
    font-weight: bold;
}
.usr_option_inner_wrapper_access_stl::after 
{
    content: '';
    background: var(--background-dark_minus);
    position: absolute;
    width: 100%;
    border-top-left-radius: 200px;
    border-bottom-right-radius: 200px;
    height: 100%;
    transition: 0.5s ease-in-out;
}
.usr_option_main_wrapper:hover .usr_option_inner_wrapper_access_stl::after {
    border-radius: 1px;
    border-top-left-radius: 200px;
}

.usr_option_inner_wrapper_access 
{
    position: relative;
    padding: 20px;
    margin: 10px;

    border-bottom: 2px solid var(--background-light);
}
.usr_option_inner_wrapper_access  li 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    border-radius: 5px;
    
    background: var(--background-dark_plus);
    padding: 5px 10px;
    border: 2px solid var(--background-light);

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.active_workspace_name 
{
    padding: 1px 5px;
}

.color_scheme_wrapper 
{
    display: flex;
    justify-content: space-between;
}
.color_scheme_wrapper div 
{
    width: 45%;
    padding: 10px;
}
.color_prview 
{
    width: 1em;
    height: 1em;
    border: 2px solid var(--background-light);
    border-radius: 50%;
}
.color_scheme_wrapper div li 
{
    padding: 3px 10px;
}