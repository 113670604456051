

.form_wrapper_std_user_form 
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}
.input_wrap_pass 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input_wrap_pass button 
{
    font-size: var(--default-font-scale-min);
    background: var(--background-dark);
    border: 2px solid var(--background-light);
    color: var(--text_light_white);
    cursor: pointer;
    border-radius: 3px;
    font-weight: bold;
    padding: 5px 10px;
    transition: 0.3s ease-in-out;
}
.input_wrap_pass button:hover 
{
    color: var(--color-theme);
    border-color: var(--color-theme);
}

.input_wrap_email 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bad_email 
{
    color: var(--red-color);
    padding: 3px;
    font-size: 0.8em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 0.9;
}
.good_email 
{
    color: var(--color-theme);
    padding: 3px;
    font-size: 0.8em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 0.9;
}
.email_loading 
{
    color: var(--inventory-type-color);
    padding: 3px;
    font-size: 0.8em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 0.9;
}
.input_separate_haf 
{
    width: 45%;
}