.archive_branch_group_main_wrapper
{
    border: 1px solid var(--background-light);
    background: var(--background-light);
    margin: 5px;
    border-radius: 5px;

    max-width: 35em;
    width: 30em;
    min-width: 25em;

    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    animation: size_up_wrap 0.5s ease-in-out;
}
@keyframes size_up_wrap {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}
.archive_branch_group_header
{
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    padding: 3px;
}
.archive_branch_group_header h3 
{
    font-size: 0.9em;
    color: var(--color-theme);
    display: inline-flex;
}
.archive_branch_group_header h3 span 
{
    margin: 1px 3px;
}
.distro_common_text 
{
    font-size: var(--default-font-scale-min);
    text-transform: uppercase;
    font-weight: bolder !important;
    text-decoration: underline;
}
.distro_icon_header 
{
    font-size: 1.3em;
}
.data_list_wrapper 
{
    padding: 10px;
    
    height: 10em;
    max-height: 10em;
    overflow: scroll;
}

.empty_his
{
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    height: 80%;
}
.empty_his .empty_his_icon 
{
    width: 100%;
    text-align: center;
    color: var(--text_light_white);
    font-size: 1.5em;
}
.empty_his .empty_hist_text 
{
    width: 100%;
    text-align: center;
    color: var(--text_light_white);
}
.empty_his .empty_hist_text h2 
{
    font-size: 0.8em;
    text-transform: uppercase;
}

.data_list_wrapper li 
{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-min);

    border: 1px solid var(--background-dark);
    background: var(--background-dark_plus);
    padding: 6px;
    color: var(--text_light_white);
    margin: 3px;

    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 7px 14px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
}
.remain_time_to_purge 
{
    color: var(--color-theme);
    font-weight: bold;
    border: 2px solid var(--color-theme);
    padding: 2px 8px;
}