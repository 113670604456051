.chefs_production_stack_card 
{
    user-select: none;
    position: relative;
    width: 15em;

    height: 13em;
    max-height: 15em;
    margin: 5px 8px;
    padding: 5px;

    border-radius: 1em;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transform:
    perspective(200px)
    rotateX(0deg);

    transition: 0.3s ease-in-out;
}
.chefs_production_stack_card_started 
{
    background: var(--background-light);
    border-color: var(--text_light_white);
}
.chefs_production_stack_card:hover 
{
    transform:
    perspective(200px)
    rotateX(5deg);
    background: var(--background-light);
    /* border-color: var(--color-theme); */
    box-shadow: var(--color-theme) 0px 25px 20px -20px;
    /* box-shadow: var(--color-theme) 0px 1px 2px 0px, var(--color-theme) 0px 2px 8px 0px; */
}
.chef_prod_name 
{
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
}
.card_name_more_right_special_info h3 
{
    font-size: 1.2em !important;
    color: var(--color-theme) !important;
}
.card_name_more_right_special_info h3 span 
{
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.chef_prod_name h3 
{
    color: var(--text_light_white);
    font-size: 1.3em;
}
.chef_prod_name li
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
}
.prod_detail 
{
 padding: 10px 5px;   
}
.expenct_amount 
{
    font-size: 1.5em;
}
.mod_slice_wrap 
{
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    padding: 3px;
    margin: 0px 2px;
    border-radius: 2em;

    display: inline-flex;
}
.card_right_data 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.card_right_data li 
{
    width: 100%;
}
.prod_detail li, .card_right_data li  
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    padding: 1px 6px;
}
.expectation_tag 
{
    color: var(--color-theme) !important;
    font-weight: bolder !important;
}
.action_button
 {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
    align-items: center;
    align-content: center;
 }
 .action_button button 
 {
    flex: 1;
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark_minus);
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-min);
    padding: 5px 10px;
    border-radius: 1em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
.button_action_report_damage:hover
{
    color: var(--primary_text_color) !important;
    background: var(--red-color) !important;
    border-color: var(--red-color) !important;
}

 .button_action_started 
 {
    background: var(--color-theme) !important;
    border-color: var(--color-theme) !important;
    color: var(--primary_text_color) !important;
 }
 .action_button button:hover 
 {
    background: var(--color-theme);
    border-color: var(--color-theme);
    color: var(--primary_text_color);
 }
.decrease_slice_count
 {
    color: var(--red-color);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    border-radius: 50%;
    margin: 2px;
 }
 .increase_slice_count 
 {
    color: var(--color-theme);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    border-radius: 50%;
    margin: 2px;
 }