.hr_pass_bunder_innter_Wreapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.empty_data_hr_data_feed_ready 
{
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    color: var(--text_light_white);
    padding: 20px;
}
.empty_data_hr_data_feed_ready li 
{
    width: 100%;
    text-align: center;
    color: var(--text_light_white);
    list-style: none;
    font-weight: 300;
    font-size: 0.8em;
    margin: 2px;
    text-transform: uppercase;

}
.empty_data_hr_data_feed_ready span 
{
    font-size: 2em;
}