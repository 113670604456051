.input_list_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    animation: slid_in 0.2s ease-in-out;
 }
  @keyframes slid_in {
   0% {
      margin-left: -150%;
      opacity: 0;
   }
   100% {
      margin-left: 0;
      opacity: 1;
   }
 }
 .input_list_wrapper 
 {

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
 }
 .input_list_inner_wrapper 
 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
 }
 .input_list_wrapper .input_single_wrap 
 {
    width: 20%;
    flex-wrap: 1;
 }
 .input_list_wrapper input
 {
   font-size: 10px;
 }
 .input_fold_wrapper 
 {
   width: 33%;
 }
.input_single_wrap_validator 
{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 10%;
}
 .validator_item_under
 {
  width: 100%;
  color: var(--background-dark_minus);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border: 1px solid var(--red-color);
  background-color: var(--red-color);
  border-top-left-radius: 10px;
  /* animation: blinkBackground 2s infinite; */
 }
 @keyframes blinkBackground {
  0% { background-color: none; }
  50% { background-color: var(--red-color); color: var(--background-dark_minus); } /* You can change this color */
  100% { background-color: none; }
}
 .validator_item_pend
 {
  color: var(--color-yellow);
  padding: 5px 10px;
 }
 .validator_item_surp
 {
  width: 100%;
  color: var(--background-dark_minus);
  background: var(--color-theme);
  border: 1px solid var(--color-theme);
  border-top-left-radius: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 3px;
 }
 .icon_budget 
 {
  font-size: var(--default-font-scale-mid);
 }
 .text_budget 
 {
  font-size: var(--default-font-scale-min);
  padding: 5px;
  text-wrap: nowrap;
 }