/* Stock Manager Card  */
.stock_manager_card_main_wrapper 
{
    margin: 5px;
    width: 15em;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 5px;
    border-radius: 2px;
    transition: 0.4s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    opacity: 0;
    transform: translateY(-100%);
    animation: slide_in 0.3s forwards;
    animation-delay: var(--animation-delay);
}

@keyframes slide_in {
    0% {
        transform: translateY(-100%);
        /* opacity: 0; */
        filter: blur(20px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
        filter: blur(0px);
    }
}
.stock_manager_card_main_wrapper:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.stock_manager_card_header 
{
    padding: 1px 15px;
    color: var(--text_light_white);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
}
.stock_manager_card_name h2 
{
    font-size: 0.5em;
    color: var(--color-theme);
}
.stock_manager_card_name h1
{
    font-size: 0.8em;
    color: var(--text_light_white);;
}
.stock_manager_card_stock_amount 
{
    font-size: 0.8em;
    color: var(--text_light_white);
    text-decoration: underline;
    font-weight: bold;
}
.stock_manager_card_stock_info_bar 
{
    padding: 10px 5px;
}
.stock_manager_card_stock_info_bar li 
{
    padding: 5px;
    list-style: none;
    font-weight: bold;
    font-size: 0.8em;
    color: var(--color-theme);
}
.stock_manager_card_grp_chid 
{
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;

   background: linear-gradient(to right, var(--color-theme), rgb(5, 117, 230));
   height: 3px;
   border: 1px solid linear-gradient(to right, var(--color-theme), rgb(5, 117, 230));
   border-radius: 20px;
   transition: 1.0s ease-in-out;
}
.prog_tip
 {
    background: none !important;
    font-size: 0.4em;
    margin-top: -2px;
    margin-right: -7px;
    color: var(--text_light_white);
    border-radius: 50%;

    box-shadow: rgba(255, 255, 255, 0.38) 12px 4px 16px 0px, rgba(255, 255, 255, 0.6) -12px -4px 16px 0px;
 }
.stock_manager_card_grp
 {
   background: var(--background-dark);
   height: 2px;
   border: 1px solid var(--background-dark);
   border-radius: 3px;
   transition: 1s ease-in-out;
   border-radius: 20px;
 }
.blip_mg
 {
    padding: 0 !important;
    font-size: var(--default-font-scale-mid) !important;
    color: var(--color-yellow) !important;
    font-weight: lighter;
    animation: blip 2s infinite;
 }
 @keyframes blip {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
 }
  .tool_quick 
{
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.stock_warehouse_swicth_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.stock_warehouse_swicth_wrapper button 
{
    font-size: 1.1em;
    background: none;
    border: none;
    color: var(--text_light_white);
    cursor: pointer;
    transform: scale(1.0);

    transition: 0.3s ease-in-out;
}
.stock_warehouse_swicth_wrapper button:hover 
{
    transform: scale(1.1);
}