.data_body 
{
    max-height: 15em;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.warehouse_wrapper
{
    flex: 1;
    position: relative;
   padding: 5px;
    margin: 5px;
    border-radius: 5px;
    min-width: 15em;
    width: 45%;
    height: 8em;
    max-height: 8em;
    overflow-y: scroll;
    
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
}
.warehouse_list_wrapper 
{
    position: relative;
    padding: 5px;
}
.warehouse_list_wrapper li
{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    background: var(--background-light);
    border: 2px solid var(--background-light);

    font-weight: bold;
    padding: 2px 4px;
    border-radius: 5px;
    
    position: relative;
    margin: 3px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.warehouse_list_wrapper li .warehouse_name 
{
    display: inline-flex;
}
.warehouse_reg_wrap 
{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 0.8em;
}
.warehouse_list_wrapper li:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.super_img 
{
    padding: 8px;
}
.super_img img
{
    width: 5em;
    height: 5em;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.head_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.pitty_cash_ana 
{
    margin: 5px;
    padding: 5px;
    border-radius: 1px;
    width: 200px;
    height: 100px;
    border-top: 3px solid var(--main-color);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.info_text 
{
    padding: 5px;
}
.info_text h2 
{
    font-size: var(--default-font-scale-lrg);
    color: var(--primary_text_color);
    text-transform: uppercase;
}
.loader_wrapper 
{
    padding: 10px;
}
.figuers li 
{
    list-style: none;
    font-size: var(--default-font-scale-min);
    padding: 3px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--primary_text_color);
}
.outer_load 
{
    margin: 10px 0;
    width: 100%;
    height: 5px;
    background: var(--background-light);
}
.inner_load 
{
    width: 70%;
    height: 6px;
    background: linear-gradient(to right, var(--main-color), var(--grren-color));
}
.figuers_cash 
{
    color: var(--main-color);
    font-weight: bold;
    text-decoration: underline;
}
.super_select_action_wrapper 
{
    text-align: center;
}
.super_select_action_wrapper button 
{
    text-transform: uppercase;
    width: 10em;
    border-radius: 1px;
    font-size: var(--default-font-scale-min);
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    color: var(--main-color);
    font-weight: bold;
    padding: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.super_select_action_wrapper button:hover 
{
    font-size: 0.65em;
}
.warehouse_type 
{
    text-align: center;
    width: 6em;
    font-size: 0.8em;
    border: 1px solid var(--text_light_white);
    padding: 1px 3px;
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    border-radius: 10px;
}
.branch_action_menu_wrapper 
{
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 5px 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 3px;
}
.warehouse_list_wrapper .slack_logo
{
    width: 100%;
    text-align: center;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
    font-weight: bold;
    display: inline-flex;
}
.slack_logo span 
{
    padding: 3px 10px;
}
.map_layer_swicth 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}