.product_card_main_wrapper 
{
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin: 5px 10px;

    width: 15em;
    height: 12.5em;
    max-height: 12.5em;
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.product_card_main_wrapper_invalid 
{
    filter:  brightness(0.5);
    transition: 0.3s ease-in-out;
}
.product_card_main_wrapper_invalid:hover 
{
    filter: brightness(1);
}
.product_card_main_wrapper:hover 
{
    box-shadow: var(--color-theme) 0px 25px 20px -20px;
    border-bottom: 2px solid var(--color-theme);
}
.disbaled_prod li 
{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    background: var(--red-color);
    padding: 5px 10px;
    font-size: var(--default-font-scale-mid);
    text-transform: uppercase;
    color: var(--primary_text_color);
}
.disbaled_prod h1 
{
    text-align: center;
    color: var(--red-color);
    padding: 10px;
}
.disbaled_prod_hide 
{
    display: none;
}
.image_area 
{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 15em;
    height: 6em;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.content_area 
{
    background: rgba(255, 255, 255, 0.2); 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 

    background: var(--background-dark_plus);

    /* display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center; */


    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.content_card_prod_name 
{
    margin: 5px;
}
.content_card_prod_name h3 
{
    font-size: 0.8em;
    color: var(--primary_text_color);
    font-weight: bold;
    padding: 5px;
    border-bottom: 2px solid var(--background-dark);

}
.content_card_prod_name h3 span 
{
    font-size: var(--default-font-scale-mid);
    font-weight: bolder;
    border-radius: 2px;

    background: var(--color-theme);
    color: var(--background-dark_plus);
    padding: 2.5px 5px;
}
.content_card_prod_name li 
{
    list-style: none;
    color: var(--color-theme);
    font-weight: bold;
    font-size: 0.5em;
    margin: 2px;

    margin-left: 10px;
    text-transform: uppercase;
}
.cake_info_tag 
{
    font-weight: 300;
}
.action_card_button_wrapper form
{
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
}
.action_card_button_wrapper button 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 2.5em;
    height: 2.5em;
    background: none;
    border: 1px solid var(--color-theme);
    border-radius: 50%;
    color: var(--color-theme);
    font-size: var(--default-font-scale-mid);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    transition: 0.3s ease-in-out;
}
.action_card_button_wrapper button:hover 
{
    color: var(--background-dark_plus);
    border-color: var(--color-theme);
    background: var(--color-theme);
}
.action_card_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;

    background: var(--background-dark_plus);
}
.action_card_button_wrapper input  
{
    width: 10em;
    height: 1em;
    padding: 5px 10px;
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark);
    color: var(--text_light_white);

    margin: 5px;
    border-radius: 3px;
    text-align: center;

    font-size: var(--default-font-scale-min);
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

}
.action_card_button_wrapper input[type="number"]::-webkit-outer-spin-button,
.action_card_button_wrapper input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.action_card_button_wrapper input[type="number"] {
    -moz-appearance: textfield;
}