.edible_in_main_wrapper
{
    background-color: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
}
.inv_type_wrapper 
{
    padding: 20px;
    display: flex;
    justify-content: center;
}
.list_item_product_class_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}
.list_item_product_class 
{
    position: relative;
    border: 1px solid var(--background-dark);
    border-top: 2px solid var(--color-theme);
    border-radius: 4px;
    background: var(--background-dark_minus);
    padding: 20px;
    width: 80%;
    margin: 10px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.list_item_product_class_list_wrapper_header 
{
    padding: 5px;
    border-bottom: 2px solid var(--background-light);
    margin: 10px;
    text-align: right;
}
.list_item_product_class_list_wrapper_header h2 
{
    color: var(--color-theme);
    font-size: 1.2em;
    font-weight: 200;
}
.list_item_product_class_list_wrapper_header h2 span 
{
    font-weight: bold;
}
.list_item_product_class_list_wrapper 
{
    position: relative;
    /* border: 1px solid red;
    background: var(--background-dark); */
    height: 15em;
    max-height: 15em;

    margin-bottom: 3em;
    overflow-y: scroll;
}
.list_item_product_class_list_wrapper li span
{
    width: 20%;
    margin: 0px 5px;
}
.list_item_product_class_list_wrapper li
{
    cursor: pointer;
}
.list_item_product_class li 
{
    list-style: none;
    font-size: var(--default-font-scale-min);

    margin: 4px;
    background: var(--background-dark_plus);
    border: 2px solid var(--background-dark);
    padding: 5px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    color: var(--primary_text_color);

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    transition: 0.3s ease-in-out;
}
.list_item_product_class li:hover 
{
    background: var(--background-dark);
    border-bottom: 2px solid var(--text_light_white);
}

.list_item_product_class_purge 
{
    width: 5em !important;
    text-align: center;
    border: 1px solid var(--red-color);
    background: var(--red-color);
    border-radius: 2px;
    padding: 3px 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.list_item_product_class_more 
{
    width: 6em !important;
    border: 1px solid var(--text_light_white);
    padding: 2px 5px;
    border-radius: 2px;
    color: var(--background-dark_plus);
    background: var(--text_light_white);
    cursor: pointer;
    text-align: right;
    text-transform: capitalize;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.list_item_product_class_detail 
{
    color: var(--text_light_white);
}

.action_button_wrapper_product_class 
{
    background: var(--background-dark_minus);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    margin: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    border-top: 2px solid var(--background-dark);
    width: 94%;
}
.action_button_wrapper_product_class button 
{
    padding: 5px 20px;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    margin: 3px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}
.action_button_wrapper_product_class button:hover 
{
    color: var(--background-dark_plus);
    background: var(--text_light_white);
}
.action_button_wrapper_product_class_filter_name_edibale
{
    width: 12em;
    padding: 5px 10px;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    border-bottom: 2px solid var(--background-dark);
    margin: 3px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;;
}
.action_button_wrapper_product_class_filter_name_edibale:hover 
{
    border-bottom: 2px solid var(--text_light_white);
}
.action_button_wrapper_product_class_filter_edibale select
{
    margin: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-dark_plus);
    border: 1px solid var(--background-dark);
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    user-select: none;
    cursor: pointer;
}