.chef_card_view_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    border-radius: 10px;
    width: 20em;
    height: 4em;
    padding: 5px 10px;
    margin: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    cursor: pointer;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    user-select: none;
}
.chef_card_view_wrapper:hover 
{
    border-bottom: 2px solid var(--color-theme);
}
.chaef_image_wrapper img
{
    width: 3em;
    height: 3em;
    border-radius: 2em;
}
.chef_name_and_info 
{
    padding: 5px;
}
.chef_name_and_info li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    padding: 2px 10px;
}
.chef_name 
{
    color: var(--primary_text_color) !important;
}