.order_group_wrapper_main
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    padding: 10px;

    flex-wrap: wrap;
    flex: 1;
}
.request_queue_halo h2
{
    color: var(--text_light_white);
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    font-size: var(--default-font-scale-lrg);
}
.request_queue_halo h1 
{
    color: var(--text_light_white);
    font-size: 3em;
    padding: 5px;
    text-align: center;
}