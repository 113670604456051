.payroll_config_wrapper 
{
    height: 19em;
    overflow: scroll;
}
.part_of_option 
{
    margin: 10px 2px;
}
.part_of_option ul
{
    padding: 2px 10px;
    background: var(--background-light);
    border: 1px solid var(--background-light);
    border-radius: 1px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.setting_items_config  
{
    margin-left: 3em;
}
.setting_items_config input[type="number"]::-webkit-outer-spin-button,
.setting_items_config input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* .setting_items_config input[type="number"] {
    -moz-appearance: textfield;
} */
.setting_items_config li
{
    list-style: none;
    color: var(--text_light_white);
    padding: 2px 10px;
    background: var(--background-dark);
    border: 1px solid var(--background-light);

    font-size: var(--default-font-scale-min);
    font-weight: bold;
    border-radius: 2px;
    margin: 3px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.setting_items_config li:hover 
{
    background: var(--background-dark_minus);
}
.setting_items_config .input_value
 {
    text-align: center;
    width: 4em;
    padding: 2px 4px;
    color: var(--text_light_white);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-lrg);
    font-weight: bold;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }
 .setting_items_config select 
 {
    text-align: center;
    padding: 2px 8px;
    color: var(--text_light_white);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }