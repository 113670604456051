.paper_footer_note_wrapper 
{
    border: 1px solid var(--text_light_white);
    background: var(--print_paper_background);
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.owner_verify  li 
{
    font-size: var(--default-font-scale-mid);
    margin: 3px;
    font-weight: bold;
    list-style: none;
}
.owner_verify 
{
    padding: 5px;
}
.owner_name 
{
    font-size: 0.9em;
}
.user_sign 
{
    padding: 20px 0px;
}
.owner_notify li
{
    list-style: none;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    text-transform: uppercase;
}