:root {
  --secondary-color: #363540;
  --icon-background-color: #505458;
  
  --menu-background-color: #222831;
  --menu-button-hover-color: #ff5722;
  
  --user-info-text-color: #009975;
  
  --nav-background-color: #26282b;
  
  --user-account-card-remove-color: #ef255f;
  --user-account-card-text-color: #5f85db;
  --user-account-card-background: #26282b;
  --user-account-card-button-background: #252525;
  
  --unit-of-measurment-color: #0099cc;
  --inventory-brand-color: #00818a;
  --inventory-type-color: #2185d5;
  
  --alpha-background: #00000042;
  --alpha-background_strong: #000000bb;
  --red-color-alpha: #cc000077;

  --default_gradient: linear-gradient(to right, rgb(17, 153, 142), rgb(56, 239, 125));
  --main-color: #2fba60;
  --body-background: #414141;
  --tool-bar-background: #252525;
  
  --print_paper_background: #dee1ec;
  --print_paper_background_dark_plus: #cbccd1;
  --grren-color: #2a9669;
  --red-color: #cc0000;
  --dimm_white: #0389ff;
  --color-theme: #2fba60;
  --color-yellow: #eac100;
  
  --primary_text_color: #f7f7f7;
  --text_light_white: #9f9fa3;
  --text_light_white_fade: #535353; 

  --background-dark_plus: #141414;
  --background-dark_minus: #181818;
  --background-dark: #252525;
  --background-light: #303030;

  /*
  --primary_text_color: #e0e0e0;         
  --text_light_white: #b0b0b0;           
  
  --background-dark_plus: #000000;      
  --background-dark_minus: #0d0d0d;     
  --background-dark: #1a1a1a;          
  --background-light: #262626;            
   */

  --day_stock_card_theme: #30c39e;
  --day_stock_card_theme_light:  #0b1613;

  --night_stock_card_theme: #f85a40;
  --night_stock_card_theme_light: #110705;
  
  --default_stock_card: #00a98f;
  --default_stock_card_light: #00a98f70;


  --default-font-scale-min: 0.6em;
  --default-font-scale-mid: 0.8em;
  --default-font-scale-lrg: 1em;
}

@font-face 
{
  font-family: 'BebasNeue-Regular';
  src: local('BebasNeue-Regular'), url('../res/fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'AnekLatin-VariableFont_wdth,wght';
  src: url('../res/fonts/Anek_Latin/AnekLatin-VariableFont_wdth\,wght.ttf');
}
@font-face {
  font-family: 'SFProText-Regular';
  src: url('../res/fonts/San_Francisco_Pro_Text/SFProText-Regular.ttf');
}
@font-face {
  font-family: 'SourceCodePro-Regular';
  src: url('../res/fonts/Source_Code_Pro/static/SourceCodePro-Medium.ttf');
}
@font-face {
  font-family: 'SpaceGrotesk-Regular';
  src: url('../res/fonts/Source_Code_Pro,Space_Grotesk/Space_Grotesk/static/SpaceGrotesk-Regular.ttf');
}
@font-face {
  font-family: 'Inter';
  src: url('../res/fonts/Inter/static/Inter_18pt-Regular.ttf');
}
* {
  margin: 0;
  /* font-family: 'AnekLatin-VariableFont_wdth,wght', cursive; */
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* word-spacing: 3px; */
  /* letter-spacing: 1.5px; */
  outline: none;
}
/* Track (the area around the scrollbar) */
::-webkit-scrollbar {
  width: 3px; 
  height: 3px;
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: var(--background-light); /* Background color of the handle */
  border-radius: 20px; /* Rounded corners for the handle */
}
/* @media print {
  @page {
    margin: 20mm 0 0 0; 
  }

} */
input[type="checkbox"] {
    position: relative;
    display: flex;
    align-items: center;
    width: 1.3em;
    height: 1.3em;
    color: var(--background-dark);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 2em;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 805ms cubic-bezier(0.1, 0.1, 0.25, 1);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
input[type="checkbox"]::before
{
  position: absolute;
  content: '';
  display: block;
  top: 1px;
  left: 5.5px;
  width: 3px;
  height: 10px;
  border-style: solid;
  border-color: var(--background-dark_plus);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
input[type="checkbox"]:checked 
{
  color: var(--background-dark_plus);
  border-color: var(--color-theme);
  background: var(--color-theme);
}
input[type="checkbox"]:checked:before 
{
  opacity: 1;
}
input 
{
  background: none;
  border: none;
  color: var(--background-dark_plus);
  font-weight: bold;
}