.input_list_wrapper
 {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    animation: slid_in 0.3s ease-in-out;
 }
 .input_list_main 
 {
   width: 50%;
 }
 .input_list_delete 
 {
   animation: slid_in 0.5s ease-in-out;
 }

 @keyframes slid_in {
   0% {
      margin-left: -150%;
      opacity: 0;
   }
   100% {
      margin-left: 0;
      opacity: 1;
   }
 }
 @keyframes slid_out {
   0% {
      margin-left: 0;
      opacity: 1;
   }
   100% {
      margin-left: 150%;
      opacity: 0;
   }
 }