.brach_card_main_wrapper 
{
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    width: 15em;
    min-width: 15em;
    margin: 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.brach_card_main_wrapper:hover 
{
    border-color: var(--text_light_white);
}
.workspace_icon_preview 
{
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: var(--background-dark_minus);
    border-radius: 10px;
}
.workspace_icon_preview li 
{
    list-style: none;
    font-size: 3em;
    color: var(--text_light_white);
}
.card_header_wrapper 
{
    padding: 3px 10px;
}
.card_header_wrapper h1 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
    text-align: center;
    border-bottom: 1px solid var(--background-light);
}
.cadr_body_wrapper 
{
    padding: 5px 10px;
}
.cadr_body_wrapper li 
{
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--background-dark_plus);
    background: var(--text_light_white);
    border-radius: 5px;
    padding: 3px 6px;
    font-weight: bold;
    margin: 3px;
}
.suspeded_branch_note 
{
    background: var(--red-color) !important;
    color: var(--primary_text_color) !important;
}