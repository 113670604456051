.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}
.orderbale_state 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 3px 5px;
    margin: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    user-select: none;
}
.master_product_type_inner_avail_workspace_list 
{
    margin: 10px;
    width: 40%;
}
.master_prod_avail_list 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.master_prod_avail_branch 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 4px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 1px 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.workspace_name_tick 
{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
}
.master_product_type_inner_avail_workspace_list_header 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px;

    border: 2px solid var(--background-dark);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 10px;
}
.master_product_type_inner_avail_workspace_list_header button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 0.8em;
    float: top;
    &:hover 
    {
        color: var(--color-theme);
    }
}
.master_product_type_inner_avail_workspace_list_header h1 
{
    font-size: 0.8em;
    text-transform: capitalize;
    color: var(--text_light_white);
}
.data_per_workspace 
{
   width: 50%;
}
.data_per_workspace input, .data_per_workspace input
{
   width: 80%;
   padding: 5px 10px;
   border: 1px solid var(--background-dark);
   border-bottom: 2px solid var(--background-dark);
   background: var(--background-dark_plus);
   color: var(--color-theme);
   border-radius: 1px;
   font-size: 0.8em;
   font-weight: bold;
   box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
   transition: 0.3s ease-in-out;
}
.data_per_workspace input:hover 
{
    background: var(--background-dark_minus);
    border-bottom: 2px solid var(--color-theme);
}
.list_data_items
 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    padding: 10px;
 }
.data_list_inner_wrapper 
{
    width: 80%;
    height: 20em;
    max-height: 20em;
    overflow: scroll;

    background: var(--background-dark_minus);
    padding: 10px;
    border: 2px solid var(--background-light);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.data_list_inner_wrapper li 
{
    background: var(--background-dark_plus);
    margin: 3px;
    padding: 3px 6px;
    border: 2px solid var(--background-light);
    border-radius: 5px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    text-align: left;

    display: flex;
    justify-content: space-between !important;
    align-items: center;
    align-content: center;
    font-weight: bold;

    opacity: 0;
    animation: slide_in 0.4s forwards;
    animation-delay: var(--animation-delay);
}
.data_list_inner_wrapper li span 
{
    flex: 1;
}

@keyframes slide_in {
    0% {
        scale: 0;
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        scale: 1;
        transform: translateY(0);
        opacity: 1;
    }
}