.order_product_card_main_wrapper 
{
    width: 8em;
    height: 4em;
    padding: 5px 5px;
    margin: 4px;
    border-radius: 10px;

    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.order_product_card_main_wrapper:hover 
{
    border-color: var(--color-theme);
}
.unavail 
{
    filter: brightness(60%);
    border: 2px solid var(--red-color) !important;
}
.unavail:hover 
{
    border: 2px solid var(--red-color) !important;
}
.order_product_card_content_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.order_product_card_content_wrapper h2 
{
    color: var(--primary_text_color);
    font-weight: bold;
    font-size: 0.8em;
}
.order_product_card_content_wrapper li 
{
    color: var(--color-theme);
    list-style: none;
    margin: 5px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    text-align: right;
    width: 100%;
}
.order_product_card_content_wrapper ul 
{
    color: var(--red-color);
    padding: 0;
    font-size: 1em !important;
    text-align: left !important;
}