.stock_in_out_header_action_bar_wrapper 
{
    background: var(--background-dark);
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.stock_inventory_action_left_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.stock_inventory_action_left_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}