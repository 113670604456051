.active_order_list_master_wrapper 
{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.avial_workspace_data_budle 
{
    display: flex;
    justify-content: space-between; 
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
}