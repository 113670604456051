.stock_action_main_wrapper
 {
    position: absolute;
    background: var(--alpha-background);
    width: 100%;
    height: 106vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 99;
 }
.stock_action_card 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    width: 60%;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    position: fixed;

    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.stock_action_card_header
{
    background: var(--background-light);
    padding: 8px;

    display: flex;
    justify-content: space-between;
}
.stock_action_card_header h1 
{
    font-size: 17px;
}
.stock_action_card_header button
{
    background: none;
    border: none;
    font-size: 17px;
    color: var(--primary_text_color);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.stock_action_card_header button:hover
{
    color: var(--red-color);
}
  .mix_low_level_detail li 
 {
  font-size: 13px;
   list-style: none;
   color: var(--text_light_white);
   border-left: 3px solid var(--primary_text_color);
    background: var(--background-dark);
    margin: 3px;
   padding: 8px;
   display: flex;
   font-weight: bold;
   justify-content: space-between;
   transition: 0.3s ease-in-out;
   cursor: pointer;
 }
 .mix_low_level_detail .detail_view_value 
 {
   color: var(--primary_text_color);
 }
 .mix_low_level_detail li:hover 
 {
   background: var(--background-light);
 }
 .po_batch_count
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    padding: 5px 20px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
 .mix_high_level_detail_header
 {
   padding: 10px;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .mix_high_level_detail_header h4 
  {
   color: var(--text_light_white);
   padding-bottom: 5px;
   border-bottom: 2px solid var(--background-dark);
 }
 .ing_list 
 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
 }
 .ing_list li 
 {
    width: 70%;
    font-size: 13px;
    border-left: 3px solid var(--grren-color);
    background: var(--background-dark);
    margin: 3px;
    list-style: none;
    color: var(--primary_text_color);
    padding: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .ing_list li:hover 
 {
   background: var(--background-light);
 }
 .mix_mat_list_wrapper 
 {
    display: flex;
    justify-content: space-between;
 }

 .stock_action_body_wrapper 
 {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;

    height: 400px;
    max-height: 500px;
    overflow: scroll;
 }
 .po_detail_wrapper 
 {
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 10px;
    width: 60%;

    height: 350px;
    max-height: 350px;
    overflow: scroll;
 }
 .stock_out_val 
 {
    font-weight: bold;
    color: var(--grren-color);
 }
 .sigCanvas
  {
    background: var(--background-dark);
    border: 2px solid var(--background-light);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  .stock_action_sig 
  {
    padding: 10px;
    text-align: center;
  }
.stock_action_sig button
{
    width: 100px;
    margin: 5px;
    padding: 3px;
    font-weight: bold;
    background: none;
    border: 2px solid var(--text_light_white);
    color: var(--text_light_white);
    border-radius: 2px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.stock_action_sig button:hover 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
}
.stock_action 
{
    padding: 50px 5px;
    display: flex;
    justify-content: center;
}
.stock_action button
{
    padding: 5px;
    width: 150px;
    color: var(--primary_text_color);
    background: var(--grren-color);
    border: 2px solid var(--grren-color);
    border-radius: 20px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.stock_action button:hover 
{
    background: none;
}
.sig_info
 {
    padding: 5px;
    display: flex;
    justify-content: left;
    gap: 10px;
 }
 .sig_info li 
 {
    list-style: none;
    color: var(--text_light_white);
    text-transform: capitalize;
 }
 .button_disable 
 {
    color: var(--background-light) !important;
    border-color: var(--background-light) !important;
 }
 .sing_info 
 {
    color: var(--grren-color) !important;
    font-weight: bold;
 }
 .sing_blip 
 {
  font-weight: bold;
    color: var(--red-color) !important;
    animation: blip 0.3s infinite;
 }
 .sign_force 
 {
    list-style: none;
    color: var(--red-color) !important;
    text-transform: capitalize;
    font-weight: bold;
    animation: blip 1s infinite;
 }
 @keyframes blip {

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
 }