.order_shadow_main_wrapper 
{
    width: 100%;
    height: 100vh;
    background: var(--alpha-background);
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    z-index: 2;
}
.order_shadow_qr_window 
{
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid var(--background-light);
    border-radius: 5px;
    position: fixed;
    
    
    background: var(--background-dark_minus);
}
.qr_shadow_body_wrapper 
{
    max-height: 350px;
    overflow: scroll;
}
.win_header_qr 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-light);
    padding: 5px;
}
.win_header_qr li 
{
    list-style: none;
    color: var(--text_light_white);
    padding: 2px 10px;
    font-size: 15px;
    font-weight: bold;
}
.win_header_qr button 
{
    background: none;
    border: none;
    font-size: 18px;
    color: var(--primary_text_color);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.win_header_qr button:hover 
{
    color: var(--red-color);
}
.body_inner_flex_view 
{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.qr_code_wrapper_left 
{
    padding: 20px;
}
.qr_code_wrapper 
{
    border: 1px solid var(--primary_text_color);
    background: var(--primary_text_color);
    padding: 20px;
    border: 3px solid var(--primary_text_color);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.49) 0px 10px 20px, rgba(0, 0, 0, 0.53) 0px 6px 6px;
}
.qr_code_wrapper_qr
 {
    border: 1px solid var(--primary_text_color);
    background: var(--primary_text_color);
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
 }
 .qr_out_info_wrapper 
 {
    border: 1px solid var(--primary_text_color);
    background: var(--primary_text_color);
    width: 200px;
    height: 280px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
 }
 .qr_info_to_print 
 {
    text-align: center;
 }
 .qr_info_to_print sub 
 {
    font-size: 10px;
    font-weight: bold;
 }
 .qr_info_to_print li
 {
    list-style: none;
    font-weight: bold;
 }
 .camp_name 
 {
    font-size: 13px;
 }
 .qr_code_wrapper_right 
 {
    padding: 20px;
 }
 .qr_code_wrapper_right li 
 {
    list-style: none;
    color: var(--text_light_white);
 }
 .action_button_wrapper 
 {
    padding: 20px;
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
 }
 .action_button_wrapper button 
 {
    width: 100px;
    padding: 5px 10px;
    border: 2px solid var(--text_light_white);
    color: var(--text_light_white);
    background: none;
    border-radius: 20px;
    margin: 5px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    cursor: pointer;
 }
 .action_button_wrapper button:hover 
 {
    color: var(--background-dark_plus);
    background: var(--text_light_white);
 }
 .action_button_wrapper .action_button_wrapper_incative 
 {
    width: 100px;
    padding: 5px 10px;
    border: 2px solid var(--background-dark);
    color: var(--background-dark);
    background: none;
    border-radius: 20px;
    margin: 5px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
 }
 .action_button_wrapper .action_button_wrapper_incative:hover 
 {
    width: 100px;
    padding: 5px 10px;
    border: 2px solid var(--background-dark);
    color: var(--background-dark);
    background: none;
    border-radius: 20px;
    margin: 5px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    cursor:progress;
 }
 .qr_code_wrapper_left_hide
 {
    filter: blur(10px);
    user-select: none;
    padding: 20px;
 }