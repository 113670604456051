.action_button_wrapper 
{
    padding: 5px 10px;
    background: var(--background-dark_minus);
    border: 2px solid var(--background-dark);
}
.production_center_inner_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
}