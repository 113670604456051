.data_main 
{
    width: 80% !important;
}
.po_mat_list_wrapper 
{
    padding: 10px;
    margin: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    border-radius: 10px;

    background: var(--background-dark_minus);
    height: 15em;
    max-height: 20em;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.po_mat_list 
{
    width: 100%;
    padding: 10px;
}
.input_list_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: space-between;

    align-items: center;
    align-content: center;
}
.input_header_single_wrapper_st_req_header h2
{
  color: var(--text_light_white);
  font-size: var(--default-font-scale-lrg);
  text-transform: uppercase;
}
.input_header_single_wrapper_st_req 
{
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    background: var(--background-dark_plus);
    border: 1px solid var(--background-light);
    padding: 3px;
    border-radius: 3px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    user-select: none;
}
.input_header_single_wrapper_st_req span 
{
  margin: 2px;
  color: var(--text_light_white);
  font-size: var(--default-font-scale-min);
}
.input_list_main_wrapper 
{
    background: var(--background-dark_minus);
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/* REMOVE BUTTON */
.remove_button_wrapper
{
  text-align: center;
  margin: 5px;
  padding: 3px;
  background: var(--red-color);
  border: 2px solid var(--red-color);
  color: var(--primary_text_color);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.remove_button_wrapper:hover 
{
  font-weight: bold;
  scale: 1.1;
  background: none;
  color: var(--red-color);
}
.remove_button_wrapper_fake 
{
   margin: 10px;
  padding: 6px;
}
.input_fild_shild 
{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
 }

 /* REMOVE BUTTON END */

 .input_single_wrap 
 {
  width: 15em;
 }