.stock_out_processing_main_wrapper
{
    position: relative;
    background: var(--background-dark_plus);
    width: 100%;
    height: 100vh;
    overflow: scroll;
}
.tool_quick 
{
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    z-index: 3;
}
.active_order_list 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.empty_po_stock_request 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 10em;
}
.empty_po_stock_request li
{
    list-style: none;
    color: var(--background-dark);
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
}
.stock_return_request_handle_list_wrapper_outer
 {
    position: absolute;
    left: 5px;
    bottom: 0;
    border-radius: 5px;
    width: 40%;
    min-width: 20em;
    min-height: 20em;
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
    border: 2px solid var(--background-dark);
    border-top: 3px solid var(--color-theme);
    padding: 10px;
 }
 .stock_return_request_handle_list_header 
 {
    border-bottom: 2px solid var(--text_light_white);
 }
 .stock_return_request_handle_list_header h3 
 {
    color: var(--primary_text_color);
 }
 .request_list_inner_wrapper 
 {
    height: 30em;
    overflow-y: scroll;
 }