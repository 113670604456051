.data_body 
{
    padding: 20px;
    height: 20em;
    max-height: 20em;
    overflow-y: scroll;
}
.data_preview_wrapper 
{
    margin: 10px;
    display: flex;
    justify-content: center;
}
.inner_preview_data_wrapper 
{
    width: 70%;
    background: #fff;
    height: 20em;
    max-height: 20em;
    overflow: scroll;
}
.meta_data_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    padding: 5px 20px;
    text-align: center;
    color: var(--print_paper_background);
}
.meta_data_wrapper h2
{
    font-size: 0.8em;
    margin: 5px;
    text-transform: uppercase;
}

.transcation_init li
{
    width: 100%;
    list-style: none;
    font-size: var(--default-font-scale-min);
    color: var(--background-dark_plus);
    margin: 3px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 6em;
}

.final_note_po_stock_out 
{
    padding: 5px;
}
.final_note_po_stock_out li 
{
    list-style: none;
    font-size: 0.5em;
    font-weight: bold;
    text-align: right;
}

/* BREAK */
.action_button_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    padding: 15px;

    background: var(--background-dark);
    border: 2px solid var(--background-light);
}
.action_button_wrapper button 
{
    font-weight: bold;
    color: var(--background-dark_plus);
    font-size: 0.8em;
    width: 8em;
    padding: 3px;
    cursor: pointer;
    border-top-left-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.action_button_wrapper button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.button_print 
{
    background: var(--inventory-type-color);
    border: 1px solid var(--inventory-type-color);
}
.button_dismiss 
{
    background: var(--red-color);
    border: 1px solid var(--red-color);
}

.employe_personal_info_wrapper_outer 
{
    background: var(--print_paper_background_dark_plus);
    border-radius: 10px;
    margin: 10px;
    padding: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.employe_personal_info_wrapper_outer h2 
{
    text-align: center;
    font-size: var(--default-font-scale-lrg);
}
.employe_personal_info_wrapper_inner 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.employe_personal_info_wrapper 
{
    /* background: var(--print_paper_background); */
    padding: 5px 10px;
    width: 50%;
}
.employe_personal_info_pp_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.employe_personal_info_pp_wrapper img 
{
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 150px;
    height: 150px;
}
.employe_personal_info_wrapper li 
{
    list-style: none;
    font-size: var(--default-font-scale-mid);
    background: var(--print_paper_background);
    margin: 5px;
    padding: 3px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.employee_personal_info_label 
{
    width: 15em;
}
.employee_personal_info_value 
{
    text-transform: capitalize;
    font-weight: bold;
}