  /* Loading GIF Wrapper */
  .loading_gif_wrapper 
  {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
    height: 15em !important;
    width: 100% !important;
  }
  .loading_gif_wrapper  .loading_gif_placeholder_text 
  {
      color: var(--text_light_white) !important;
      font-size: var(--default-font-scale-min) !important;
  
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      align-content: center !important;
      font-weight: bold !important;
  
      border: 2px solid var(--background-light) !important;
      background: var(--background-dark);
  
      border-radius: 2em !important;
      padding: 2px 10px !important;
      cursor: pointer !important;
      transition: 0.3s ease-in-out !important;
      margin: 3px !important;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
      animation: smoth_in 0.3s ease-in-out !important;
  }
  @keyframes smoth_in {
      0% {
          opacity: 0.0;
          filter: blur(5px);
      }
      100% {
          opacity: 1;
          filter: blur(0px);
      }
  }
    /* END Loading GIF Wrapper */