.qr_code_reader_main_wrapper 
{
    position: fixed;
    background: var(--alpha-background);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 99;
}
.code_scan_wrapper 
{
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.qr_code_reader 
{
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    width: 25em;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.qr_action 
{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: var(--background-dark_plus);
}
.qr_action li 
{
    border: 1px solid var(--grren-color);
    padding: 5px 15px;
    color: var(--background-dark_plus);
    background: var(--grren-color);
    list-style: none;
    border-radius: 2px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    animation: blip 2s infinite;
}
@keyframes blip {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.qr_action button 
{
    padding: 5px 10px;
    font-size: var(--default-font-scale-mid);
    border: 2px solid var(--red-color);
    background: var(--red-color);
    color: var(--primary_text_color);
    cursor: pointer;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 2px;
    transition: 0.3s ease-in-out;
}
.qr_action button:hover 
{
    border: 2px solid var(--red-color);
    background: none;
    color: var(--red-color);
}