.Notification_main_wrapper 
{
    width: 400px;
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    padding: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 3px;
    align-items: center;
    position: fixed;
    box-shadow: rgb(53, 64, 112) 0px 20px 30px -10px;
    transition: 0.7s ease-in-out;
    scale: 1;
    opacity: 1;
    margin: 20px;
    z-index: 4;
    animation: 0.7s slide_in ease-in-out;
}
@keyframes slide_in {
    0% {
        margin-top: -15%;
    }
   100% {
        margin: 20px;
    }
  }
.Notification_main_wrapper_hide 
{
    width: 400px;
    border: 1px solid var(--background-dark_plus);
    background: var(--background-dark_plus);
    padding: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 3px;
    align-items: center;
    position: fixed;
    box-shadow: rgb(53, 64, 112) 0px 20px 30px -10px;
    scale: 0;
    opacity: 1;
    z-index: 4;
    margin-top: -15%;
    transition: 0.7s ease-in-out;
}
.icon_section 
{
    color: var(--primary_text_color);
    font-size: 30px;
    padding: 5px;
}
.noti_manual_close 
{
    padding: 5px;
}
.noti_manual_close button 
{
    background: none;
    border: none;
    color: var(--background-light);
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.noti_manual_close button:hover 
{
    color: var(--primary_text_color);
}
.msg_header
 {
    color: var(--primary_text_color);
    font-size: 1.0em;
    font-weight: 600;
    padding-left: 10px;
 }
 .msg_body 
 {
    padding: 10px;
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    word-wrap: break-word;
    /* text-transform: uppercase; */
 }
.left_side_not_wrapper
 {
    display: flex;
    justify-content: left;
    max-width: 90%;
 }