.data_main 
{
    width: 30em;
    height: auto;
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    border-radius: 1em;
    height: 10em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.data_main_header 
{
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background: var(--background-dark_minus);
}
.data_main_header h3 
{
    color: var(--text_light_white);
    font-size: 0.8em;
    font-weight: 200;
}
.data_main_header .data_create_name 
{
    font-weight: 600;
}
.data_main_header button 
{
    background: none;
    border: none;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    color: var(--text_light_white);
    transition: 0.3s ease-in-out;
}
.data_main_header button:hover 
{
    color: var(--red-color);
}
.data_body 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 7em;
    flex-wrap: wrap;
}
.data_body input 
{
    text-align: center;
    text-transform: uppercase;
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    color: var(--text_light_white);
    border-radius: 2em;
    font-size: var(--default-font-scale-mid);
    width: 70%;
    padding: 5px 10px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.data_body li 
{
    width: 100%;
    text-align: center;
    color: var(--text_light_white);
    margin: 10px;
    font-size: var(--default-font-scale-min);
    list-style: none;
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s ease-in-out;
}
.data_body li:hover 
{
    color: var(--primary_text_color);
}