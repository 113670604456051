.form_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.master_prod_type_config_wrapper 
{
    padding: 5px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;

    width: 80%;

    background: var(--background-dark_minus);
    border: 2px solid var(--background-light);
    border-radius: 0.5em;

    max-height: 15em;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.master_prod_type_config_wrapper li 
{
    cursor: pointer;
    border-radius: 2em;
    font-size: var(--default-font-scale-min);
    list-style: none;
    color: var(--text_light_white);
    border: 1px solid var(--background-light);
    width: 90%;
    margin: 3px;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s ease-in-out;
    font-weight: bold;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.tag_so_detail_label 
{
    width: 15em;
    font-weight: lighter;
}
.tag_so_detail_value 
{
    color: var(--color-theme);
    margin-left: 10px;
    font-weight: bold;
    padding: 3px 5px;
}
.master_prod_type_config_wrapper li:hover 
{
    background: var(--background-dark_minus);
    border-color: var(--background-light);
    scale: 1.03;
}
.deatil_patch_wrapper 
{
    width: 50%;
}