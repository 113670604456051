.emp_credit_main_wrapper 
{
    z-index: 1;
}
.emp_action_adder 
{
    text-align: right;
}
.emp_action_adder button 
{
    margin-left: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: var(--default-font-scale-min);
    border: 1px solid var(--background-dark);
    background: var(--background-dark);
    color: var(--primary_text_color);
    cursor: pointer;
    animation: 0.3s smooth_in;
    transition: 0.3s ease-in-out;
}
.emp_action_adder button:hover 
{
    background: var(--color-theme);
    color: var(--primary_text_color);
    font-weight: bold;
}
.amp_credit_info
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.list_wrapper_inner
{
    margin-top: 10px;
    padding: 5px 10px;
    min-width: 40em;
    width: 90%;
    background: var(--background-dark_minus);
    border: 2px solid var(--background-dark);
    height: 15em;
    max-height: 15em;
    overflow-y: scroll;
}
.list_wrapper_inner li 
{
    margin: 5px 2px;
    border: 1px solid var(--background-light);
    padding: 3px 8px;
    background: var(--background-dark_plus);

    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s ease-in-out;
}
.list_wrapper_inner li:hover 
{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.list_wrapper_inner li span 
{
    flex: 1;
}
.action_indicator
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}