.form_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}
.input_list_wrapper 
{
    width: 40%;
}
.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.master_prod_avail_list 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.workspace_tick_and_name_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 40%;
}
.master_prod_avail_branch 
{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin: 4px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 1px 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.master_prod_avail_branch:hover 
{
    background: var(--background-dark);
}
.workspace_min_max_value_per_type_wrapper 
{
    width: 70%;
}
.master_prod_avail_branch  li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    margin: 5px;
}
.master_product_type_inner_avail_workspace_list 
{
    width: 50%;
}
.master_product_type_inner_avail_workspace_list_header 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px;

    border: 2px solid var(--background-dark);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 10px;
}
.master_product_type_inner_avail_workspace_list_header button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 0.8em;
    float: top;
    &:hover 
    {
        color: var(--color-theme);
    }
}
.master_product_type_inner_avail_workspace_list_header h1 
{
    font-size: 0.8em;
    text-transform: capitalize;
    color: var(--text_light_white);
}
.master_product_type_inner_config_wrapper 
{
    width: 40%;
}
.master_product_type_inner_config_wrapper li
{
    list-style: none;
    margin: 5px;
}
.master_product_type_inner_config_wrapper li input 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    border-radius: 3px;
    width: 70%;
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
}
.edible_state_input_wrapper 
{
    border: 1px solid var(--background-light);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin: 5px;
    border-radius: 3px;
    background: var(--background-dark);
    padding: 10px;
    cursor: pointer;
    user-select: none;
}
.edible_state_input_wrapper span 
{
    color: var(--text_light_white);
    padding: 3px 10px;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
}
.follow_parent_wrapper 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    border-bottom: 5px;
    padding: 7px;
    margin: 5px;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    user-select: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.follow_parent_wrapper span 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-mid);
    padding: 3px 10px;
}
.loader_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    height: 10em;
}