.single_main_item 
{
    list-style: none;
    background: var(--background-dark);
    margin: 2px;
    transition: 0.3s ease-in-out;
    border-bottom: 2px solid var(--background-dark);
}
.single_main_item:hover 
{
    background: var(--background-light);
    border-bottom: 2px solid var(--main-color);
}
.single_main_item_inner 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    font-size: var(--default-font-scale-min);
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: none;
    color: var(--text_light_white);

    padding: 4px;
    padding-right: 25px;
}
.link_tag_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.link_tag_ico_wrapper 
{
    width: 2em;
    padding: 1px 2px;
}