.main_data_creator_wrapper 
{
    position: fixed;
    background: var(--alpha-background); 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    
    
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 100;
    animation: slow_in 0.5s ease-in-out;
}
.main_data_creator_wrapper_emp {
    position: fixed;
    background: var(--alpha-background_strong);
    
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 100;
    animation: slow_in 0.5s ease-in-out;
}
@keyframes slow_in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.data_main 
{
    width: 85%;
    height: auto;
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
@media (max-width: 1024px) { /* Targeting tablets */
    .data_main {
        width: 100%;  /* Make it full width */
        height: 100%; /* Full height */
        top: 0;
        left: 0;
        transform: none; /* No need for centering translation */
        border-radius: 0; /* Remove rounded corners if any */
    }
}
.data_main_header 
{
    display: flex;
    justify-content: space-between;
    padding: 7px;
    background: var(--text_light_white);
}
.data_main_header h3 
{
    color: var(--background-dark_plus);
    font-size: 16px;
    font-weight: 200;
}
.data_main_header .data_create_name 
{
    font-weight: 600;
}
.data_main_header button 
{
    background: none;
    border: none;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    color: var(--background-dark_plus);
    transition: 0.3s ease-in-out;
}
.data_main_header button:hover 
{
    color: var(--red-color);
}

.form_input_with_icon 
{
    background: var(--background-dark);
    border-bottom: 1px solid var(--background-dark);
    border-radius: 2px;
    display: flex;
    justify-content: start;
    margin: 5px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.form_input_with_icon_force_input 
{
    border-right: 3px solid var(--color-yellow);
}
.form_input_child_icon_wrapper 
{
    background: var(--background-dark_minus);
    border: 1px solid var(--background-dark_minus);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: 0.1s ease-in-out;
}
.form_input_child_wrapper 
{
    width: 100%;
}
.form_input_icon 
{
    color: var(--primary_text_color);
    transition: 0.1s ease-in-out;
}
.form_input_child_wrapper input 
{
    width: 90%;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold; 
    outline: none;
}
.form_input_child_wrapper textarea 
{
    min-height: 3em;
    min-width: 70%;

    color: var(--text_light_white);
    max-height: 5em;
    max-width: 85%;
    border-radius: 3px;
    font-size: var(--default-font-scale-min);
    font-weight: bold; 
    border: none;
    background: var(--background-dark);
}
.form_input_with_icon:hover 
{
    border-bottom: 1px solid var(--color-theme);
}
.form_input_with_icon:hover .form_input_icon  
{
    color: var(--background-dark_plus);
}
.form_input_with_icon:hover .form_input_child_icon_wrapper 
{
    background: var(--color-theme);
    border-color: var(--color-theme);
}

/* Action Buttons */
.form_action_button_save 
{
    font-size: var(--default-font-scale-min);
    padding: 4px 8px;
    margin: 5px;
    width: 100px;
    background: none;
    border: 2px solid var(--text_light_white);
    color: var(--text_light_white);
    border-radius: 20px;
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.form_action_button_save:hover 
{
    color: var(--background-dark_plus);
    background: var(--color-theme);
    border-color: var(--color-theme);
}
.form_action_button_dismiss
{
    font-size: var(--default-font-scale-min);
    padding: 4px 8px;
    margin: 5px;
    width: 100px;
    background: none;
    border: 2px solid var(--text_light_white);
    color: var(--text_light_white);
    border-radius: 20px;
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.form_action_button_dismiss:hover 
{
    color: var(--background-dark_plus);
    background: var(--red-color);
    border-color: var(--red-color);
}
.tool_quick 
{
    z-index: 999;
    font-size: var(--default-font-scale-min) !important;
    max-width: 300px;
    font-weight: 600;
    color: var(--background-dark_plus) !important;
    background: var(--text_light_white) !important;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}
.input_adder 
{
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.input_adder button 
{
    font-size: var(--default-font-scale-min);
    user-select: none;
    padding: 5px 10px;
    margin-right: 10px;
    border: 2px solid var(--text_light_white);
    background: var(--text_light_white);
    border-radius: 2px;
    color: var(--background-dark_plus);
    transition: 0.3s ease-in-out;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.input_adder button:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.form_action_button_wrapper 
{
   display: flex;
   justify-content: right;
   align-items: center;
   padding: 5px 10px;
   border: 2px solid var(--background-dark);
   background: var(--background-dark_minus);
}
.form_input_child_text_label 
{
    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    font-weight: bold;
    list-style-type: disc;
    padding: 0px 10px;
}
.lable_tick 
{
    color: var(--color-theme);
}