.part_of_option ul
{
    padding: 5px 10px;
    background: var(--background-light);
    width: 100%;
    border: 2px solid var(--background-light);
    border-radius: 2em;
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    color: var(--text_light_white);
}
.setting_items_config  
{
    margin-left: 5em;
}
.setting_items_config li
{
    list-style: none;
    color: var(--text_light_white);
    padding: 5px 10px;
    background: var(--background-dark);

    font-size: var(--default-font-scale-min);
    border-radius: 2em;
    margin: 3px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 5px;
    
}
.setting_items_config .input_value
 {
    text-align: center;
    width: 4em;
    padding: 2px 4px;
    color: var(--text_light_white);
    border: 1px solid var(--background-light);
    background: var(--background-dark_plus);
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }