.inventory_header_not_type 
{
    padding: 2px 4px;
    border-bottom: 1px solid var();
    background: var(--print_paper_background_dark_plus);
}
.inventory_header_not_type h2
{
    font-size: var(--default-font-scale-mid);
}
.entry_wrapper_left_monthly_data_list 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.montly_inventory_data_list 
{
    padding: 2px 4px;
    width: 90%;
    
    border-bottom: 10px;
}
.montly_inventory_data_list li 
{
    list-style: none;
    font-size: var(--default-font-scale-min);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 2px;
    background: var(--print_paper_background);
    border-radius: 2em;
    padding: 1px 10px;
}
.montly_inventory_data_list li  span 
{
    text-align: left;
  
}
.stock_utilization_deduct 
{
    color: var(--red-color);
    font-weight: bold;
}