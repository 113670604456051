.branch_card_preview_payroll_schedule 
 {
    position: relative;
     border: 2px solid var(--background-light);
     background: var(--background-dark);
    width: 10em;
    padding: 10px;
    margin: 3px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }
 .branch_card_preview_payroll_schedule:hover 
 {
    background: var(--background-dark_minus);
 }
 .schedulaer_card_left_content_wrapper li
 {
    color: var(--text_light_white);
    font-size: 0.8em;
    font-weight: bolder;
    list-style: none;
 }
 .schedulaer_card_left_content_wrapper ul 
 {
    font-size: 0.5em;
    color: var(--text_light_white);
 }
 .schedulaer_card_right_content_wrapper li 
 {
    text-transform: uppercase;
    list-style: none;
    color: var(--color-theme);
    font-size: 2em;
 }

 .payroll_schedule_setup_date_input_wrapper 
 {
    border-radius: 5px;
    
    position: absolute;
    border: 2px solid var(--background-light);
    background: var(--background-dark);
    width: 10em;
    top: 4em;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
 }
 .payroll_schedule_setup_date_input_body_wrapper 
 {
    padding: 10px;
 }
 .mini_header_min_menu 
 {
    background: var(--background-light);
    text-align: right;
 }
 .mini_header_min_menu button
 {
    border: none;
    background: none;
    font-size: 0.9em;
    padding: 1px 10px;
    color: var(--text_light_white);
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .mini_header_min_menu button:hover 
 {
    color: var(--red-color);
 }
 .payroll_schedule_setup_input_field_wrapper 
 {
    text-align: center;
 }
 .payroll_schedule_setup_input_field_wrapper input 
 {
    padding: 3px 6px;
    font-size: var(--default-font-scale-min);
    background: var(--background-dark_plus);
    border: 2px solid var(--background-light);
    border-radius: 2em;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
 }
 .payroll_schedule_setup_action_button 
 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
 }
 .init_schdule_with_manual 
 {
   font-size: 1em !important;
   display: inline-flex !important;
   color: var(--red-color) !important;
   animation: blip 1s ease-in-out infinite;
 }
 @keyframes blip {
   0%{
      opacity: 0;
   }
   50%{
      opacity: 1;
   }
   100%{
      opacity: 0;
   }
 }
 .init_schduleed_succ 
 {
   color: var(--color-theme) !important;
   font-weight: bold !important;
 }