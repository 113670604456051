 .local_notification
 {
    width: 25em;
    border: 2px solid var(--menu-button-hover-color);
    background: var(--menu-button-hover-color);
    border-radius: 4px;
    position: fixed;
    top: 50px;
    left: 10px;
    z-index: 2;
    opacity: 1;
    margin-left: 0%;
    z-index: 4;
    animation: 0.2s slide_in ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
@keyframes slide_in {
    0% {
        margin-left: -15%;
    }
   100% {
        margin: 0px;
    }
  }
 .local_notification_hide
 {
    width: 25em;
    border: 2px solid var(--menu-button-hover-color);
    background: var(--menu-button-hover-color);
    border-radius: 4px;
    position: fixed;
    top: 50px;
    left: 10px;
    z-index: 2;
    opacity: 0;
    margin-left: -15%;
    transition: 0.3s ease-in-out;
 }
.local_wrapper_not 
{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    align-content: center;
}
.local_icon_section 
{
    color: var(--menu-button-hover-color);
    padding: 5px;
    background: var(--background-dark_plus);
}
.local_msg_seaction 
{
    color: var(--background-dark_minus);
    font-weight: bold;
    font-size: var(--default-font-scale-mid);
}