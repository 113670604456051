.form_wrapper 
{
    padding: 10px;
    height: 10em;
    max-height: 10em;
    overflow: scroll;
}
.input_list_wrapper 
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
}
.input_wrap 
{
    flex: 1;
}
.input_package_wrapper 
{
    width: 45%;
}
.input_wrap_special_cloner 
{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: var(--background-dark);
    border: 1px solid var(--background-light);
    padding: 2px 10px;
}
.input_wrap_special_cloner li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 2px 5px;
}