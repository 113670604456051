.desktop_main_icon_wrapper 
{
    visibility: hidden;
    animation: scale-from-middle 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
@keyframes scale-from-middle {
    0% {
        transform: scale(0) translateY(50px);
        filter: blur(10px);
        opacity: 0;
        visibility: hidden;
      }
      60% {
        transform: scale(1.1) translateY(0);
        opacity: 1;
        visibility: visible;
      }
      100% {
        filter: blur(0px);
        transform: scale(1) translateY(0);
        visibility: visible;
      }
  }
.desktop_icon_box_wrapper 
{
    border: 2px solid var(--background-dark);
    width: 2.5em;
    height: 2.5em;
    background: var(--background-dark_minus);
    border-radius: 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text_light_white);
    font-size: 2em;
    cursor: pointer;
    color: var(--color-theme);
    transition: 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.desktop_icon_box_wrapper:hover 
{
    background: var(--color-theme);
    color: var(--background-dark);
    border-color: var(--color-theme);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.desktop_icon_text 
{
    padding: 8px 4px;
    display: flex;
    justify-content: center;
}
.desktop_icon_text li 
{
    font-weight: bold;
    list-style: none;
    color: var(--primary_text_color);
    text-align: center;
    font-size: var(--default-font-scale-min);
    width: 7em;
    text-align: center;
}