.form_wrapper 
{
    margin: 10px;
}
.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
}
.master_product_type_inner_config_wrapper
{
    width: 45%;
}
.master_product_type_inner_config_wrapper_content_list 
{
    height: 15em;
    border-radius: 1em;
    overflow: scroll;
    padding: 3px 6px;
    background: var(--background-dark);
    border: 1px solid var(--background-light);
}