 .emp_pto_action_adder 
 {
    display: flex;
    justify-content: right;
 }
 .emp_pto_action_adder button 
 {
    background: var(--background-dark);
    border: 1px solid var(--background-dark);
    color: var(--primary_text_color);
    padding: 3px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: 0.3s ease-in-out;
    font-size: var(--default-font-scale-min);
    cursor: pointer;
 }
 .emp_pto_action_adder button:hover
 {
    background: var(--color-theme);
 }