.recipe_card_main_wrapper 
{
    border: 2px solid var(--background-dark);
    /* border-top: 2px solid var(--color-theme); */
    background: var(--background-dark_minus);
    border-radius: 1em;
    width: 20em;
    min-width: 18em;
    max-width: 25em;
    margin: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 0.2s ease-in-out;

    user-select: none;
    flex: 1 1 200px;
}
/* .emulate_sublimi 
{
    transform:
    perspective(600px)
    rotateX(-5deg);
} */
.recipe_card_main_wrapper:hover 
{
    box-shadow: var(--color-theme) 0px 25px 20px -20px;
    border-bottom: 2px solid var(--color-theme);
}
.recipe_card_header 
{
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    padding: 5px 6px;
    background: var(--background-dark_plus);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.recipe_card_header h2 
{
    color: var(--text_light_white);
    font-size: 0.9em;
    font-weight: bold;
    text-transform: capitalize;
}
.local_buffer_count 
{
    height: 1em;
    width: 1em;
    /* padding: 1px; */
    font-size: var(--default-font-scale-lrg);

    color: var(--color-theme) !important;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    animation: pulse 1s infinite, ease-in-out;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--color-theme);
	}
	70% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.recipe_card_header sub 
{
    color: var(--background-light);
    color: var(--color-theme);
    font-size: var(--default-font-scale-min);
}
.recipe_card_ing_list
{
     padding: 5px 10px;
     height: 8em;
     max-height: 8em;
     overflow: scroll;
     background: var(--background-light);
}
.recipe_card_ing_list li 
{
    list-style: none;
    color: var(--text_light_white);
    font-weight: bold;
    font-size: var(--default-font-scale-min);
    margin: 3px 3px;
    cursor: pointer;
}
.recipe_card_ing_list li 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 2em;
    flex-wrap: wrap;
    border: 1px solid var(--background-dark_minus);
    background: var(--background-dark);
    padding: 4px 10px;
    margin: 5px;
    transition: 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.recipe_card_ing_list li:hover 
{
    background: var(--background-light);
}
.recipe_card_ing_list li span 
{
    flex: 1;
    text-align: left;
}
.recipe_card_val 
{
    text-align: right !important;
}
.recipe_invt_check 
{
    text-align: right !important;
    color: var(--color-theme);
}
.recipe_invt_x 
{
    text-align: right !important;
    color: var(--red-color);
}
.crad_action_wrapper 
{
    border-radius: 1em;
    padding: 5px 10px;
    background: var(--background-dark_plus);
}
.card_action_button_wrapper 
{
    padding: 5px;
    display: flex;
    justify-content:center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.card_action_button 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 5px;
}
.card_action_button li 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.card_action_button li span 
{
    display: inline-flex;
    margin: 0 3px;
}
.sheet_amount_manip 
{
    color: var(--color-theme);
    text-align: center;
    font-size: var(--default-font-scale-lrg);
    cursor: pointer;
}
.sheet_amount_active 
{
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    font-weight: bolder;
    padding: 2px;
    border: 2px solid var(--color-theme);
    width: 1em;
    height: 1em;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.sheet_count 
{
    padding: 10px 1px;
    height: 10em;
    transition: 0.3s ease-in-out;
    visibility: visible;
}
.sheet_count_hide 
{
    height: 0em;
    overflow: hidden;
    visibility: hidden;
    transition: 0.3s ease-in-out;
}
.show_beh 
{
    padding: 3px;
}
.show_beh button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    transition: 0.3s ease-in-out;
}
.show_beh button:hover 
{
    color: var(--color-theme);
}
.sheet_count li, .sheet_count_hide li 
{
    position: relative;
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 3px 6px;
    border: 1px solid var(--background-dark);
    margin: 3px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    flex: 1;
    transition: 0.3s ease-in-out;
}
.scan_local_buffer 
{
    cursor: pointer;
}
.sheet_tag_val 
{
    border: 1px solid var(--text_light_white);
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    padding: 1px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.ban_stock_out 
{
    color: var(--red-color) !important;
    background: var(--background-dark_minus) !important;
}