.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.invoice_view_body_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    padding: 10px;
    gap: 10px;
}
.invoice_preview 
{
    padding: 5px;
}
.invoice_preview img 
{
    width: 10em;
    height: 15em;
    cursor: pointer;
    border-radius: 0.5em;
    transition: 0.3s ease-in-out;
    box-shadow: var(--color-theme) 0px 13px 27px -5px, var(--color-theme) 0px 8px 16px -8px;
}
.invoice_preview img:hover 
{
    scale: 1.02;
    transform:
    perspective(400px)
    rotateX(10deg);
}

.main_data_entry_view 
{
    border-radius: 0.5em;
    width: 40%;
    min-width: 30em;
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.entry_list 
{
    padding: 5px 10px;
    height: 12em;
    max-height: 12em;
    overflow: scroll;
}
.entry_list li 
{
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 3px;
    padding: 2px 8px;
    border: 1px solid var(--background-dark);
    background: var(--background-dark_minus);

    font-size: var(--default-font-scale-min);
    color: var(--text_light_white);
    cursor: pointer;
    border-radius: 2em;
    transition: 0.3s ease-in-out;
}
.entry_list li:hover 
{
    scale: 1.03;
    background: var(--background-light);
}
.entry_list li span 
{
    flex: 1;
}
.price_teller 
{
    color: var(--color-theme);
    font-weight: bold;
    text-decoration: underline;
}
.entry_header 
{
    padding: 5px 10px;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    background: var(--background-dark);
    border-bottom: 2px solid var(--background-light);
    margin: 4px;
}
.entry_header li 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    list-style: none;
    font-size: var(--default-font-scale-min);
    padding: 1px;
    color: var(--text_light_white);
    font-weight: bold;
}