.production_stack_main_wrapper 
{
    width: 100%;
    height: 100vh;
    background: var(--background-dark_minus);
    overflow: scroll;
}
.innder_prod_flex_wrapper 
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.list_wrapper_center_flex 
{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner_prod_stack_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
}



@keyframes smoothIn {
  from {
    opacity: 0;
    transform: translateY(-40px); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.innder_prod_flex_wrapper div {
  animation: smoothIn 0.3s ease-in-out;
  animation-fill-mode: both; /* Keeps the end state of the animation */
}
.empty_stack 
{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  height: 10em;
}
.empty_stack li 
{
  width: 100%;
  list-style: none;
  color: var(--background-dark);
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.production_stack_action_Wrapper 
{
  border: 1px solid var(--background-light);
  padding: 5px 10px;
  background: var(--background-dark);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.production_stack_action_Wrapper li 
{
  list-style: none;
  font-size: var(--default-font-scale-mid);
  border: 1px solid var(--text_light_white);
  background: var(--text_light_white);
  color: var(--background-dark_plus);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 2em;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}