.data_body 
{
    height: 25em;
    max-height: 25em;
    overflow-y: scroll;
}
.map_frame_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.leaftlet_map 
{
    z-index: 90;
    width: 60%;
    height: 200px;
    border: 2px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
}
.map_marker  
{
    position: absolute;
    border-radius: 50%;
    animation: pulse 2.0s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--color-theme);
	}
	70% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.usr_info_pers 
{
    padding: 10px;
    background: #090909;
    width: 40%;
    height: 11em;
    max-height: 11em;
    margin: 10px;
    border-radius: 5px;
}
.usr_info_pers li
{
    padding: 2px 5px;
    border-bottom: 2px dotted var(--background-dark_minus);
    list-style: none;
    color: var(--color-theme);
    font-size: var(--default-font-scale-mid);
    font-weight: bold;
    
    display: flex;
    justify-content: space-between;
}
.ac_val 
{
    font-size: 0.9em;
}

.top_level_user_detail 
{
    padding: 10px;
}
.user_pic_wrapper 
{
    padding: 10px;
}
.user_pic_wrapper img 
{
    width: 150px;
    height: 150px;
    border-radius: 5px;

    transform:
    perspective(200px)
    rotateX(20deg);

    transition: 0.6s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.user_pic_wrapper img:hover 
{
    transform: perspective(200px) rotateX(-10deg); 
    box-shadow: rgb(91, 190, 152) 0px 20px 30px -10px;
    scale: 1.08;
}
.user_name_wrapper 
{
    padding: 5px;
    text-align: center;
}
.user_name_wrapper li 
{
    font-size: 0.8em;
    list-style: none;
    color: var(--text_light_white);
}

.header_wrap 
{
    padding: 20px 10px;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    flex-wrap: wrap;

    background: var(--background-dark_plus);
    border-bottom: 2px solid var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.session_detail 
{
    width: 70%;
}
.session_detail li 
{
    list-style: none;
    color: var(--text_light_white);
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 5px 10px;
    margin: 5px;
    font-size: var(--default-font-scale-mid);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.session_detail li:hover 
{
    scale: 1.03;
}
.pass_update_wrapper
{
    list-style: none;
    color: var(--text_light_white);
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 1px;
    margin: 5px;
    font-size: var(--default-font-scale-mid);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    display: flex;
}
.pass_update_wrapper input 
{
    margin: 5px;
    outline: none;
    border: 1px solid var(--background-dark);
    padding: 8px 5px;
    background: var(--background-dark_plus);
    font-size: 0.9em;
    font-weight: bold;

}
.pass_field_action 
{
    display: flex;
    justify-content: flex-start;
}
.pass_field_action button 
{
    font-size: 1.0em;
    padding: 1px 5px;
    border: 1px solid var(--background-light);
    background: var(--background-light);
    margin: 5px 2px;
    color: var(--text_light_white);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 1px;
    font-weight: bold;
    text-transform: uppercase;

    &:hover 
    {
        background: var(--color-theme);
        color: var(--background-dark_plus);
    }
}
.user_action 
{
    padding: 10px;
    display: flex;
    justify-content: right;
    background: var(--background-dark_minus);
    border-top: 2px solid var(--background-light);
}
.halt_account 
{
    background: var(--color-yellow);
    border: 2px solid var(--color-yellow);
    color: var(--background-dark_plus);
}
.halt_account:hover 
{
    color: var(--color-yellow);
    background: var(--background-dark_plus);
}
.revoke_account 
{
    background: var(--red-color);
    border: 2px solid var(--red-color);
    color: var(--background-dark_plus);
}
.revoke_account:hover 
{
    color: var(--red-color);
    background: var(--background-dark_plus);
}
.state_wrapper 
{
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    background: var(--background-dark);
    border: 2px solid var(--background-light);
    margin: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.state_wrapper button 
{
    width: 10em;
    margin: 0px 10px;
    border: none;
    cursor: pointer;
    border-top-left-radius: 10px;
}


.online_state, .account_state_active 
{
    color: var(--background-dark_plus);
    border-radius: 1px;
    font-weight: bold;
    padding: 2px 10px;
    background: linear-gradient(to right, rgb(17, 153, 142), rgb(56, 239, 125));
}
.offline_state, .account_state_suspend
{
    color: var(--background-dark_plus);
    border-radius: 1px;
    font-weight: bold;
    padding: 2px 10px;
    background: linear-gradient(to right, rgb(203, 45, 62), rgb(239, 71, 58));
}



.info_wrap 
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}

.raw_info_wrap 
{
    width: 45%;
}
.access_wrap 
{
    width: 50%;
    border-radius: 3px;
    border: 1px solid var(--background-light);
    background: var(--background-dark);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.batch_input_wrap 
{
    padding: 5px;
    height: 10em;
    max-height: 10em;
    overflow-y: scroll;
}
.action_wrap 
{
    padding: 5px;
    text-align: right;
    background: var(--background-dark_plus);
}

.single_input_wrap 
{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    background: var(--background-dark_minus);
    border: 2px solid var(--background-light);
    padding: 3px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.workspace_access_tag
 {
    padding: 3px 10px;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
 }
 .role_update_wrapper 
 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    color: var(--text_light_white);
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);
    padding: 2px;
    margin: 5px;
    font-size: var(--default-font-scale-mid);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
 }
 .input_box_dp_role_data_wrapper 
 {
    width: 80%;
    font-size: 1.5em;
 }
 .role_update_wrapper button 
 {
    padding: 6px 10px;
    margin: 5px;
    border: 1px solid var(--background-light);
    background: var(--background-light);
    color: var(--text_light_white);
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 1px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    text-transform: uppercase;

    &:hover 
    {
        background: var(--color-theme);
        color: var(--background-dark_plus);
    }
 }

.loading_placeholder 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 25em;
}
.user_location_info_tip li
{
    font-size: 0.9em;
    font-weight: bold;
}
.unverified_email
 {
    border: 2px solid var(--red-color);
    padding: 1px 7px;
    color: var(--red-color);
    border-radius: 2em;
    font-weight: bold;
    margin-left: 3px;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
 }
 .verified_email 
 {
    color: var(--color-theme);
    margin-left: 5px;
}
.user_email_preview_check
{
      display: inline-flex;
      width: 92%;
}