.vender_card_main_wrapper 
{
    margin: 10px;
    border: 1px solid var(--background-light);
    width: 12em;
    text-align: center;
    border-radius: 3px;
    background: var(--background-dark);
    transition: 0.4s ease-in-out;
    cursor: pointer;
    animation: smooth_in 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
@keyframes smooth_in
 {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}
.vender_card_main_wrapper li 
{
    list-style: none;
    padding: 3px;
}
.v_name 
{
    color: var(--primary_text_color);
    font-size: var(--default-font-scale-lrg);
    font-weight: bold;
    border-bottom: 2px solid var(--background-dark);
}
.v_tin 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
}
.vender_card_main_wrapper:hover 
{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.vendor_card_action_button_wrapper 
{
    border: 1px solid var(--background-light);
    background: var(--background-dark_minus);

    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.vendor_card_action_button_wrapper button 
{
    cursor: pointer;
    margin: 3px;
    padding: 4px;
    background: none;
    font-size: var(--default-font-scale-min);
    border: 1px solid var(--background-light);
    border-radius: 20%;
    color: var(--text_light_white);
    transition: 0.4s ease-in-out;
}
.vendor_card_action_button_wrapper button:hover 
{
    background: var(--text_light_white);
    color: var(--background-dark_plus);
}