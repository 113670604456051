.data_main
{
    max-height: 70%;
    overflow: scroll;
}
.loading_placeholder {
    height: 25em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.distro_graph_wrapper 
{
    background: var(--background-dark_minus);
    border: 2px solid var(--background-light);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 10px;
}
.inner_body_wrapper
 {
    padding: 5px 10px;
 }
.header_graph 
{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 5px;
    background: var(--background-light);
    text-align: center;
}
.header_graph h2 
{
    color: var(--text_light_white);
    font-size: var(--default-font-scale-lrg);
}

.list_main 
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.pitty_cash_audit_list_inner_wrapper 
{
    width: 90%;
    background: var(--background-dark_minus);
    border: 2px solid var(--background-dark);
    height: 200px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.pitty_cash_audit_list_inner_wrapper li 
{
    margin: 5px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    border: 2px solid var(--background-light);
    background: var(--background-dark_plus);
    font-size: var(--default-font-scale-mid);
    color: var(--text_light_white);
    cursor: pointer;
    transform: scale(1.0);
    transition: 0.3s ease-in-out;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.pitty_cash_audit_list_inner_wrapper li:hover 
{
    background: var(--background-dark);
}
.pitty_cash_audit_list_inner_wrapper li span 
{
    flex: 1;
    text-align: left;
}
.pit_amount 
{
    color: var(--color-theme);
    font-weight: bold;
}
/* animation: pulse 1.0s infinite, smooth_in 0.6s; */
@keyframes pulse {
	0% {
		/* transform: scale(0.95); */
		box-shadow: 0 0 0 0 var(--red-color);
	}

	70% {
		/* transform: scale(1); */
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}

	100% {
		/* transform: scale(0.95); */
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.active_usage 
{
    border: 1px solid var(--main-color);
    padding: 2px 5px;
    background: var(--main-color);
    color: var(--background-dark_plus);
    border-radius: 2px;
    width: 5em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}
.action_button_list_for_branch_spec 
{
    position: relative;
    background: var(--background-dark);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
}
.center_span_align 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}