.data_main 
{
  width: 85% !important;
}
.form_action_button_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
}
.input_list_wrapper
 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
 }
.po_mat_list 
{
    border-radius: 5px;
    width: 90%;
    padding: 10px;
    border: 2px solid var(--background-light);
    background: var(--background-dark_minus);

    height: 15em;
    max-height: 15em;
    overflow: scroll;
}
.po_mat_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.input_fild_shild 
 {
   display: flex;
   justify-content: center;
   align-items: center;
 }
  .remove_button_wrapper
 {
   text-align: center;
   margin: 5px;
   padding: 3px;
   background: var(--red-color);
   border: 2px solid var(--red-color);
   color: var(--primary_text_color);
   border-radius: 5px;
   cursor: pointer;
   transition: 0.3s ease-in-out;
 }
 .remove_button_wrapper:hover 
 {
   font-weight: bold;
   scale: 1.1;
   background: none;
   color: var(--red-color);
 }
 .remove_button_wrapper_fake 
 {
    margin: 10px;
   padding: 6px;
 }
 .input_adder 
 {
    display: flex !important;
    justify-content: flex-start !important;
 }