.desktop_icon_view 
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    z-index: 1;
    background: none;

    height: 100vh;
    animation: blurShrinkFadeOut 0.3s ease-in-out forwards;
}

@keyframes blurShrinkFadeOut {
    0% {
        transform: scale(0.3) translate(-50%, 50%) rotate(-30deg);
        filter: blur(100px);
        opacity: 0;
    }
    100% {
        transform: scale(1) translate(0, 0);
        filter: blur(0px);
        opacity: 1;
    }
}
.desktop_icon_view::before 
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--text_light_white);
    opacity: 0.8;
    background-image: radial-gradient(circle at top left, var(--color-theme), var(--background-dark_minus)), 
    repeating-radial-gradient(circle at top left, var(--color-theme), var(--background-dark), 2px, transparent 100px, transparent 10px);
    background-blend-mode: multiply;
    z-index: -1;
}
.desktop_icon_view_inner_wrapper 
{
    padding: 10px;
    margin-left: 7em;
    width: 70%;
    height: 90%;
    max-height: 90%;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 2em;
    overflow: scroll;
}
.search_module_bar 
{
    width: 100%;
    padding: 10px;
    margin-left: 7em;
}
.workshop_filter_input_wrapper 
{
    border: 2px solid var(--background-dark);
    background: var(--background-dark_plus);
    border-radius: 2em;
    padding: 3px 8px;
    width: 25em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.stock_filter_wrapper 
{
   width: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
}
.workshop_filter_input_wrapper input 
{
    padding: 4px 8px;
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    width: 90%;
    color: var(--text_light_white);
}
.workshop_filter_input_wrapper span 
{
    padding: 2px 8px;
    font-size: var(--default-font-scale-mid);
    text-wrap: nowrap;
    color: var(--text_light_white);
}

.element_x_route 
{
    position: relative;
}
.desktop_mode_controller 
{
    position: sticky;
    padding: 5px;
    bottom: 0.5em;
    left: 0.5em;
    border: 1px solid var(--color-theme);
    width: 1.2em;
    height: 1.2em;
    border-radius: 2em;
    background: var(--color-theme);
    color: var(--background-dark_plus);

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 1.1em;

    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    animation: pulse 1.0s infinite, smooth_in 0.6s;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--color-theme);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
