.stock_req_inner_wrapper
{
    margin: 10px;
}
.group_item_list_wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

}
.request_queue_halo
{
    padding: 10px;
}
.request_queue_halo h2
{
    color: var(--text_light_white);
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    font-size: var(--default-font-scale-lrg);
}
.request_queue_halo h1 
{
    color: var(--text_light_white);
    font-size: 3em;
    padding: 5px;
    text-align: center;
}