.master_prod_type_config_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.master_product_type_inner_config_wrapper 
{
    width: 90%;
    margin: 10px;
    border: 2px solid var(--background-dark);
    background: var(--background-dark_minus);
    padding: 5px 10px;
    border-radius: 5px;
}
.input_list_wrapper 
{
    padding: 5px 10px;
    width: 70%;
    border: 2px solid var(--background-dark);
    border-radius: 3px;
    background: var(--background-dark_minus);

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 5px;
}
.input_header_single_wrapper_st_req 
{
    width: 40%;
}
.final_product_ship_wrapper 
{
    height: 10em;
    max-height: 10em;
    overflow: scroll;
}
.final_product_ship_wrapper li 
{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.final_product_ship_wrapper .main_input_final_product_ship_wrapper 
{
    width: 90%;
}
.finsla_product_trasnfer_package_input_action_button 
{
    padding: 4px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}