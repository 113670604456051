.form_wrapper 
{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
  flex-wrap: wrap;
}
.input_list_wrapper
 {
   width: 45%;
 }

 .single_input 
 {
   width: 90%;
 }
 .prod_preview_wrpper 
 {
   width: 45%;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   flex-wrap: wrap;
 }
 .prod_image_input_wrapper 
 {
   width: 12em;
   height: 10em;
   border: 1px solid var(--color-theme);
   background: var(--color-theme);
   border-radius: 10px;
   cursor: pointer;
   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }
 .prod_image_input_wrapper img
 {
   width: 12em;
   height: 10em;
   border-radius: 10px;
 }
 .click_upload_helper 
 {
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   flex-wrap: wrap;

   height: 100%;
   width: 90%;
 }
 .click_upload_helper li 
 {
   width: 100%;
   list-style: none;
   color: var(--background-dark_plus);
   text-align: center;
   font-size: 0.9em;
   font-weight: bold;
   text-transform: uppercase;
 }