.kicthen_supply_order_main_wrapper 
{
    width: 100%;
    height: 100vh;
    background: var(--background-dark_plus);
}
.kicthen_supply_header_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
}
.side_action_wrapper 
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 3px;
}
.side_action_wrapper select 
{
    border: 1px solid var(--text_light_white);
    background: var(--text_light_white);
    color: var(--background-dark_plus);
    font-weight: bold;
    padding: 3px 8px;
    font-size: var(--default-font-scale-min);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.kicthen_supply_header_inner_action_wrapper 
{
    /* background: rgba(255, 255, 255, 0.1); 
    backdrop-filter: blur(7px); 
    -webkit-backdrop-filter: blur(7px);  */

    background: var(--background-dark_minus);

    /* background: var(--background-dark_minus); */
    border: 1px solid var(--background-dark);
    margin: 10px;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 10px 15px;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: ce;

}
.main_inner_order_list_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    flex-wrap: wrap;
}
.main_order_prod_card_list_wrapper 
{
    height: 80%;
    max-height: 80%;
    overflow-y: scroll;
}

/* Cart View Start */

.cart_view_main_wrapper 
{
    border: 2px solid var(--background-dark);
    border-top: 2px solid var(--color-theme);
    border-radius: 2px;
    position: fixed;
    right: 0;
    bottom: 0em;
    width: 25em;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    visibility: visible;
    opacity: 1;
    transition: 0.2s ease-in-out;

    background: rgba(50, 50, 50, 0.1); 
    backdrop-filter: blur(7px); 
    -webkit-backdrop-filter: blur(7px); 
}
.cart_view_main_wrapper_hide 
{
    border: 2px solid var(--background-dark);
    border-top: 2px solid var(--color-theme);
    border-radius: 2px;
    position: fixed;
    right: 0;
    bottom: 0em;
    width: 20em;
    z-index: 10;
    width: 0em;
    opacity: 0;
    filter: blur(10px);
    visibility: hidden;
    transition: 0.2s ease-in-out;

    background: rgba(50, 50, 50, 0.1); 
    backdrop-filter: blur(7px); 
    -webkit-backdrop-filter: blur(7px); 
}
.cart_view_heade_wrapper 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
    background: var(--background-dark_plus);
}
.cart_view_heade_wrapper h4 
{
    font-size: 0.8em;
    color: var(--text_light_white);
}
.cart_view_heade_wrapper h4 sub 
{
    color: var(--background-light);
}
.cart_view_heade_wrapper button 
{
    background: none;
    border: none;
    color: var(--text_light_white);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.dat_wrapp_cart_action 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    background: var(--color-theme) !important;
    border: 2px solid var(--color-theme);
    color: var(--background-dark_plus) !important;
    border-color: var(--color-theme) !important;

    padding: 3px 6px !important;
    
    font-size: 0.9em !important;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

    width: 1em;
    height: 1em;

    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.dat_wrapp_cart_action:hover 
{
    background: none !important;
    
}
.dat_wrapp_cart_action:hover button 
{
    color: var(--color-theme) !important;
}
.dat_wrapp_cart_action button 
{
    background: none !important;
    border: none !important;
    color: var(--background-dark_plus) !important;
    box-shadow: none !important;
}
.right_side_action_wrapper 
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.right_side_action_wrapper input 
{
    padding: 3px 10px;
    border: 1px solid var(--background-light);
    border-radius: 1px;
    background: var(--background-dark_plus);
    color: var(--color-theme);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.right_side_action_wrapper li 
{
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    
    list-style: none;
    margin-top: -30px;
    margin-right: 30px;
    border: 1px solid var(--red-color);

    font-size: var(--default-font-scale-mid);
    background: var(--red-color);
    color: var(--primary_text_color);

    /* box-shadow: var(--red-color) 0px 19px 38px, var(--red-color) 0px 15px 12px; */
    box-shadow: var(--red-color) 0px 3px 6px, var(--red-color) 0px 3px 6px;

    border-radius: 5px;

    width: 2em !important;
    height: 1.3em !important;

    animation: scale_up 0.3s ease-in;
}
@keyframes scale_up {
    0% {
        scale: 0;
    }
    50% {
        scale: 1;
    } 
    75% {
        scale: 1.3;
    }
    100% {
        scale: 1;
    }
}
.cart_view_heade_wrapper button:hover 
{
    color: var(--red-color);
}
.cart_action_taker_wrapper 
{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 3px 6px;
    border-top: 2px solid var(--background-dark);
    margin: 10px;
}
.cart_action_taker_wrapper  button 
{
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid var(--background-dark);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s ease-in-out;
    border-radius: 2px;
}
.cart_action_taker_wrapper  button:hover 
{
    border-radius: 20px;
}
.cart_action_taker_wrapper  button span 
{
    margin: 2px;
}
.order_action 
{
    background: var(--color-theme);
    border: 1px solid var(--color-theme);
    color: var(--background-dark_plus);
}
.void_order_action 
{
    background: var(--red-color);
    border: 1px solid var(--red-color);
    color: var(--primary_text_color);
}

.cart_view_list_wrapper_main 
{
    padding: 5px 10px;
    height: 20em;
    max-height: 20em;
    overflow: scroll;
}
.cart_view_list_wrapper_main li 
{
    list-style: none;
    color: var(--text_light_white);
    font-size: var(--default-font-scale-min);
    font-weight: bold;
    padding: 5px 10px;
    background: var(--background-dark_plus);
    border: 2px solid  var(--background-dark);
    border-radius: 10px;
    margin: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 1;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transform: translateX(-100%);
    animation: slideIn 0.3s ease-in-out forwards;
}
@keyframes slideIn {
    from {
        opacity: 0;
        filter: blur(10px);
    }
    to {
        filter: blur(0px);
        opacity: 1;
        transform: translateX(0);
    }
}
.cart_view_list_wrapper_main li span 
{
    flex: 1;
}
.action_button_cart_orders 
{
    text-align: right;
}
.pr_name_cart_orders 
{
    flex: 2 !important;
}
.count_cart_orders 
{
    border: 1px solid var(--color-theme);
    background: var(--color-theme);
    color: var(--background-dark_plus) !important;
    font-weight: bolder;
    text-align: center;
    padding: 1px 2px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.action_button_cart_orders button 
{
    background: none;
    color: var(--red-color);
    font-size: var(--default-font-scale-lrg);
    border: none;
    cursor: pointer;
}
.cart_view_list_wrapper_main li:hover 
{
    border-bottom: 2px solid var(--color-theme);
    scale: 1.05;
}

/* Cart View End */